import { IdcardTwoTone, LockOutlined, UserOutlined } from '@ant-design/icons'
import { Alert, Button, Card, Form, Input, message, Switch, Tabs } from 'antd'
import Blank from 'containers/blank'
import React, { useEffect, useState } from 'react'
import { Fade } from 'react-animation-components'
import { useDispatch } from 'react-redux'
import { setSiteInfoTitle } from 'store/actions'
import { API_URL } from 'utilities/constants'
import { axiosInstance as axios } from 'utilities/requests'
import Checkbox from 'antd/lib/checkbox/Checkbox'
import track, { useTracking } from 'react-tracking'

const API_url = API_URL.API_URL
const { TabPane } = Tabs

const LoginPage = () => {
    const { trackEvent } = useTracking()
    const [hasLoggedIn, setHasLoggedIn] = useState()
    const [isLoggingIn, setIsLoggingIn] = useState(false)
    const [logoutOtherSessions, setLogoutOtherSessions] = useState(false)
    const dispatch = useDispatch()
    const primaryColor = localStorage.getItem('primary_color')

    useEffect(() => {
        dispatch(setSiteInfoTitle('Log In'))
    }, [dispatch])

    const handleLoginAugmenticom = async (values) => {
        message.loading('Connexion en cours ...', 1)
        const param = new URLSearchParams()
        param.append('identifiant', values.identifiant)
        param.append('password', values.password)
        param.append('logoutOtherSessions', logoutOtherSessions)
        await axios
            .post(API_url + '/api/login', param)
            .then((resp) => {
                let modules = resp.data.modules
                window.localStorage.setItem('id', resp.data.id)
                window.localStorage.setItem('name', resp.data.identifiant)
                window.localStorage.setItem('profile', resp.data.profile)
                window.localStorage.setItem('email', resp.data.email)
                window.localStorage.setItem('token', resp.data.access_token)
                window.localStorage.setItem('modules', JSON.stringify(modules))
                window.localStorage.setItem('isActive', resp.data.isActive)
                window.localStorage.setItem('hierarchie', resp.data.hierarchie)
                window.localStorage.setItem('entite', resp.data.entite)
                window.localStorage.setItem('client', resp.data.client)
                message.success('Vous avez été connecté avec succès.')
                setHasLoggedIn(true)
                window.localStorage.setItem('isAuthenticated', true)
                if (resp.data.isActive === false) {
                    window.location.replace('/modifyPassword')
                } else {
                    window.location.replace('/')
                }
                trackEvent({
                    funComponent: 'Login',
                    event: 'Loging-sendData'
                })
            })
            .catch((error) => {
                if (error.response && error.response?.status === 401) {
                    message.error('Identifiant ou mot de passe incorrect.')
                } else if (error.response && error.response?.status === 403) {
                    message.error('Vous avez une autre session ouverte, fermez-la avant de réessayer.')
                } else {
                    message.error('Une erreur est survenue lors de la connexion.')
                }
                trackEvent({
                    funComponent: 'Login',
                    event: 'Loging-sendData',
                    status: JSON.stringify(error.response?.status)
                })
                window.localStorage.clear()
            })
            .finally(() => {
                setIsLoggingIn(false)
            })
    }

    return (
        <>
            <div className="ant-row">
                <div className="ant-col ant-col-xs-24 ant-col-xl-12">
                    <a href="https://augmenticom.fr/">
                        <img style={{ maxWidth: '100%', maxHeight: '100%', marginLeft: '20%' }} src="../img/AugmenticomLog.png" id="imgG" alt="img" />
                    </a>
                </div>

                <div id="container" style={{ marginTop: '5%', marginBottom: '20%' }} className="ant-col ant-col-xs-24 ant-col-xl-12">
                    <Fade in={!hasLoggedIn}>
                        <Alert
                            message="Important"
                            description="Afin de s'assurer de la source des informations soumises, il vous est obligatoire d'utiliser une adresse mail professionelle."
                            type="warning"
                            closable
                            showIcon
                        />
                        <br />
                        <Card title="Portail d'accès" size="small">
                            <Tabs defaultActiveKey="credentials">
                                <TabPane
                                    tab={
                                        <span>
                                            <IdcardTwoTone id="utilisateur-clients" />
                                            Utilisateur Clients
                                        </span>
                                    }
                                    key="credentials"
                                >
                                    <Form
                                        method="POST"
                                        name="normal_login"
                                        className="login-form"
                                        initialValues={{ remember: true }}
                                        onFinish={handleLoginAugmenticom}
                                    >
                                        <Form.Item
                                            name="identifiant"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Nom d'utilisateur requis"
                                                }
                                            ]}
                                        >
                                            <Input
                                                prefix={<UserOutlined className="site-form-item-icon" />}
                                                placeholder="Identifiant"
                                                type="text"
                                                size="large"
                                                addonAfter={process.env.REACT_APP_SITE_EMAIL_DOMAIN}
                                            />
                                        </Form.Item>
                                        <Form.Item name="password" rules={[{ required: true, message: 'Mot de passe requis' }]}>
                                            <Input.Password
                                                prefix={<LockOutlined className="site-form-item-icon" />}
                                                type="password"
                                                placeholder="Mot de passe"
                                                size="large"
                                            />
                                        </Form.Item>
                                        <Form.Item label="Se déconnecter de toutes les autres sessions">
                                            <Checkbox
                                                id="checkbox-session"
                                                onChange={(e) => {
                                                    setLogoutOtherSessions(e.target.checked)
                                                }}
                                            ></Checkbox>
                                        </Form.Item>
                                        <Form.Item>
                                            <Button
                                                id="btn-login-clients"
                                                style={{ backgroundColor: primaryColor ? primaryColor : '#3CB371', color: 'white' }}
                                                htmlType="submit"
                                                size="large"
                                                block
                                                loading={isLoggingIn}
                                            >
                                                Se connecter
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                </TabPane>
                            </Tabs>
                        </Card>
                    </Fade>
                </div>
            </div>
            <div style={{ position: 'fixed', width: '100%', bottom: 0 }}>
                <Blank />
            </div>
        </>
    )
}

export default LoginPage
