import {
    CheckCircleOutlined,
    ClockCircleOutlined,
    SyncOutlined,
    CloseCircleOutlined,
    CaretUpOutlined,
    MinusOutlined,
    CaretDownOutlined
} from '@ant-design/icons'
import { Tag } from 'antd'
import React from 'react'

export const getStatusTag = (status) => {
    switch (status) {
        case 'open':
            return (
                <Tag icon={<SyncOutlined />} color="processing">
                    Ouvert
                </Tag>
            )
        case 'closed':
            return (
                <Tag icon={<CheckCircleOutlined />} color="success">
                    Fermé
                </Tag>
            )
        case 'pending reminder' || 'pending close':
            return (
                <Tag Tag icon={<ClockCircleOutlined />} color="warning">
                    En attente
                </Tag>
            )
        case 'new':
            return (
                <Tag icon={<ClockCircleOutlined />} color="default">
                    Nouveau
                </Tag>
            )
        case 'removed':
            return (
                <Tag icon={<CloseCircleOutlined />} color="error">
                    Supprimé
                </Tag>
            )
        default:
            return null
    }
}

export const getPrioritieTag = (priority) => {
    switch (priority) {
        case '1 low':
            return (
                <Tag icon={<CaretDownOutlined />} color="processing">
                    Low
                </Tag>
            )
        case '2 normal':
            return (
                <Tag icon={<MinusOutlined />} color="warning">
                    Medium
                </Tag>
            )
        case '3 high':
            return (
                <Tag icon={<CaretUpOutlined />} color="error">
                    High
                </Tag>
            )
        default:
            return null
    }
}
