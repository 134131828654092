import { Button, Card, Col, Descriptions, List, Row, message } from 'antd'
import { Link } from 'react-router-dom'
import React, { useState, useEffect } from 'react'
import TicketingService from '../../services/TicketingService'
import DetailTicket from '../Modals/DetailTicket'
import { SearchOutlined } from '@ant-design/icons'
import moment from 'moment'
import { getPrioritieTag, getStatusTag } from '../../constants/enums/ticketStatusEnum'
import track, { useTracking } from 'react-tracking'


const DocumentsWidget = () => {
    const currentUserId = localStorage.getItem('id')
    const { trackEvent } = useTracking()
    const [tickets, setTickets] = useState([])
    const [visible, setVisible] = useState(false)
    const [rec, setRec] = useState([])
    const [articles, setArticles] = useState([])
    const [article, setArticle] = useState({ idTicket: '', from: '', body: '' })
    const NUM_TICKETS = 4

    useEffect(() => {
        getTickets()
        TicketingService.getArticle(rec.id)
            .then((res) => {
                setArticles(res.data)
            })
            .catch((err) => {})
    }, [rec])

    const getTickets = async () => {
        let numeros = []
        let data = {
            email: localStorage.getItem('email'),
            idEntite: Number(localStorage.getItem('entite')),
            idHierarchie: Number(localStorage.getItem('hierarchie')),
            idClient: Number(localStorage.getItem('client'))
        }
        setTickets([])
        TicketingService.getTicket(data)
            .then((response) => {
                if (response.data.length > 0) {
                    for (let ticket of response.data) {
                        const data = {}
                        TicketingService.getPriority(ticket.priority_id).then((responsePriority) => {
                            data.priority = getPrioritieTag(responsePriority.data.name)
                            TicketingService.getUser(ticket.customer_id).then((responseUser) => {
                                data.client = responseUser.data.firstname
                                TicketingService.getStatus(ticket.state_id).then((responseStatus) => {
                                    data.status = getStatusTag(responseStatus.data.name)
                                    data.number = ticket.number
                                    data.id = ticket.id
                                    data.title = ticket.title
                                    const DateCreationUtc = moment.utc(ticket.created_at?.toString()).toDate()
                                    data.created_at = moment(DateCreationUtc).local().format('YYYY-MM-DD HH:mm:ss')
                                    setTickets((prevState, props) => [data, ...prevState])
                                })
                            })
                        })
                    }
                }
                 trackEvent({
                     funComponent: 'List-Tickets',
                     event: 'List-Tickets-getData'
                 })
            })
            .catch((err) => {
                 trackEvent({
                     funComponent: 'List-Tickets',
                     event: 'List-Tickets-getData',
                     status: JSON.stringify(err.response?.data.status)
                 })
                message.error("Impossible d'afficher les tickets")
            })
    }

    return (
        <>
            <Card
                type="inner"
                title="Derniers tickets"
                extra={
                    <Link to={'/Ticketing'}>
                        <Button
                            id="btn-new-ticket"
                            size="small"
                            onClick={() =>
                                trackEvent({
                                    funComponent: 'Button-New-Ticket',
                                    event: 'Button-New-Ticket-clicked'
                                })
                            }
                        >
                            Nouveau ticket
                        </Button>
                    </Link>
                }
            >
                {tickets.slice(0, NUM_TICKETS).map((ticket) => {
                    return (
                        <Row>
                            <Col span={8}>
                                <List.Item>
                                    <Descriptions column={1}>
                                        <Descriptions.Item>{ticket.title}</Descriptions.Item>
                                    </Descriptions>
                                </List.Item>
                            </Col>
                            <Col span={8}>
                                <List.Item>
                                    <Descriptions column={1}>
                                        <Descriptions.Item>{ticket.status}</Descriptions.Item>
                                    </Descriptions>
                                </List.Item>
                            </Col>
                            <Col span={8}>
                                <List.Item>
                                    <Descriptions column={1}>
                                        <Descriptions.Item>
                                            <div className="btn-wrap">
                                                <Button
                                                    id="btn-detail-ticket"
                                                    onClick={async (e) => {
                                                        setRec(ticket)
                                                        setVisible(true)
                                                        trackEvent({
                                                            funComponent: 'Button-Detail-Ticket',
                                                            event: 'Button-Detail-Ticket-' + ticket.id + '-clicked'
                                                        })
                                                    }}
                                                >
                                                    <a href="#details">
                                                        <SearchOutlined />
                                                    </a>
                                                </Button>
                                            </div>
                                        </Descriptions.Item>
                                    </Descriptions>
                                </List.Item>
                            </Col>
                        </Row>
                    )
                })}
            </Card>
            {visible === true ? (
                <DetailTicket rec={rec} setVisible={setVisible} visible={visible} setArticle={setArticle} article={article} articles={articles} />
            ) : null}
        </>
    )
}

export default DocumentsWidget
