import { Button } from 'antd'
import { Link } from 'react-router-dom'
import React from 'react'

export const buildAntDTableFromArray = (arr, itemURL) => {
    const m = arr.map((item) => {
        return { ...item, dataIndex: item.key, title: item.name }
    })

    m.push({
        title: 'Action',
        key: 'action',
        render: (text, record) => (
            <Button type="dashed">
                <Link to={`${itemURL}${record.id}`}>Détails</Link>
            </Button>
        )
    })
    return m
}

export const showNotificationError = (data) => {
    if (data instanceof Array) {
        return (
            <ul>
                {data.map((d) => (
                    <li key={d.description}>{d.description}</li>
                ))}
            </ul>
        )
    } else if ('description' in data) {
        return data.description
    } else if ('message' in data) {
        return data.message
    }
}
