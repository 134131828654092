import http from '../utilities/http-common'

const getOrdersTypes = () => {
    return http.get('/api/v1/retention/ordersType')
}

const getIntervenants = () => {
    return http.get('/api/v1/retention/intervenants')
}

const getLinkedToHierarchyIntervenants = (userId) => {
    return http.get(`/api/v1/retention/intervenants/hierarchy/${userId}`)
}

const createRetention = (retention) => {
    return http.post('/api/v1/retention/create', retention)
}

const getUserRetentions = (userId) => {
    return http.get(`/api/v1/retention/${userId}`)
}
const deleteRetention = (idRetention) => {
    return http.post('/api/v1/retention/delete/', idRetention)
}
const modifierRetention = (retention) => {
    return http.post('/api/v1/retention/updateDates', retention)
}
export default {
    getIntervenants,
    getLinkedToHierarchyIntervenants,
    modifierRetention,
    getOrdersTypes,
    deleteRetention,
    createRetention,
    getUserRetentions
}
