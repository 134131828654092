import { IdcardTwoTone, LockOutlined } from '@ant-design/icons'
import { Alert, Button, Card, Col, Form, Input, message, Row, Tabs } from 'antd'
import Blank from 'containers/blank'
import React, { useEffect, useState } from 'react'
import { Fade } from 'react-animation-components'
import { useDispatch } from 'react-redux'
import { setSiteInfoTitle } from 'store/actions'
import { analyze, validerPassword } from '../utilities/validationPassword'
import http from 'utilities/http-common'

const { TabPane } = Tabs
const primaryColor = localStorage.getItem('primary_color')
const LoginPage = () => {
    const [hasLoggedIn, setHasLoggedIn] = useState()
    const [isLoggingIn, setIsLoggingIn] = useState(false)
    const dispatch = useDispatch()
    const currentUserId = localStorage.getItem('id')
    const [backgroundColorNewPassword, setBackgroundColorNewPassword] = useState('')
    const [backgroundColorConfirmPassword, setBackgroundColorConfirmPassword] = useState('')

    useEffect(() => {
        dispatch(setSiteInfoTitle('Modifier le mot de passe'))
    }, [dispatch])

    const hadleChangePassword = async (values) => {
        if (values.newPassword !== values.confirmPassword) {
            message.error('Les mots de passe ne sont pas identiques')
        } else {
            if (validerPassword(values.newPassword)) {
                http.patch('/api/v1/users/' + currentUserId + '/change-password', { newPassword: values.newPassword }).then(() => {
                    window.localStorage.setItem('isActive', true)
                    message
                        .loading("Votre mot de passe a été enregistré ,vous serez rediriger vers la page d'accueil dans quelques instants", 1)
                        .then(() => {
                            window.location.replace('/')
                        })
                })
            } else {
                message.error(
                    'Le mot de passe doit contenir au minimum 8 caractères,au moins une lettre minuscule et une lettre majuscule, un caractère spécial et un chiffre.'
                )
            }
        }
    }

    const changeColorNewPassword = async (value) => {
        let color = analyze(value)
        setBackgroundColorNewPassword(color)
    }

    const changeColorConfirmPassword = async (value) => {
        let color = analyze(value)
        setBackgroundColorConfirmPassword(color)
    }

    return (
        <>
            <div className="container" style={{ marginTop: '3rem' }}>
                <div className="img">
                    <img src="../img/AugmenticomPass.png" id="imgG" alt="img" />
                </div>

                <Row gutter={[30, 30]} style={{ marginTop: '50px' }}>
                    <Col xs={{ span: 22, offset: 1 }}>
                        <Fade in={!hasLoggedIn}>
                            <Alert
                                message="Important"
                                description="Vous utilisez un mot de passe généré, pour plus de sécurité, vous devez créer votre propre mot de passe."
                                type="info"
                                closable
                                showIcon
                            />
                            <br />
                            <Card title="" size="small">
                                <Tabs defaultActiveKey="credentials">
                                    <TabPane
                                        tab={
                                            <span>
                                                <IdcardTwoTone />
                                                Créer un nouveau mot de passe
                                            </span>
                                        }
                                        key="code"
                                    >
                                        <Form
                                            name="normal_login"
                                            className="login-form"
                                            initialValues={{ remember: true }}
                                            onFinish={hadleChangePassword}
                                        >
                                            <Form.Item
                                                name="newPassword"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Mot de passe requis'
                                                    }
                                                ]}
                                            >
                                                <Input.Password
                                                    style={{ background: backgroundColorNewPassword }}
                                                    prefix={<LockOutlined className="site-form-item-icon" />}
                                                    placeholder="Nouveau mot de passe"
                                                    type="newPassword"
                                                    size="large"
                                                    onChange={(val) => changeColorNewPassword(val)}
                                                />
                                            </Form.Item>
                                            <Form.Item name="confirmPassword" rules={[{ required: true, message: 'Mot de passe requis' }]}>
                                                <Input
                                                    style={{ backgroundColor: backgroundColorConfirmPassword }}
                                                    prefix={<LockOutlined className="site-form-item-icon" />}
                                                    type="password"
                                                    placeholder="Confirmez le nouveau mot de passe"
                                                    size="large"
                                                    onChange={(val) => changeColorConfirmPassword(val)}
                                                />
                                            </Form.Item>
                                            <Form.Item>
                                                <Button
                                                    style={{ backgroundColor: primaryColor ? primaryColor : '#009E60', color: 'white' }}
                                                    htmlType="submit"
                                                    size="large"
                                                    block
                                                    loading={isLoggingIn}
                                                >
                                                    Enregistrer
                                                </Button>
                                            </Form.Item>
                                        </Form>
                                    </TabPane>
                                </Tabs>
                            </Card>
                        </Fade>
                    </Col>
                </Row>
            </div>

            <div style={{ position: 'fixed', width: '100%', bottom: 0 }}>
                <Blank />
            </div>
        </>
    )
}

export default LoginPage
