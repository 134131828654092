import { createReducer } from '@reduxjs/toolkit'

const initialState = {
    feedbacks: {
        loading: false,
        data: [],
        error: ''
    },
    feedbackCampaigns: {
        loading: false,
        data: [],
        error: ''
    }
}

export const feedbackManagerReducer = createReducer(initialState, {
    FETCH_FEEDBACK_CAMPAIGNS_REQUEST: (state) => {
        state.feedbackCampaigns.loading = true
    },
    FETCH_FEEDBACK_CAMPAIGNS_SUCCESS: (state, action) => {
        state.feedbackCampaigns = {
            loading: false,
            data: action.payload
        }
    },
    FETCH_FEEDBACK_CAMPAIGNS_FAILURE: (state, action) => {
        state.feedbackCampaigns = {
            loading: false,
            data: {},
            error: action.payload
        }
    }
})
