import { DownloadOutlined, EyeOutlined, FileExcelTwoTone, FilePdfTwoTone, MoreOutlined } from '@ant-design/icons'
import { Button, Card, Col, Descriptions, Dropdown, List, Menu, Row } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'

const DocumentsWidget = () => {
    return (
        <>
            <Card
                type="inner"
                title="Dernier(s) document(s)"
                extra={
                    <Dropdown
                        overlay={
                            <Menu>
                                <Menu.Item key="all">
                                    <Link to="/documents">Tous les documents</Link>
                                </Menu.Item>
                            </Menu>
                        }
                        trigger={['click']}
                    >
                        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                            <MoreOutlined />
                        </a>
                    </Dropdown>
                }
            >
                <Row gutter={20}>
                    <List.Item>
                        <Col span={6}>
                            <FileExcelTwoTone twoToneColor="#1d6f42" style={{ fontSize: '50px' }} />
                        </Col>
                        <Col span={18}>
                            <Descriptions column={1}>
                                <Descriptions.Item label="Titre"></Descriptions.Item>
                                <Descriptions.Item label="Date de publication"></Descriptions.Item>
                            </Descriptions>
                            <>
                                <Button type="link" icon={<EyeOutlined />}>
                                    Consulter
                                </Button>
                                <Button type="link" icon={<DownloadOutlined />}>
                                    Télécharger
                                </Button>
                            </>
                        </Col>
                    </List.Item>
                    <List.Item>
                        <Col span={6}>
                            <FilePdfTwoTone twoToneColor="#c90000" style={{ fontSize: '50px' }} />
                        </Col>
                        <Col span={18}>
                            <Descriptions column={1}>
                                <Descriptions.Item label="Titre"></Descriptions.Item>
                                <Descriptions.Item label="Date de publication"></Descriptions.Item>
                            </Descriptions>
                            <>
                                <Button type="link" icon={<EyeOutlined />}>
                                    Consulter
                                </Button>
                                <Button type="link" icon={<DownloadOutlined />}>
                                    Télécharger
                                </Button>
                            </>
                        </Col>
                    </List.Item>
                </Row>
            </Card>
        </>
    )
}

export default DocumentsWidget
