import { Card, Divider, Select, message, Tabs, Modal, Col, Row } from 'antd'
import React, { useState, useEffect } from 'react'
import Cookies from 'js-cookie'
import '../../static/less/app.less'
import EchangesSearchForm from '../../components/Echanges/EchangesSearchForm'
import EchangeService from 'services/EchangeService'
import BordTable from '../../components/Echanges/BordTable'
import RecepTable from '../../components/Echanges/RecepTable'
import BordereauDetails from '../../components/Echanges/BordereauDetails'
import { hasActionByModule } from 'utilities/modules'
import ReportTable from '../../components/Echanges/ReportTable'
import RecepDetails from '../../components/Echanges/RecepDetails'
import track, { useTracking } from 'react-tracking'

const { Option } = Select
const { TabPane } = Tabs

const RechercheBord = () => {
    const currentUserId = localStorage.getItem('id')
    const { trackEvent } = useTracking()
    const [researchedData, setSearchedData] = useState([])
    const [steps, setSteps] = useState([])
    const [showDetail, setShowDetail] = useState(false)
    const [searchDetails, setSearchDetails] = useState([])
    const [recepisseDetails, setrecepisseDetails] = useState([])
    const [showPopup, setShowPopup] = useState(false)
    const [recepisseId, setRecepisseId] = useState()
    const [details, setDetail] = useState([])
    const [detailsAdditionalInfo, setDetailsAdditionalInfo] = useState([])
    const [recepisseEvent, setrecepisseEvent] = useState([])
    const [reportPointage, setReportPointage] = useState([])
    const [bordereauInfo, setBordereauInfo] = useState(JSON.parse(localStorage.getItem('bordereau') ? localStorage.getItem('bordereau') : null))
    const [bordereauId, setBordereauId] = useState()
    const nbRowPreference = Cookies.get('nbRow') !== undefined ? Cookies.get('nbRow') : 10
    const [nbRow, setNbRow] = useState(nbRowPreference)
    const [currentPage, setCurrentPage] = useState(1)

    document.title = 'Recherche bordereau'

    useEffect(() => {
        if (bordereauInfo !== null) {
            const latestEchangesRequest = {
                emis: true,
                recus: true,
                reference: bordereauInfo.bordereau,
                startDate: undefined,
                endDate: undefined
            }

            EchangeService.getUserLatestBoredreau(currentUserId, latestEchangesRequest).then((res) => {
                setSearchedData(res.data)
                getBordereauDetailsById(bordereauInfo.bordereauId, bordereauInfo.bordereau)
            })
            setBordereauInfo(null)
            localStorage.removeItem('bordereau')
        } else {
            setShowDetail(false)
            setSearchDetails([])
            setrecepisseDetails([])
        }
    }, [researchedData.length > 1])

    const getBordereauDetailsById = async (idBordereau, numBordereau) => {
        EchangeService.getBordereauDetailsById(currentUserId, idBordereau)
            .then((res) => {
                setSearchDetails(res.data)
                setBordereauId(numBordereau)
                getRecepisseByBordereauIdAndOrderType(idBordereau, res.data.ordersType)
                message.success('Chargement des données terminé')
                trackEvent({
                    funComponent: 'List-Details-Bordereau',
                    event: 'List-Details-Bordereau-getData'
                })
            })
            .catch((err) => {
                trackEvent({
                    funComponent: 'List-Details-Bordereau',
                    event: 'List-Details-Bordereau-getData',
                    status: JSON.stringify(err.response?.data.status)
                })
                message.error('Une erreur est survenue lors du chargement des données')
            })
    }

    const getRecepisseByBordereauIdAndOrderType = async (idBordereau, orderType) => {
        EchangeService.getRecepisseByBordereauIdAndOrderType(currentUserId, idBordereau, orderType)
            .then((res) => {
                setrecepisseDetails(res.data)
                setCurrentPage(1)
                setShowDetail(true)
                trackEvent({
                    funComponent: 'List-Recepisse',
                    event: 'List-Recepisse-getData'
                })
            })
            .catch((err) => {
                trackEvent({
                    funComponent: 'List-Recepisse',
                    event: 'List-Recepisse-getData',
                    status: JSON.stringify(err.response?.data.status)
                })
                message.error('Une erreur est survenue lors du chargement des données')
            })
    }
    const afficherPopup = () => {
        return (
            <Modal centered visible={showPopup} onCancel={() => setShowPopup(false)} footer={[]} width={1100}>
                <RecepDetails details={details} recepisseId={recepisseId} detailsAdditionalInfo={detailsAdditionalInfo} />
                <br />
                <br />
                <Tabs
                    type="card"
                    defaultIndex={1}
                    onSelect={(key) =>
                        trackEvent({
                            funComponent: 'Tab',
                            event: 'Tab-' + key.key + '-clicked'
                        })
                    }
                >
                    <TabPane tab="Report(s) de suivi" key="Report(s) de suivi">
                        <ReportTable data={recepisseEvent} />
                    </TabPane>
                    <TabPane tab="Report(s) de pointage" key="Report(s) de pointage">
                        <ReportTable data={reportPointage} />
                    </TabPane>
                </Tabs>
            </Modal>
        )
    }
    return hasActionByModule('Recherche bordereaux', 'Lister') ? (
        <>
            <EchangesSearchForm setSearchedData={setSearchedData} fetchCallback={EchangeService.getUserLatestBoredreau} setSteps={setSteps} />
            <Divider />
            <Card>
                <Row gutter={{ xs: 1, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row">
                        <div>Nombre de lignes: </div>
                    </Col>
                    <Col className="gutter-row">
                        <Select
                            style={{ width: '60px' }}
                            defaultValue={nbRow}
                            onChange={(e) => {
                                Cookies.set('nbRow', e)
                                setNbRow(e)
                            }}
                        >
                            <Option value="5">5</Option>
                            <Option value="10">10</Option>
                            <Option value="25">25</Option>
                            <Option value="50">50</Option>
                        </Select>
                    </Col>
                </Row>
            </Card>
            <Card>
                <BordTable
                    data={researchedData}
                    getBordereauDetailsById={getBordereauDetailsById}
                    setSearchedData={setSearchedData}
                    setSteps={setSteps}
                />
            </Card>
            <br />
            {showDetail ? (
                <Card type="inner" title={'Détail du bordereau : ' + bordereauId}>
                    <BordereauDetails data={searchDetails} />
                    <br />
                    <br />
                    <RecepTable
                        setShowDetail={setShowDetail}
                        setRecepisseId={setRecepisseId}
                        setrecepisseEvent={setrecepisseEvent}
                        setDetail={setDetail}
                        setDetailsAdditionalInfo={setDetailsAdditionalInfo}
                        data={recepisseDetails}
                        steps={steps}
                        numRows={nbRow}
                        setReportPointage={setReportPointage}
                        setShowPopup={setShowPopup}
                        isRecepisse={false}
                        setCurrentPage={setCurrentPage}
                        currentPage={currentPage}
                    />
                </Card>
            ) : null}
            {showPopup ? afficherPopup() : null}
            <div id="details"></div>
        </>
    ) : (
        "vous ne pouvez pas lister les ressources, veuillez contacter l'administrateur"
    )
}

export default RechercheBord
