import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Layout, Menu, message } from 'antd'
import { HomeOutlined, UserOutlined, ProfileOutlined, ImportOutlined, FilePdfOutlined } from '@ant-design/icons'
import { Link, useLocation } from 'react-router-dom'
import '../../static/less/app.less'
import { getModuleEnumByLabel, getModuleIcon } from '../../constants/enums/moduleEnum'
import ModulesProvider from '../../utilities/modules'
import UserService from 'services/UserService'
import track, { useTracking } from 'react-tracking'

ModulesProvider()
const { Sider } = Layout
const { SubMenu } = Menu

const App = () => {
    const { trackEvent } = useTracking()
    const [collapsed, setCollapsed] = useState(false)
    const location = useLocation()
    const [dataMenu, setDataMenu] = useState([])
    const [menu, setMenu] = useState([])

    useEffect(() => {
        setMenu([])
        const checkRole = window.localStorage.getItem('modules')
        setDataMenu(JSON.parse(checkRole))
    }, [])

    const buildMenu = () => {
        if (menu.length > 0 || dataMenu === undefined || dataMenu === null) {
            setMenu([])
            return menu
        }
        var i =0;
        let statistiqueNoShowed = true
        let parametreNoShowed = true
        Object.keys(dataMenu).forEach((module) => {
            let sousModule = dataMenu[module].sousModule
            let modulesChildren = []
            let hasSousModule = false
            let moduleName = dataMenu[module].module.libelle

            if(moduleName !== 'Support'){
                i++;
                if(i===dataMenu.length){
                    menu.push(
                        <Menu.SubMenu  id="Support" key="Support" icon={getModuleIcon("Support")} title={"Support"}>
                            <Menu.Item id={"Documentation"} key={"Documentation"} icon={getModuleIcon("Documentation")} className="menu-item-color">
                                Documentation
                                <Link id={"Documentation"} to={'/Documentation' }></Link>
                            </Menu.Item>
                        </Menu.SubMenu>
                    )
                }
            }

            if (moduleName === 'Statistique(s)' || moduleName === 'Volumétrie' || moduleName === 'K.P.I.') {
                if (statistiqueNoShowed) {
                    moduleName = 'Statistique(s)'
                    menu.push(
                        <Menu.Item id={moduleName} key={moduleName} icon={getModuleIcon(moduleName)} className="menu-item-color">
                            {moduleName}
                            <Link id={moduleName} to={'/' + getModuleEnumByLabel(moduleName)}></Link>
                        </Menu.Item>
                    )
                    statistiqueNoShowed = false
                }
            } else if (moduleName === 'Paramètre(s)' || moduleName === 'Rôle(s)' || moduleName === 'Utilisateur(s)') {
                if (parametreNoShowed) {
                    moduleName = 'Paramètre(s)'
                    menu.push(
                        <Menu.Item id={moduleName} key={moduleName} icon={getModuleIcon(moduleName)} className="menu-item-color">
                            {moduleName}
                            <Link id={moduleName} to={'/' + getModuleEnumByLabel(moduleName)}></Link>
                        </Menu.Item>
                    )
                    parametreNoShowed = false
                }
            } else {
                if (sousModule !== null && sousModule.length > 0) {
                    sousModule.forEach((element) => {
                        let sousModuleName = element.libelle
                        modulesChildren.push(
                            <Menu.Item id={sousModuleName} key={sousModuleName} name={sousModuleName}>
                                <NavLink id={sousModuleName} exact to={'/' + getModuleEnumByLabel(sousModuleName)}>
                                    {sousModuleName}
                                </NavLink>
                            </Menu.Item>
                        )
                    })
                    hasSousModule = true
                }
                if (hasSousModule) {
                    menu.push(
                        <SubMenu id={moduleName} key={moduleName} icon={getModuleIcon(moduleName)} title={moduleName}>
                            {modulesChildren}
                        </SubMenu>
                    )
                } else {
                    menu.push(
                        <Menu.Item id={moduleName} key={moduleName} icon={getModuleIcon(moduleName)} className="menu-item-color">
                            <span>{moduleName}</span>
                            <Link id={moduleName} to={'/' + getModuleEnumByLabel(moduleName)}></Link>
                        </Menu.Item>
                    )
                }
            }
        })

        return menu
    }

    const onCollapse = (collapsed) => setCollapsed(collapsed)
    const handleLogout = () => {
        UserService.logOutUser()
            .then(() => {
                trackEvent({
                    funComponent: 'Logout',
                    event: 'Logout-clicked'
                })
                message.success('Vous êtes déconnecté avec succès')
            })
            .catch((error) => {
                if (error.response && error.response?.data.status === 403) {
                    trackEvent({
                        funComponent: 'Logout',
                        event: 'Logout-clicked'
                    })
                    message.success('Vous êtes déconnecté avec succès')
                } else {
                    trackEvent({
                        funComponent: 'Logout',
                        event: 'Logout-clicked',
                        status: JSON.stringify(error.response?.data.status)
                    })
                    message.error('Une erreur est survenue lors de la déconnexion')
                }
            })
            .finally(() => {
                window.localStorage.clear()
                window.location.replace('/login')
            })
    }
    const primaryColor = localStorage.getItem('primary_color')

    return (
        document.getElementById('root')?.style.setProperty('--primary-color', primaryColor ? primaryColor : '#3CB371'),
        (
            <Sider breakpoint="lg" id="sider" theme="light" collapsible collapsed={collapsed} onCollapse={onCollapse}>
                <div style={{ textAlign: 'center' }}>
                    <img
                        src={localStorage.getItem('logo') ? localStorage.getItem('logo') : '../img/logo1.png'}
                        alt="logo1"
                        width="70%"
                        length="50%"
                        style={{ marginTop: '10%' }}
                    />
                </div>
                <br />
                <Menu
                    theme="light"
                    mode="inline"
                    defaultSelectedKeys={[location.pathname]}
                    onSelect={(key) => {
                        trackEvent({
                            funComponent: 'Menu',
                            event: 'Menu-' + key.key + '-clicked'
                        })
                    }}
                >
                    <Menu.Item id="Home" key="Home" className="menu-item-color">
                        <HomeOutlined />
                        <span>Home</span>
                        <Link to="/"></Link>
                    </Menu.Item>
                    {buildMenu()}
                    <Menu
                        style={{ textAlign: 'center' }}
                        defaultSelectedKeys={[location.pathname]}
                        onSelect={(key) => {
                            trackEvent({
                                funComponent: 'Menu',
                                event: 'Menu-' + key.key + '-clicked'
                            })
                        }}
                    >
                        <SubMenu
                            className="menu-item-color"
                            id="userDetail"
                            key="Profile"
                            icon={<ProfileOutlined />}
                            title={localStorage.getItem('name')}
                            Deconnexion
                        >
                            <Menu.Item id="Profile" key="Profile">
                                <NavLink id="Profile" exact to="/profile">
                                    <UserOutlined /> Gestion de Profil
                                </NavLink>
                            </Menu.Item>
                            <Menu.Item id="Deconnexion" key="Deconnexion" onClick={handleLogout}>
                                <NavLink id="Deconnexion" to="#">
                                    <ImportOutlined /> Deconnexion
                                </NavLink>
                            </Menu.Item>
                        </SubMenu>
                    </Menu>
                </Menu>
            </Sider>
        )
    )
}

export default App
