import { Button, Result } from 'antd'

import { Link } from 'react-router-dom'
import React from 'react'

const NoMatch = () => {
    return (
        <Result
            status="404"
            title="404"
            subTitle="Oops la page souhaitée n'existe pas."
            extra={
                <Button type="primary">
                    <Link to="/">Accueil</Link>
                </Button>
            }
        />
    )
}

export default NoMatch
