import SolidgaugeChart from 'components/Charts/Solidgauge'
import Highcharts from 'highcharts'
import React from 'react'

const getSolidgaugePercentage = (tauxRemonteData, orientation, step) => {
    if (tauxRemonteData.lenght === 0) {
        return 0
    } else if (tauxRemonteData[orientation] === undefined || tauxRemonteData[orientation] === null) {
        return 0
    } else if (tauxRemonteData[orientation][step] === undefined || tauxRemonteData[orientation][step] === null) {
        return 0
    }
    return tauxRemonteData[orientation][step] * 100
}

const configChartPie = (data) => { 
    return({
    chart: {
        type: 'solidgauge',
        height: '70%'
    },

    title: {
        text: data.text
    },

    tooltip: {
        borderWidth: 0,
        backgroundColor: 'none',
        shadow: false,
        style: {
            fontSize: '16px'
        },
        valueSuffix: '%',
        pointFormat: '{series.name}<br><span style="font-size:2em; color: {point.color}; font-weight: bold">{point.y}</span>',
        positioner: function (labelWidth) {
            return {
                x: (this.chart.chartWidth - labelWidth) / 2,
                y: this.chart.plotHeight / 2 + 15
            }
        }
    },

    pane: {
        startAngle: 0,
        endAngle: 360,
        background: [
            {
                // Track for Move
                outerRadius: '112%',
                innerRadius: '88%',
                backgroundColor: Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0.3).get(),
                borderWidth: 0
            },
            {
                // Track for Exercise
                outerRadius: '87%',
                innerRadius: '63%',
                backgroundColor: Highcharts.color(Highcharts.getOptions().colors[1]).setOpacity(0.3).get(),
                borderWidth: 0
            },
            {
                // Track for Stand
                outerRadius: '62%',
                innerRadius: '38%',
                backgroundColor: Highcharts.color(Highcharts.getOptions().colors[2]).setOpacity(0.3).get(),
                borderWidth: 0
            },
            {
                // Track for Stand
                outerRadius: '37%',
                innerRadius: '13%',
                backgroundColor: Highcharts.color(Highcharts.getOptions().colors[3]).setOpacity(0.3).get(),
                borderWidth: 0
            }
        ]
    },

    yAxis: {
        min: 0,
        max: 100,
        lineWidth: 0,
        tickPositions: []
    },

    plotOptions: {
        solidgauge: {
            dataLabels: {
                enabled: false
            },
            linecap: 'round',
            stickyTracking: false,
            rounded: true
        }
    },

    series: [
        {
            name: data.names[0],
            data: [
                {
                    color: Highcharts.getOptions().colors[0],
                    radius: '112%',
                    innerRadius: '88%',
                    y: getSolidgaugePercentage(data.taux, data.number, 'pourc_Step00')
                }
            ]
        },
        {
            name: data.names[1],
            data: [
                {
                    color: Highcharts.getOptions().colors[1],
                    radius: '87%',
                    innerRadius: '63%',
                    y: getSolidgaugePercentage(data.taux, data.number, 'pourc_Step01')
                }
            ]
        },
        {
            name:  data.names[2],
            data: [
                {
                    color: Highcharts.getOptions().colors[2],
                    radius: '62%',
                    innerRadius: '38%',
                    y: getSolidgaugePercentage(data.taux, data.number, 'pourc_Step02')
                }
            ]
        },
        {
            name:  data.names[3],
            data: [
                {
                    color: Highcharts.getOptions().colors[3],
                    radius: '37%',
                    innerRadius: '13%',
                    y: getSolidgaugePercentage(data.taux, data.number, 'pourc_Step03')
                }
            ]
        }
    ]
}
    )
};

const ChartPie = ({data}) => {
    const configChartPieResult = configChartPie(data);
    return (
        <SolidgaugeChart config={configChartPieResult} />
    );
  };
  
  export default ChartPie;
