import { Descriptions } from 'antd'
import React from 'react'
import '../../static/less/app.less'

const BordereauDetails = ({ data }) => {
    const secondaryColor = localStorage.getItem('secondary_color')
    return (
        <Descriptions labelStyle={{ color: 'white', backgroundColor: secondaryColor ? secondaryColor : '#3CB371'}} size="small" bordered>
            <Descriptions.Item label="Référence">{data.reference}</Descriptions.Item>
            <Descriptions.Item label="Date de réception">{data.receiveDate}</Descriptions.Item>
            <Descriptions.Item label="Statut">None</Descriptions.Item>
            <Descriptions.Item label="Fichier">{data.filename}</Descriptions.Item>
            <Descriptions.Item label="Du S.I.">{data.siEmet}</Descriptions.Item>
            <Descriptions.Item label="Pour le compte de">{data.siDest}</Descriptions.Item>
            <Descriptions.Item label="Type">{data.ordersType}</Descriptions.Item>
            <Descriptions.Item label="Régime">{data.regime}</Descriptions.Item>
            <Descriptions.Item label="Nbre de position">{data.numOrders}</Descriptions.Item>
        </Descriptions>
    )
}

export default BordereauDetails
