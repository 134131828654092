import http from "../utilities/http-common"

const getStyleByIdUser = (idUser) => {
   return http.get('/api/v1/styles/' + idUser);
  };

  
  export default {
    getStyleByIdUser

  };