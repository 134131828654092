import http from 'utilities/http-common'
import ModifyRole from 'pages/parametres/roles/ModifyRole'


const modifierRole = async (profileId, newProfile) => {
    return http.patch('/api/v1/profiles/' + profileId + '/modify', newProfile);
}

const createRole = async (roleData) => {
    return http.post('/api/v1/profiles/create', roleData)
}

const getActions = async (setAction) => {
    http.get('/api/v1/actions').then((res) => setAction(res.data))
}

const modifyRole = async (roleId, visibleUpdateRole, setVisibleUpdateRole, setModale, trackEvent) => {
    await http.get('/api/v1/profile-modules-actions/' + roleId).then((response) => {
        ModifyRole(roleId, response.data, visibleUpdateRole, setVisibleUpdateRole, setModale, trackEvent)
    })
}

const getUserPermissions = (userProfileId) => {
    return http.get('/api/v1/profile-modules-actions/' + userProfileId);
}

export default {
    modifierRole,
    createRole,
    getActions,
    modifyRole,
    getUserPermissions
  };