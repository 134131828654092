import { CheckCircleTwoTone, CloseCircleTwoTone, EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { Button, Card, Divider, Form, Input, Table, Select, message, Modal, Descriptions, Row } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import UserService from '../../services/UserService'
import CreateRole from './roles/CreateRole'
import RoleService from 'services/RoleService'
import { hasActionByModule } from 'utilities/modules'
import { getColumnSearchProps } from '../../utilities/table-utils'
import ParametresService from 'services/ParametresService'
import track, { useTracking } from 'react-tracking'

const clientName = window.localStorage.getItem('name')
const Parametre = () => {
    const { trackEvent } = useTracking()
    const [form] = Form.useForm()
    const [niveaux, setNiveaux] = useState([])
    const [entities, setEntities] = useState([])
    const [roles, setRoles] = useState([])
    const [users, setUsers] = useState([])
    const [visibleCreatedUser, setVisibleCreatedUser] = useState(false)
    const [deletedUser, setDeletedUser] = useState('')
    const [identifiantDeletedUser, setIdentifiantDeletedUser] = useState('')
    const [nameDeletedRole, setNameDeletedRole] = useState('')
    const [deletedRole, setDeletedRole] = useState('')
    const [visible, setVisible] = useState(false)
    const [visibleDeleteUser, setVisibleDeleteUser] = useState(false)
    const [visibleDeleteRole, setVisibleDeleteRole] = useState(false)
    const [visibleUpdateRole, setVisibleUpdateRole] = useState(false)
    const [subordinateProfiles, setSubordinateProfiles] = useState([])
    const [modale, setModale] = useState(null)
    const [userSubordinateEntities, setUserSubordinateEntities] = useState([])
    const [creationUser, setCreationUser] = useState({ entiteId: '', identifiant: '', email: '', roleId: '' })
    const [updatedUser, setUpdatedUser] = useState({
        id: '',
        entiteId: '',
        status: '',
        roleId: '',
        identifiant: '',
        libelleEntite: '',
        roleLibelle: ''
    })
    const currentUserId = localStorage.getItem('id')
    const secondaryColor = localStorage.getItem('secondary_color')
    const primaryColor = localStorage.getItem('primary_color')
    const profile = localStorage.getItem('profile')

    const getUserSubordinateEntities = useCallback(() => {
        ParametresService.getUserSubordinateEntities(currentUserId).then((res) => {
            setUserSubordinateEntities(res.data)
        })
    }, [currentUserId])

    const getHierarchies = useCallback(() => {
        ParametresService.getHierarchies(currentUserId).then((res) => setNiveaux(res.data))
    }, [currentUserId])

    const getSubordinateUsers = useCallback(() => {
        ParametresService.getSubordinateUsers(currentUserId)
            .then((res) => {
                const data = res.data.map((user) => ({
                    id: user.id,
                    key: user.id,
                    identifiant: user.identifiant,
                    libelleEntite: user.entiteLibelle,
                    libelle: user.libelle,
                    actif: user.actif ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor="#ff0000" />,
                    statusUser: user.actif ? 'Actif' : 'Inactif',
                    idProfile: user.idProfile,
                    idEntite: user.idEntite
                }))
                setUsers(data)
                trackEvent({
                    funComponent: 'List-Users',
                    event: 'List-Users-getData'
                })
            })
            .catch((err) => {
                trackEvent({
                    funComponent: 'List-Users',
                    event: 'List-Users-getData',
                    status: JSON.stringify(err.response?.data.status)
                })
                message.error("Une erreur s'est produite lors de la récupération des utilisateurs")
            })
    }, [currentUserId])

    const getSubordinateProfiles = useCallback(() => {
        ParametresService.getSubordinateProfiles(currentUserId)
            .then((res) => {
                const subProfilesData = res.data.map((data) => ({
                    key: data.id_Profil,
                    role: data.profil,
                    hierarchie: data.hierarchie,
                    entite: data.entite,
                    numUsers: data.numUsers
                }))
                setSubordinateProfiles(subProfilesData)
                trackEvent({
                    funComponent: 'List-Profils',
                    event: 'List-Profils-getData'
                })
            })
            .catch((err) => {
                trackEvent({
                    funComponent: 'List-Profils',
                    event: 'List-Profils-getData',
                    status: JSON.stringify(err.response?.data.status)
                })
                message.error("Une erreur s'est produite lors de la récupération des rôles")
            })
    }, [currentUserId])

    useEffect(() => {
        document.title = 'Utilisateurs'
        getHierarchies()
        getUserSubordinateEntities()
        getSubordinateUsers()
        getSubordinateProfiles()
    }, [getHierarchies, getUserSubordinateEntities, getSubordinateUsers, getSubordinateProfiles])

    const [searchText, setSearchText] = useState('')
    const [searchedColumn, setSearchedColumn] = useState('')

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
    }

    const handleReset = (clearFilters) => {
        clearFilters()
        setSearchText('')
    }

    const getHierarchieEntities = async (hierarchieId) => {
        setCreationUser((prevState) => ({
            ...prevState,
            roleId: '',
            entiteId: ''
        }))
        await ParametresService.getHierarchieEntities(currentUserId, hierarchieId)
            .then((res) => {
                setEntities(res.data)
                trackEvent({
                    funComponent: 'List-Entities',
                    event: 'List-Entities-getData'
                })
            })
            .catch((err) => {
                trackEvent({
                    funComponent: 'List-Entities',
                    event: 'List-Entities-getData',
                    status: JSON.stringify(err.response?.data.status)
                })
                message.error("Une erreur s'est produite lors de la récupération des entités")
            })
    }

    const getEntiteRoles = async (entiteId) => {
        await ParametresService.getEntiteRoles(entiteId)
            .then((res) => {
                setRoles(res.data)
                trackEvent({
                    funComponent: 'List-Entities-Profils',
                    event: 'List-Entities-Profils-getData'
                })
            })
            .catch((err) => {
                trackEvent({
                    funComponent: 'List-Entities-Profils',
                    event: 'List-Entities-Profils-getData',
                    status: JSON.stringify(err.response?.data.status)
                })
                message.error("Une erreur s'est produite lors de la récupération des profils")
            })
    }

    const handleUserCreation = async (userData) => {
        message.loading("Creation de l'utilisateur ...")
        await ParametresService.createUser(userData)
            .then(() => {
                setVisibleCreatedUser(true)
                message.destroy()
                form.resetFields()
                getSubordinateUsers()
                getSubordinateProfiles()
                trackEvent({
                    funComponent: 'Create-User',
                    event: 'Create-User-sendData'
                })
            })
            .catch((e) => {
                message.destroy()
                if (e.response?.data.status === 500) {
                    message.error("Une erreur est survenue lors de la création de l'utilisateur.")
                } else {
                    message.error(e.response.data.message)
                }
                trackEvent({
                    funComponent: 'Create-User',
                    event: 'Create-User-sendData',
                    status: JSON.stringify(e.response?.data.status)
                })
            })
    }
    const cancelAlertCretedUser = () => {
        setVisibleCreatedUser(false)
    }
    const alertUserBienCree = () => {
        return (
            <Modal
                visible={visibleCreatedUser}
                icon={<ExclamationCircleOutlined />}
                onCancel={cancelAlertCretedUser}
                footer={[
                    <Button key="back" id={'Button-ok-createdUser'} onClick={cancelAlertCretedUser}>
                        Ok
                    </Button>
                ]}
            >
                <p>
                    Un mot de passe temporaire a été envoyé à l'adresse électronique spécifiée.\nL'utilisateur doit se connecter pour créer son propre
                    mot de passe
                </p>
            </Modal>
        )
    }
    const deleteUser = (userId) => {
        ParametresService.deleteUser(userId)
            .then(() => {
                trackEvent({
                    funComponent: 'Button-Confirmer-delete-user',
                    event: 'Button-Confirmer-delete-user-' + userId + '-Clicked',
                    user: clientName
                })
                message.success("L'utilisateur a été supprimé avec succès")
                getSubordinateUsers()
                getSubordinateProfiles()
                setVisibleDeleteUser(false)
                trackEvent({
                    funComponent: 'Delete-User',
                    event: 'Delete-User-sendData'
                })
            })
            .catch((err) => {
                trackEvent({
                    funComponent: 'Delete-User',
                    event: 'Delete-User-sendData',
                    status: JSON.stringify(err.response?.data.status)
                })
                message.error("Une erreur est survenue lors de la suppression de l'utilisateur.")
            })
    }
    const cancelDeleteUser = () => {
        setVisibleDeleteUser(false)
    }
    const confirmDeleteUser = () => {
        return (
            <Modal
                visible={visibleDeleteUser}
                title="Confirmer"
                icon={<ExclamationCircleOutlined />}
                onCancel={cancelDeleteUser}
                footer={[
                    <Button key="back" id={'Button-annuler-delete-user'} onClick={cancelDeleteUser}>
                        Annuler
                    </Button>,
                    <Button key="submit" type="primary" id={'Button-delete-user'} onClick={() => deleteUser(deletedUser)}>
                        Confirmer
                    </Button>
                ]}
            >
                <p>Vous êtes sur de vouloir supprimer l'utilisateur {identifiantDeletedUser} ?</p>
            </Modal>
        )
    }
    const deleteRole = (roleId) => {
        ParametresService.deleteRole(roleId)
            .then(() => {
                trackEvent({
                    funComponent: 'Button-Confirmer-delete-role',
                    event: 'Button-Confirmer-delete-role-' + roleId + '-Clicked',
                    user: clientName
                })
                message.success('Le rôle  a été supprimé avec succès')
                getSubordinateUsers()
                getSubordinateProfiles()
                setVisibleDeleteRole(false)
                trackEvent({
                    funComponent: 'Delete-Profil',
                    event: 'Delete-Profil-sendData'
                })
            })
            .catch((err) => {
                trackEvent({
                    funComponent: 'Delete-Profil',
                    event: 'Delete-Profil-sendData',
                    status: JSON.stringify(err.response?.data.status)
                })
                message.error('Une erreur est survenue lors de la suppression de lu rôle.')
            })
    }

    const cancelDeleteRole = () => {
        setVisibleDeleteRole(false)
    }
    const confirmDeleteRole = () => {
        return (
            <Modal
                visible={visibleDeleteRole}
                title="Confirmer"
                icon={<ExclamationCircleOutlined />}
                onCancel={cancelDeleteRole}
                footer={[
                    <Button key="back" onClick={cancelDeleteRole} id={'Button-annuler-delete-role'}>
                        Annuler
                    </Button>,
                    <Button key="submit" type="primary" id={'Button-delete-role'} onClick={() => deleteRole(deletedRole)}>
                        Confirmer
                    </Button>
                ]}
            >
                <p>Vous êtes sur de vouloir supprimer le rôle {nameDeletedRole}?</p>
            </Modal>
        )
    }
    const handleReinitialiser = (idUser, identifiant) => {
        UserService.reinitialiserPassword(idUser)
            .then(() => {
                trackEvent({
                    funComponent: 'Reinitialisation-Password',
                    event: 'Reinitialisation-Password-sendData'
                })
                message.success("Le nouveau mot de passe a été envoyé à l'email de l'utilisateur " + identifiant)
            })
            .catch((err) => {
                trackEvent({
                    funComponent: 'Reinitialisation-Password',
                    event: 'Reinitialisation-Password-sendData',
                    status: JSON.stringify(err.response?.data.status)
                })
                message.error('Une erreur est survenue lors de la reinitialisation du mot de passe.')
            })
    }
    const afficherModal = () => {
        return (
            <Modal
                visible={visible}
                title={"Modification de l'utilisateur : " + updatedUser.identifiant}
                onCancel={handleCancel}
                footer={[
                    <Button
                        id="btn-reinitialiser-password"
                        key="test"
                        type="primary"
                        onClick={() => {
                            handleReinitialiser(updatedUser.id, updatedUser.identifiant)
                            trackEvent({
                                funComponent: 'Button-Reinitialiser-Password',
                                event: 'Button-Reinitialiser-Password-Clicked',
                                user: clientName
                            })
                        }}
                    >
                        Reinitialiser le mot de passe
                    </Button>,
                    <Button
                        id="btn-edit"
                        type="primary"
                        onClick={() => {
                            handleModifierUser()
                            trackEvent({
                                funComponent: 'Button-Edit',
                                event: 'Button-Edit-Clicked',
                                user: clientName
                            })
                        }}
                    >
                        Modifier
                    </Button>,
                    <Button
                        id="btn-fermer"
                        key="back"
                        type="primary"
                        onClick={() => {
                            handleCancel()
                            trackEvent({
                                funComponent: 'Button-Fermer',
                                event: 'Button-Fermer-Clicked',
                                user: clientName
                            })
                        }}
                    >
                        Fermer
                    </Button>
                ]}
            >
                <Form
                    labelCol={{
                        span: 8
                    }}
                    wrapperCol={{
                        span: 8
                    }}
                    layout="horizontal"
                    method="POST"
                    onFinish={handleUserCreation}
                >
                    <br />

                    <Form.Item label="Entité" name="entite" required>
                        <Input
                            id="user-edit-entity"
                            style={{ width: '100%' }}
                            required
                            key={`libelleEntite:${updatedUser.entiteId || updatedUser.entiteId}`}
                            defaultValue={'' || updatedUser.libelleEntite}
                            disabled
                        ></Input>
                    </Form.Item>
                    <Form.Item label="Statut" name="status" required>
                        <Select
                            id="user-edit-status"
                            key={`status:${updatedUser.status || updatedUser.status}`}
                            defaultValue={'' || updatedUser.status}
                            allowClear
                            onChange={(val) => {
                                setUpdatedUser((prevState) => ({ ...prevState, status: val }))
                                trackEvent({
                                    funComponent: 'User-Edit-Status',
                                    event: 'User-Edit-Status-' + val + '-choosed',
                                    user: clientName
                                })
                            }}
                        >
                            <Select.Option id="actif" key="actif" value="actif">
                                Actif
                            </Select.Option>
                            <Select.Option id="inactif" key="inactif" value="inactif">
                                Inactif
                            </Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="Rôle" name="role" required>
                        <Select
                            id="user-edit-role"
                            key={`roleLibelle:${updatedUser.roleId || updatedUser.roleId}`}
                            defaultValue={'' || updatedUser.roleLibelle}
                            datasource={roles}
                            allowClear
                            style={{ width: '100%' }}
                            placeholder="Sélectionner"
                            onChange={(val) => {
                                setUpdatedUser((prevState) => ({ ...prevState, roleId: val }))
                                trackEvent({
                                    funComponent: 'User-Edit-Role',
                                    event: 'User-Edit-Role-' + val + '-choosed',
                                    user: clientName
                                })
                            }}
                        >
                            {roles.map((role) => (
                                <Select.Option id={role.libelle} key={role.id} value={role.id}>
                                    {role.libelle}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        )
    }

    const handleCancel = () => {
        setUpdatedUser({ id: '', entiteId: '', status: '', roleId: '', identifiant: '', libelleEntite: '', roleLibelle: '' })
        setVisible(false)
    }
    const getIdEntitByName = (name) => {
        const idEntity = userSubordinateEntities.filter((entity) => entity.libelle === name)[0]?.id
        return idEntity
    }
    const handleModifierUser = async () => {
        if (updatedUser.id !== '' && updatedUser.status !== '' && updatedUser.roleId !== '' && updatedUser.entiteId !== '') {
            UserService.modifierUser(updatedUser)
                .then(() => {
                    message.success("L'utilisateur est modifié avec succès")
                    setUpdatedUser({
                        id: '',
                        entiteId: '',
                        status: '',
                        roleId: '',
                        identifiant: '',
                        libelleEntite: '',
                        roleLibelle: ''
                    })
                    setVisible(false)
                    getSubordinateUsers()
                    trackEvent({
                        funComponent: 'Update-User',
                        event: 'Update-User-sendData'
                    })
                })
                .catch((err) => {
                    trackEvent({
                        funComponent: 'Update-User',
                        event: 'Update-User-sendData',
                        status: JSON.stringify(err.response?.data.status)
                    })
                    message.error("Une erreur est survenue lors de la modification d'utilisateur.")
                })
        } else {
            message.error('vous pouvez pas modifier cet utilisateur, il faut remplir tous les champs')
        }
    }

    return (
        <>
            {hasActionByModule('Utilisateur(s)', 'Accéder') && (
                <Card type="inner" title="Utilisateur(s)">
                    <div className="ant-row">
                        {hasActionByModule('Utilisateur(s)', 'Lister') && (
                            <div className="ant-col ant-col-xs-24 ant-col-xl-11">
                                <h1 style={{ fontWeight: 700 }}>Liste des utilisateurs</h1>
                                <br />
                                <Table
                                    id="table-utilisateur"
                                    columns={[
                                        {
                                            id: 'id',
                                            title: 'Id',
                                            dataIndex: 'id',
                                            sorter: {
                                                compare: (cell1Data, cell2Data) => cell1Data.id - cell2Data.id,
                                                multiple: 1
                                            }
                                        },
                                        {
                                            id: 'identifiant',
                                            title: 'Identifiant',
                                            dataIndex: 'identifiant',
                                            ...getColumnSearchProps('identifiant', searchText, searchedColumn, handleSearch, handleReset),
                                            sorter: {
                                                compare: (cell1Data, cell2Data) => cell1Data.identifiant.localeCompare(cell2Data.identifiant),
                                                multiple: 2
                                            }
                                        },
                                        {
                                            id: 'entite',
                                            title: 'Entité',
                                            dataIndex: 'libelleEntite',
                                            ...getColumnSearchProps('libelleEntite', searchText, searchedColumn, handleSearch, handleReset),
                                            sorter: {
                                                compare: (cell1Data, cell2Data) => cell1Data.libelleEntite.localeCompare(cell2Data.libelleEntite),
                                                multiple: 3
                                            }
                                        },
                                        {
                                            id: 'role',
                                            title: 'Rôles',
                                            dataIndex: 'libelle',
                                            ...getColumnSearchProps('libelle', searchText, searchedColumn, handleSearch, handleReset),
                                            sorter: {
                                                compare: (cell1Data, cell2Data) => cell1Data.libelle.localeCompare(cell2Data.libelle),
                                                multiple: 4
                                            }
                                        },
                                        {
                                            id: 'statut',
                                            title: 'Statut',
                                            dataIndex: 'actif',
                                            sorter: {
                                                compare: (cell1Data, cell2Data) => (cell2Data.actif && !cell1Data.actif ? 1 : -1),
                                                multiple: 5
                                            }
                                        },
                                        hasActionByModule('Utilisateur(s)', 'Editer') || hasActionByModule('Utilisateur(s)', 'Supprimer')
                                            ? {
                                                  title: 'Action',
                                                  dataIndex: 'print',

                                                  render: (text, record) => (
                                                      <div
                                                          className="btn-wrap"
                                                          style={{
                                                              width: '120px'
                                                          }}
                                                      >
                                                          {hasActionByModule('Utilisateur(s)', 'Editer') && record.id.toString() !== currentUserId && (
                                                              <Button
                                                                  id="btn-edit-user"
                                                                  onClick={() => {
                                                                      setVisible(true)
                                                                      setUpdatedUser((prevState) => ({
                                                                          ...prevState,
                                                                          id: record.id,
                                                                          identifiant: record.identifiant,
                                                                          libelleEntite: record.libelleEntite,
                                                                          roleLibelle: record.libelle,
                                                                          roleId: record.idProfile,
                                                                          entiteId: record.idEntite,
                                                                          status: record.statusUser
                                                                      }))
                                                                      getEntiteRoles(getIdEntitByName(record.libelleEntite))
                                                                      trackEvent({
                                                                          funComponent: 'Button-Edit-user',
                                                                          event: 'Button-Edit-user-' + record.id + '-clicked',
                                                                          user: clientName
                                                                      })
                                                                  }}
                                                              >
                                                                  <EditOutlined />
                                                              </Button>
                                                          )}

                                                          {hasActionByModule('Utilisateur(s)', 'Supprimer') &&
                                                              record.id.toString() !== currentUserId && (
                                                                  <Button
                                                                      id="btn-delete-user"
                                                                      onClick={() => {
                                                                          trackEvent({
                                                                              funComponent: 'Button-delete-user',
                                                                              event: 'Button-delete-user-' + record.id + '-clicked',
                                                                              user: clientName
                                                                          })
                                                                          setVisibleDeleteUser(true)
                                                                          setDeletedUser(record.id)
                                                                          setIdentifiantDeletedUser(record.identifiant)
                                                                      }}
                                                                  >
                                                                      <DeleteOutlined />
                                                                  </Button>
                                                              )}
                                                      </div>
                                                  )
                                              }
                                            : {}
                                    ].filter((item) => !item.hidden)}
                                    size="middle"
                                    scroll={{ x: 120 }}
                                    dataSource={users}
                                    pagination={{ pageSize: 4 }}
                                />
                            </div>
                        )}
                        {visible ? afficherModal() : null}
                        <Divider type="vertical" />
                        <Divider type="vertical" />
                        <Divider type="vertical" />
                        {hasActionByModule('Utilisateur(s)', 'Créer') && (
                            <div id="container" className="ant-col ant-col-xs-24 ant-col-xl-14">
                                <h1 style={{ fontWeight: 700 }}>Creation d'un utilisateur</h1>
                                <Form method="POST" id="createForm" onFinish={() => handleUserCreation(creationUser)} form={form}>
                                    <Descriptions
                                        style={{ marginTop: '1.5rem' }}
                                        labelStyle={{ color: 'white', backgroundColor: secondaryColor ? secondaryColor : '#3CB371' }}
                                        size="small"
                                        bordered
                                        column={1}
                                    >
                                        <Form.Item label="Niveau" name="niveau">
                                            <Select
                                                id="user-niveau"
                                                style={{ width: '100%' }}
                                                required
                                                datasource={niveaux}
                                                bordered={false}
                                                placeholder="Sélectionner l'hiérarchie"
                                                onChange={getHierarchieEntities}
                                            >
                                                {niveaux.map((niveau) => (
                                                    <Select.Option
                                                        id={niveau.libelle}
                                                        required
                                                        key={niveau.id}
                                                        value={niveau.libelle}
                                                        onClick={() =>
                                                            trackEvent({
                                                                funComponent: 'User-niveau',
                                                                event: 'User-niveau-' + '-selected',
                                                                user: clientName
                                                            })
                                                        }
                                                    >
                                                        {niveau.libelle}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>

                                        <Form.Item label="Entité" name="entity" required>
                                            <Select
                                                id="user-entity"
                                                style={{ width: '100%' }}
                                                required
                                                datasource={entities}
                                                allowClear
                                                onClear={() => {
                                                    setCreationUser((prevState) => ({
                                                        ...prevState,
                                                        entiteId: ''
                                                    }))
                                                }}
                                                bordered={false}
                                                placeholder="Sélectionner l'entité"
                                                onChange={(val) => {
                                                    setCreationUser((prevState) => ({
                                                        ...prevState,
                                                        roleId: '',
                                                        entiteId: val
                                                    }))
                                                    trackEvent({
                                                        funComponent: 'User-entity',
                                                        event: 'User-entity-' + val + '-selected',
                                                        user: clientName
                                                    })
                                                    getEntiteRoles(val)
                                                }}
                                                value={creationUser.entiteId === '' ? undefined : creationUser.entiteId}
                                            >
                                                {entities.map((entity) => (
                                                    <Select.Option id={entity.libelle} key={entity.id} value={entity.id}>
                                                        {entity.libelle}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item label="E-mail" name="email" required>
                                            <Input
                                                id="user-email"
                                                style={{ width: '100%' }}
                                                placeholder="Insérer l'email de l'utilisateur"
                                                type="email"
                                                bordered={false}
                                                required
                                                onChange={(val) => {
                                                    setCreationUser((prevState) => ({ ...prevState, email: val.target.value }))
                                                }}
                                            />
                                        </Form.Item>
                                        <Form.Item label="Identifiant" name="identifiant" required>
                                            <Input
                                                id="user-identifiant"
                                                style={{ width: '100%' }}
                                                placeholder="Insérer l'identifiant de l'utilisateur"
                                                required
                                                bordered={false}
                                                onChange={(val) => {
                                                    setCreationUser((prevState) => ({ ...prevState, identifiant: val.target.value }))
                                                }}
                                            />
                                        </Form.Item>

                                        <Form.Item label="Rôle" name="role" required>
                                            <Select
                                                id="user-role"
                                                style={{ width: '100%' }}
                                                datasource={roles}
                                                allowClear
                                                placeholder="Sélectionnez le rôle de l'utilisateur"
                                                bordered={false}
                                                onChange={(val) => {
                                                    setCreationUser((prevState) => ({ ...prevState, roleId: val }))
                                                    trackEvent({
                                                        funComponent: 'User-role',
                                                        event: 'User-role-' + val + '-choosed',
                                                        user: clientName
                                                    })
                                                }}
                                                value={creationUser.roleId === '' ? undefined : creationUser.roleId}
                                            >
                                                {roles.map((role) => (
                                                    <Select.Option id={role.libelle} key={role.id} value={role.id}>
                                                        {role.libelle}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Descriptions>

                                    <Row>
                                        <Form.Item style={{ marginTop: '1rem' }}>
                                            <Button
                                                id="btn-creer"
                                                style={{
                                                    backgroundColor: primaryColor ? primaryColor : '#3CB371',
                                                    color: 'white',
                                                    borderRadius: '10%'
                                                }}
                                                onClick={() => {
                                                    trackEvent({
                                                        funComponent: 'User-email',
                                                        event: 'User-email-' + creationUser.email + '-puted',
                                                        user: clientName
                                                    })
                                                    trackEvent({
                                                        funComponent: 'User-identifiant',
                                                        event: 'User-identifiant-' + creationUser.identifiant + '-puted',
                                                        user: clientName
                                                    })
                                                    trackEvent({
                                                        funComponent: 'Button-Cree-user',
                                                        event: 'Button-Cree-use-clicked',
                                                        user: clientName
                                                    })
                                                }}
                                                htmlType="submit"
                                                size="large"
                                            >
                                                Créer
                                            </Button>
                                        </Form.Item>
                                    </Row>
                                </Form>
                            </div>
                        )}
                    </div>
                </Card>
            )}
            <Divider />
            {hasActionByModule('Rôle(s)', 'Accéder') && (
                <Card type="inner" title="Rôle(s)">
                    <div className="ant-row">
                        {hasActionByModule('Rôle(s)', 'Lister') && (
                            <div className="ant-col ant-col-xs-24 ant-col-xl-9">
                                <h1 style={{ fontWeight: 700 }}>Liste des rôles</h1>
                                <br />
                                <Table
                                    id="table-roles"
                                    columns={[
                                        {
                                            id: 'id',
                                            title: 'id',
                                            dataIndex: 'key',
                                            hidden: true,
                                            width: '0%'
                                        },
                                        {
                                            id: 'hierarchie',
                                            title: 'Hiérarchie',
                                            dataIndex: 'hierarchie',
                                            width: '15%',
                                            ...getColumnSearchProps('hierarchie', searchText, searchedColumn, handleSearch, handleReset),
                                            sorter: {
                                                compare: (cell1Data, cell2Data) => cell1Data.hierarchie.localeCompare(cell2Data.hierarchie),
                                                multiple: 4
                                            }
                                        },
                                        {
                                            id: 'entite',
                                            title: 'Entité',
                                            dataIndex: 'entite',
                                            ellipsis: true,
                                            width: '15%',
                                            ...getColumnSearchProps('entite', searchText, searchedColumn, handleSearch, handleReset),
                                            sorter: {
                                                compare: (cell1Data, cell2Data) => cell1Data.entite.localeCompare(cell2Data.entite),
                                                multiple: 4
                                            }
                                        },
                                        {
                                            id: 'role',
                                            title: 'Rôle',
                                            ellipsis: true,
                                            dataIndex: 'role',
                                            width: '15%',
                                            ...getColumnSearchProps('role', searchText, searchedColumn, handleSearch, handleReset),
                                            sorter: {
                                                compare: (cell1Data, cell2Data) => cell1Data.role.localeCompare(cell2Data.role),
                                                multiple: 4
                                            }
                                        },
                                        {
                                            id: 'numUsers',
                                            title: 'Nombre des utilisateurs',
                                            ellipsis: true,
                                            dataIndex: 'numUsers',
                                            width: '12%',
                                            ...getColumnSearchProps('numUsers', searchText, searchedColumn, handleSearch, handleReset)
                                        },
                                        hasActionByModule('Rôle(s)', 'Editer') || hasActionByModule('Rôle(s)', 'Supprimer')
                                            ? {
                                                  title: 'Action',
                                                  dataIndex: 'print',
                                                  render: (text, record, index) => (
                                                      <div
                                                          className="btn-wrap"
                                                          style={{
                                                              width: '90px'
                                                          }}
                                                      >
                                                          {hasActionByModule('Rôle(s)', 'Editer') && record.key?.toString() !== profile && (
                                                              <Button
                                                                  key={'edit-' + index}
                                                                  id="btn-edit-role"
                                                                  size="small"
                                                                  onClick={() => {
                                                                      trackEvent({
                                                                          funComponent: 'Button-Edit-Role',
                                                                          event: 'Button-Edit-Role-' + record.key + '-clicked',
                                                                          user: clientName
                                                                      })
                                                                      message.loading('Action in progress..', 1).then(() => {
                                                                          setVisibleUpdateRole(true)

                                                                          RoleService.modifyRole(
                                                                              record.key,
                                                                              true,
                                                                              setVisibleUpdateRole,
                                                                              setModale,
                                                                              trackEvent
                                                                          )
                                                                      })
                                                                  }}
                                                              >
                                                                  <EditOutlined />
                                                              </Button>
                                                          )}
                                                          {hasActionByModule('Rôle(s)', 'Supprimer') && record.key?.toString() !== profile && (
                                                              <Button
                                                                  key={index}
                                                                  id="btn-delete-role"
                                                                  size="small"
                                                                  onClick={() => {
                                                                      trackEvent({
                                                                          funComponent: 'Button-Delete-Role',
                                                                          event: 'Button-Delete-Role-' + record.key + '-clicked',
                                                                          user: clientName
                                                                      })
                                                                      setDeletedRole(record.key)
                                                                      setNameDeletedRole(record.role)
                                                                      setVisibleDeleteRole(true)
                                                                  }}
                                                              >
                                                                  <DeleteOutlined />
                                                              </Button>
                                                          )}
                                                      </div>
                                                  ),
                                                  width: '14%'
                                              }
                                            : {}
                                    ].filter((item) => !item.hidden)}
                                    size="middle"
                                    dataSource={subordinateProfiles}
                                    pagination={{ marginTop: '1rem', pageSize: 6 }}
                                />
                            </div>
                        )}

                        <Divider type="vertical" />
                        <Divider type="vertical" />
                        <Divider type="vertical" />
                        {hasActionByModule('Rôle(s)', 'Créer') && (
                            <div className="ant-col ant-col-xs-24 ant-col-xl-14">
                                <CreateRole userSubordinateEntities={userSubordinateEntities} reloadRoleList={getSubordinateProfiles} />
                            </div>
                        )}
                    </div>
                </Card>
            )}
            {visibleDeleteUser ? confirmDeleteUser() : null}
            {visibleDeleteRole ? confirmDeleteRole() : null}
            {visibleCreatedUser ? alertUserBienCree() : null}
            {visibleUpdateRole ? modale : null}
        </>
    )
}

export default Parametre

