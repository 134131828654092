import { combineReducers } from 'redux'
import { clientsReducer } from 'store/async/clients/clients-reducer'
import { feedbackManagerReducer } from 'store/async/feedbacks/feedbacks-reducer'
import { auditLogsReducer } from 'store/async/logs/logs-reducer'
import { metaReducer } from 'store/async/meta/meta-reducer'
import { reportsReducer } from 'store/async/reports/reports-reducer'
import { userReducer } from 'store/async/users/users-reducer'
import { authReducer } from 'store/async/auth/auth-reducer'
import alerts from 'store/reducers/alerts'
import { siteInfoReducer } from 'store/reducers/site-info'

export default combineReducers({
    alerts,
    clients: clientsReducer,
    feedbackManager: feedbackManagerReducer,
    meta: metaReducer,
    auditLogs: auditLogsReducer,
    reports: reportsReducer,
    siteInfo: siteInfoReducer,
    users: userReducer,
    auth: authReducer
})
