import { ADD_ALERT, EMPTY_ALERTS, REMOVE_ALERT_BY_URL, SET_SITE_INFO_TITLE, UPDATE_USER_INFO, SET_SITE_INFO_DESCRIPTION } from './actionTypes'

export const setSiteInfoTitle = (title) => ({
    type: SET_SITE_INFO_TITLE,
    payload: title
})

export const setSiteInfoDescription = (description) => ({
    type: SET_SITE_INFO_DESCRIPTION,
    payload: description
})

export const addAlert = (alert) => ({
    type: ADD_ALERT,
    payload: alert
})

export const removeAlertByUrl = (url) => ({
    type: REMOVE_ALERT_BY_URL,
    payload: url
})

export const emptyAlerts = () => ({
    type: EMPTY_ALERTS
})

export const updateUserInfo = (userInfo) => ({
    type: UPDATE_USER_INFO,
    payload: userInfo
})
