import { Card, Divider, message } from 'antd'
import React, { useState } from 'react'
import { RetentionForm } from 'components/Formulaires'
import { RetentionsTable } from 'components/Tables'
import { hasActionByModule } from 'utilities/modules'
import RetentionService from '../../services/RetentionService'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import track, { useTracking } from 'react-tracking'

const currentUserId = localStorage.getItem('id')

const Retention = () => {
    const { trackEvent } = useTracking()
    const { t } = useTranslation()
    const [retentions, setRetentions] = useState([])
    const [intersectionRetentions, setIntersectionRetentions] = useState([])

    const isExist = (orderType, emetteur, destinataire, startDate, endDate, currentRetentions) => {
        setIntersectionRetentions([])
        let exist = false
        currentRetentions.forEach((retention) => {
            if (orderType === retention.orderType && emetteur === retention.emetteur && destinataire === retention.destinataire) {
                if (checkIntersectionDates(retention.dateDebut, retention.dateFin, startDate, endDate)) {
                    exist = true
                    intersectionRetentions.push(retention)
                }
            }
        })
        return exist
    }
    const getRetentions = () => {
        const result = []
        RetentionService.getUserRetentions(currentUserId)
            .then((response) => {
                response.data.forEach((retention) => {
                    const data = {}
                    data.key = retention.id
                    data.id = retention.id
                    data.emetteur = retention.emetteur ? retention.emetteur.libelle : ''
                    data.destinataire = retention.destinataire ? retention.destinataire.libelle : ''
                    data.orderType = retention.ordersType ? retention.ordersType.libelle : ''
                    let duration = moment.duration(retention.delai, 'minutes')
                    data.delai = duration.days() + ' jours et ' + duration.hours() + ' heures'
                    data.dateDebut = retention.dateFrom
                    data.dateFin = retention.dateTo
                    result.push(data)
                })

                setRetentions(result)
                trackEvent({
                    funComponent: 'List-Retentions',
                    event: 'List-Retentions-getData'
                })
            })
            .catch((error) => {
                trackEvent({
                    funComponent: 'List-Retentions',
                    event: 'List-Retentions-getData',
                    status: JSON.stringify(error.response?.data.status)
                })
                message.error("Impossible d'afficher les retentions")
            })
    }
    const checkIntersectionDates = (dateFromNew, dateToNew, dateFromOld, dateToOld) => {
        let dateFinRetentionExistent = null
        let dateFinNouvelleRetention = null
        if (dateToNew !== null) dateFinRetentionExistent = moment(new Date(dateToNew)).format('YYYY-MM-DD')
        if (dateToOld !== null) dateFinNouvelleRetention = moment(new Date(dateToOld)).format('YYYY-MM-DD')
        let dateDebutRetentionExistent = moment(new Date(dateFromNew)).format('YYYY-MM-DD')
        let dateDebutNouvelleRetention = moment(new Date(dateFromOld)).format('YYYY-MM-DD')
        if (dateFinRetentionExistent === null && dateFinNouvelleRetention === null) {
            return true
        }
        if (dateFinRetentionExistent === null && dateDebutRetentionExistent <= dateFinNouvelleRetention) {
            return true
        }
        if (dateFinNouvelleRetention === null && dateFinRetentionExistent >= dateDebutNouvelleRetention) {
            return true
        }
        if (
            (dateDebutNouvelleRetention >= dateDebutRetentionExistent && dateDebutNouvelleRetention <= dateFinRetentionExistent) ||
            (dateFinNouvelleRetention >= dateDebutRetentionExistent && dateFinNouvelleRetention <= dateFinRetentionExistent)
        ) {
            return true
        }
        if (
            dateDebutRetentionExistent >= dateDebutNouvelleRetention &&
            dateDebutRetentionExistent <= dateFinNouvelleRetention &&
            dateFinRetentionExistent >= dateDebutNouvelleRetention &&
            dateFinRetentionExistent <= dateFinNouvelleRetention
        ) {
            return true
        }
        return false
    }

    return (
        <>
            {hasActionByModule('Retention', 'Accéder') && (
                <>
                    {hasActionByModule('Retention', 'Créer') && (
                        <Card>
                            <h1 className="blod">{t('Retention')}</h1>
                            <RetentionForm
                                isExist={isExist}
                                retentions={retentions}
                                getRetentions={getRetentions}
                                intersectionRetentions={intersectionRetentions}
                            />
                        </Card>
                    )}
                    <Divider />
                    {hasActionByModule('Retention', 'Lister') && (
                        <Card>
                            <h1 className="blod">{t('TableRetentions')}</h1>
                            <RetentionsTable
                                isExist={isExist}
                                retentions={retentions}
                                getRetentions={getRetentions}
                                intersectionRetentions={intersectionRetentions}
                            />
                        </Card>
                    )}
                </>
            )}
        </>
    )
}

export { Retention }
