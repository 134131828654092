import React, { useState, useEffect } from 'react'
import '../../static/less/app.less'
import { Form, Button, Radio, Select, DatePicker, Space, message } from 'antd'
import RetentionService from '../../services/RetentionService'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import track, { useTracking } from 'react-tracking'

const { Option } = Select

const RetentionForm = ({ isExist, retentions, getRetentions, intersectionRetentions }) => {
    const { trackEvent } = useTracking()
    const { t } = useTranslation()
    const [ordersTypes, setOrdersTypes] = useState([])
    const [intervenants, setIntervenants] = useState([])
    const [hours, setHours] = useState(0)
    const [days, setDays] = useState(0)
    const [nb, setNb] = useState(0)
    const [retentionStatus, setRetentionStatus] = useState({
        orderTypeStatus: true,
        emetteurStatus: true,
        destinataireStatus: true,
        dateDebutStatus: true
    })


    const [dataRetention, setDataRetention] = useState({
        orderType: null,
        orderTypeLibelle: '',
        emetteur: null,
        emetteurLibelle: '',
        destinataire: null,
        destinataireLibelle: '',
        delaiMinutes: 0,
        startDate: null,
        endDate: null
    })

    useEffect(() => {
        RetentionService.getOrdersTypes().then((res) => {
            setOrdersTypes(res.data)
        })
        RetentionService.getIntervenants()
            .then((res) => {
                setIntervenants(res.data)
                trackEvent({
                    funComponent: 'List-intervenants',
                    event: 'List-intervenants-getData'
                })
            })
            .catch((err) => {
                trackEvent({
                    funComponent: 'List-intervenants',
                    event: 'List-intervenants-getData',
                    status: JSON.stringify(err.response?.data.status)
                })
                message.error("Impossible d'afficher les intervenants")
            })

        getRetentions()
    }, [])

    const resetInput = () => {
        setRetentionStatus({
            orderTypeStatus: false,
            emetteurStatus: false,
            destinataireStatus: false,
            dateDebutStatus: false
        })
        document.getElementById("retentionForm").reset();
        setRetentionStatus({
            orderTypeStatus: true,
            emetteurStatus: true,
            destinataireStatus: true,
            dateDebutStatus: true
        })

    }

    const onFormSubmit = () => {
        dataRetention.delaiMinutes = days * 24 * 60 + hours * 60
        if (!dataRetention.orderType || !dataRetention.emetteur || !dataRetention.destinataire || !dataRetention.startDate) {
            message.error('Veuillez remplir tous les champs')
            setNb(nb+1);
            return
        }
        if (moment(dataRetention.endDate).format('YYYY-MM-DD') <= moment(dataRetention.startDate).format('YYYY-MM-DD')) {
            message.error('Veuillez choisir au moins un jour ouvrable entre Date début et la date de fin')
            setNb(nb+1);
            return
        }
        if (dataRetention.delaiMinutes === 0) {
            message.error('Veuillez choisir au moins une demi-journée dans le délai imparti')
            setNb(nb+1);
            return
        }
        if (
            isExist(
                dataRetention.orderTypeLibelle,
                dataRetention.emetteurLibelle,
                dataRetention.destinataireLibelle,
                dataRetention.startDate,
                dataRetention.endDate,
                retentions
            )
        ) {
            let messageAlert = 'Cette rétention interférant avec '
            messageAlert = messageAlert + (intersectionRetentions.length === 1 ? 'une rétention :' : +intersectionRetentions.length + ' rétentions :')
            intersectionRetentions.filter(
                (intersectionRetention, index) =>
                    (messageAlert =
                        messageAlert +
                        ' rétention (' +
                        (index + 1) +
                        ') => Date Début: ' +
                        intersectionRetention.dateDebut +
                        ' et Date Fin: ' +
                        intersectionRetention.dateFin)
            )
            message.error(messageAlert)
            return
        } else {
            RetentionService.createRetention(dataRetention)
                .then(() => {
                    message.success('Règle de rétention crée avec succès')
                    trackEvent({
                        funComponent: 'Create-Retention',
                        event: 'Create-Retention-sendData'
                    })
                    getRetentions()
                    resetInput();

                })
                .catch((err) => {
                    trackEvent({
                        funComponent: 'Create-Retention',
                        event: 'Create-Retention-sendData',
                        status: JSON.stringify(err.response?.data.status)
                    })
                    message.error(err.response.data)
                })
        }
    }

    return (
        <>
            <div style={{ marginLeft: '10%' }}>
                <Form
                    id="retentionForm"
                    wrapperCol={{
                        span: 14
                    }}
                    layout="horizontal"
                >
                    <div className="ant-row">
                        <div className="ant-col ant-col-xs-24 ant-col-xl-8">
                            <Form.Item
                                label={t('OrdreType')}
                                name="type"
                                rules={[
                                    {
                                        required: retentionStatus.orderTypeStatus
                                    }
                                ]}
                            >
                                <Radio.Group style={{ marginLeft: '5px' }}>
                                    {ordersTypes.map((orderType) => (
                                        <Radio.Button
                                            id={'Ordre-Type-' + orderType.libelle}
                                            key={orderType.id}
                                            value={orderType.id}
                                            onChange={(order) => {
                                                setDataRetention((prevState) => ({
                                                    ...prevState,
                                                    orderType: order.target.value,
                                                    orderTypeLibelle: orderType.libelle
                                                }))
                                                trackEvent({
                                                    funComponent: 'Ordre-Type',
                                                    event: 'Ordre-Type-' + orderType.libelle + '-choosed'
                                                })
                                            }}
                                        >
                                            {orderType.libelle}
                                        </Radio.Button>
                                    ))}
                                </Radio.Group>
                            </Form.Item>
                        </div>
                        <div className="ant-col ant-col-xs-24 ant-col-xl-8">
                            <Form.Item
                                label={t('Emetteur')}
                                name="emetteur"
                                rules={[
                                    {
                                        required: retentionStatus.emetteurStatus
                                    }
                                ]}
                            >
                                <Select
                                    id="Emetteur"
                                    style={{ marginLeft: '4%' }}
                                    showSearch
                                    filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    filterSort={(optionA, optionB) => optionA.label.toLowerCase().localeCompare(optionB.label.toLowerCase())}
                                    onChange={(val, obj) => {
                                        setDataRetention((prevState) => ({
                                            ...prevState,
                                            emetteur: val,
                                            emetteurLibelle: obj.label
                                        }))
                                        trackEvent({
                                            funComponent: 'Emetteur',

                                            event: 'Emetteur-' + obj.label + '-choosed'
                                        })
                                    }}
                                    options={intervenants.map((intervenant) => ({
                                        id: intervenant.libelle,
                                        key: intervenant.id,
                                        value: intervenant.id,
                                        label: intervenant.libelle
                                    }))}
                                />
                            </Form.Item>
                        </div>
                        <div className="ant-col ant-col-xs-24 ant-col-xl-8">
                            <Form.Item
                                label={t('Destinataire')}
                                name="destinataire"
                                rules={[
                                    {
                                        required: retentionStatus.destinataireStatus
                                    }
                                ]}
                            >
                                <Select
                                    id="Destinataire"
                                    showSearch
                                    filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    filterSort={(optionA, optionB) => optionA.label.toLowerCase().localeCompare(optionB.label.toLowerCase())}
                                    onChange={(val, obj) => {
                                        setDataRetention((prevState) => ({
                                            ...prevState,
                                            destinataire: val,
                                            destinataireLibelle: obj.label
                                        }))
                                        trackEvent({
                                            funComponent: 'Destinataire',

                                            event: 'Destinataire-' + obj.label + '-choosed'
                                        })
                                    }}
                                    options={intervenants.map((intervenant) => ({
                                        id: intervenant.libelle,
                                        key: intervenant.id,
                                        value: intervenant.id,
                                        label: intervenant.libelle
                                    }))}
                                />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="ant-row">
                        <div className="ant-col ant-col-xs-24 ant-col-xl-8">
                            <Form.Item style={{ marginLeft: '2%' }} label={t('Delai')} name="delai">
                                <Space direction="horizontal">
                                    <Select
                                        id="Delai-Days"
                                        style={{ marginLeft: '52%' }}
                                        defaultValue={0}
                                        onChange={(val) => {
                                            setDays(val)
                                            trackEvent({
                                                funComponent: 'Delai',

                                                event: 'Delai-' + val + '-Days-choosed'
                                            })
                                        }}
                                    >
                                        <Option id="Delai-0-Day" value={0}>
                                            0 jours
                                        </Option>
                                        <Option id="Delai-1-Day" value={1}>
                                            1 jour
                                        </Option>
                                        <Option id="Delai-2-Day" value={2}>
                                            2 jours
                                        </Option>
                                        <Option id="Delai-3-Day" value={3}>
                                            3 jours
                                        </Option>
                                        <Option id="Delai-4-Day" value={4}>
                                            4 jours
                                        </Option>
                                    </Select>
                                    <Select
                                        id="Delai-Hours"
                                        style={{ marginLeft: '55%' }}
                                        defaultValue={0}
                                        onChange={(val) => {
                                            setHours(val)
                                            trackEvent({
                                                funComponent: 'Delai',

                                                event: 'Delai-' + val + '-hours-choosed'
                                            })
                                        }}
                                    >
                                        <Option id="Delai-0-Hour" value={0}>
                                            0h
                                        </Option>
                                        <Option id="Delai-12-Hour" value={12}>
                                            12h
                                        </Option>
                                    </Select>
                                </Space>
                            </Form.Item>
                        </div>
                        <div className="ant-col ant-col-xs-24 ant-col-xl-8">
                            <Form.Item
                                label={t('DateDebut')}
                                name="Date début"
                                rules={[
                                    {
                                        required: retentionStatus.dateDebutStatus
                                    }
                                ]}
                            >
                                <DatePicker
                                    id="Date-Start"
                                    disabledDate={(current) =>
                                        (current && current < new Date(new Date().setDate(new Date().getDate() - 1))) ||
                                        current > new Date(new Date().setDate(new Date().getDate() + 365))
                                    }
                                    onChange={(val) => {
                                        setDataRetention((prevState) => ({
                                            ...prevState,
                                            startDate: val
                                        }))
                                        trackEvent({
                                            funComponent: 'Date-Start',
                                            event: 'Date-Start-' + new Date(val) + '-choosed'
                                        })
                                    }}
                                />
                            </Form.Item>
                        </div>
                        <div className="ant-col ant-col-xs-24 ant-col-xl-8">
                            <Form.Item style={{ marginLeft: '2%' }} label={t('DateFin')} name="Date fin">
                                <DatePicker
                                    id="Date-Fin"
                                    style={{ marginLeft: '9%' }}
                                    disabledDate={(current) =>
                                        (current && current < new Date(new Date().setDate(new Date().getDate() - 1))) ||
                                        current > new Date(new Date().setDate(new Date().getDate() + 365)) ||
                                        current <= dataRetention.startDate
                                    }
                                    disabled={!dataRetention.startDate}
                                    onChange={(val) => {
                                        setDataRetention((prevState) => ({
                                            ...prevState,
                                            endDate: val
                                        }))
                                        trackEvent({
                                            funComponent: 'Date-Fin',
                                            event: 'Date-Fin-' + new Date(val) + '-choosed'
                                        })
                                    }}
                                />
                            </Form.Item>
                        </div>
                    </div>
                    <Form.Item label=" " colon={false}>
                        <Button
                            id="Button-Créé-Retention"
                            type="primary"
                            htmlType="submit"
                            onClick={() => {
                                onFormSubmit()
                                trackEvent({
                                    funComponent: 'Button-Créé-Retention',
                                    event: 'Button-Créé-Retention-clicked'
                                })
                            }}
                        >
                            {t('Creer')}
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </>
    )
}
export default RetentionForm
