import React from 'react'
import { SendOutlined, UserOutlined } from '@ant-design/icons'
import TicketingService from '../../services/TicketingService'
import { message, Button, Modal, Typography, Divider, Card, Input, Skeleton, Avatar } from 'antd'
import track, { useTracking } from 'react-tracking'

const { Text } = Typography
const { Meta } = Card
const secondaryColor = localStorage.getItem('secondary_color')

const DetailTicket = ({ rec, setVisible, visible, setArticle, article, articles }) => {
    const { trackEvent } = useTracking()
    const handleCancel = () => {
        setVisible(false)
    }
    const sendMessage = () => {
        TicketingService.sendArticle(article)
            .then((res) => {
                message.success(res.data)
            })
            .catch(() => {
                message.error("Une erreur est survenue lors de l'envoi du message")
            })
        setVisible(false)
    }
    const afficherModal = (articles) => {
        return (
            <Modal visible={visible} title={'Ticket ' + rec.number + ' détail:'} footer={[]} onCancel={() => handleCancel()}>
                <Text type="secondary">Statut: </Text>
                {rec.status}
                <Text style={{ justifyContent: 'end', marginLeft: '12rem' }} type="secondary">
                    Priorité:
                </Text>
                {rec.priority}
                <Divider>
                    <Text strong>{rec.title}</Text>
                </Divider>
                <Card>
                    {articles.map((article) => (
                        <Card style={{ width: 'auto', marginTop: 16 }}>
                            <Skeleton loading={false} avatar active>
                                <Meta
                                    avatar={
                                        article.sender === 'Agent' ? (
                                            <Avatar
                                                style={{
                                                    backgroundColor: '#F5980A',
                                                    color: 'white'
                                                }}
                                                icon={<UserOutlined />}
                                            />
                                        ) : (
                                            <Avatar
                                                style={{ backgroundColor: secondaryColor ? secondaryColor : '#3CB371', color: 'white' }}
                                                icon={<UserOutlined />}
                                            />
                                        )
                                    }
                                    title={article.from}
                                    description={article.body}
                                />
                            </Skeleton>
                        </Card>
                    ))}
                </Card>
                <Input
                    id="message"
                    style={{ width: 'calc(100% - 32px)' }}
                    onChange={(e) => {
                        setArticle({
                            idTicket: rec.id,
                            from: localStorage.getItem('name'),
                            body: e.target.value
                        })
                        trackEvent({
                            funComponent: 'Ticket-message',

                            event: 'Ticket-message-' + e.target.value + '-puted'
                        })
                    }}
                />
                <Button
                    type="primary"
                    id="btn-send-message"
                    onClick={() => {
                        sendMessage()
                        trackEvent({
                            funComponent: 'Button-send',

                            event: 'Button-send-clicked'
                        })
                    }}
                    icon={<SendOutlined />}
                ></Button>
            </Modal>
        )
    }
    return afficherModal(articles)
}
export default DetailTicket
