import React, { useEffect, useState } from 'react'
import 'antd/dist/antd.css';
import { Card, Row, Typography, Avatar, Tabs, Divider, Tag, message } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import EntiteService from '../../services/EntiteService'
import UserService from '../../services/UserService'

const { TabPane } = Tabs
const { Text, Title } = Typography
const currentUserId = localStorage.getItem('id')
const CardProfile = () => {
    const [entiteUser, setEntiteUser] = useState()
    const [tabs, setTabs] = useState([])
    const [userData, setUserData] = useState({
        identifiant: '',
        email: '',
        authorities: []
    })

    const getidEntiteInfo = (entiteId) => {
        EntiteService.getidEntiteInfo(entiteId).then((res) => {
            setEntiteUser(res.data)
            getUserModuleAction(currentUserId)
        })
    }

    const getUserInfo = async () => {
        await UserService.getUserInfo(currentUserId).then((res) => {
            const data = res.data
            setUserData(data)
            if (data.authorities.length > 0) {
                const profile = data.authorities[0] // Each user has at least one authority, if he doesn't then mybe because his profile is no more active (Should we allow him to login?)
                getidEntiteInfo(profile.idEntite)
            } else {
                message.error(`Aucun profile n\'est associé à cet utilisateur, contacter l\'administrateur`)
            }
        })
    }

    useEffect(() => {
        getUserInfo()
    }, [])

    const getUserModuleAction = (userId) => {
        UserService.getUserModuleAction(userId).then((res) => {
            getTabs(res.data)
        })
    }
    const getTabs = (authorities) => {
        authorities.forEach((res) => {
            let moduleAction = []
            let entite = res.entite
            let modulesActions = res.entiteProfiles
            if (modulesActions !== null) {
                modulesActions.forEach((element) => {
                    moduleAction.push(
                        <p key={'profile'}>
                            <Title level={5}>Profile :</Title>
                            <Divider type="vertical" />
                            <Text type="secondary">{element.profile}</Text>
                        </p>
                    )
                    let modules = element.moduleAction
                    if (modules.length > 0) {
                        moduleAction.push(
                            <p key={'modules'}>
                                <Divider type="vertical" />
                                <Title level={5}>Modules :</Title>
                            </p>
                        )
                    }
                    let colModule = []
                    modules.forEach((module) => {
                        let code = {
                            contenu: []
                        }
                        let actions = module.actions
                        actions.map((action) =>
                            code.contenu.push(
                                <p key={action}>
                                    <Tag color="#009E60">{action}</Tag>
                                </p>
                            )
                        )
                        colModule.push(
                            <TabPane tab={module.module} key={module.module}>
                                {code.contenu}
                            </TabPane>
                        )
                    })
                    moduleAction.push(
                        <Tabs type="card" key={'tab1'}>
                            {colModule}
                        </Tabs>
                    )
                })
                setTabs([
                    ...tabs,
                    <TabPane tab={entite} key={entite}>
                        {moduleAction}
                    </TabPane>
                ])
            }
        })
    }
    return (
        <div className="ant-row">
            <div className="ant-col ant-col-xs-24 ant-col-xl-4">
                <Card>
                    <Card style={{ textAlign: 'center' }}>
                        <Row>
                            <Avatar
                                style={{ justifyContent: 'center', alignItems: 'center', flexDirection: 'column', display: 'flex' }}
                                size={130}
                                icon={<UserOutlined />}
                                title="user image"
                            ></Avatar>
                        </Row>
                    </Card>
                    <Row>
                        <Divider plain>
                            <div class="text-center">
                                <Title level={4}>{userData.identifiant}</Title>
                            </div>
                        </Divider>
                    </Row>
                    <Row>
                        <div>
                            <Title level={5}>Email :</Title>
                            <Text type="secondary">{userData.email}</Text>
                        </div>
                    </Row>
                    <Row>
                        <div>
                            <Title level={5}>Entité :</Title>
                            <Text type="secondary">{entiteUser !== undefined ? entiteUser.libelle : ''}</Text>
                        </div>
                    </Row>
                </Card>
            </div>
            <div className="ant-col ant-col-xs-24 ant-col-xl-20">
                <Card>
                    <h1 style={{ fontWeight: 700 }}>Mes permissions</h1>
                    <br />
                    <div className="card-container">
                        <Tabs type="card">{tabs}</Tabs>
                    </div>
                </Card>
            </div>
        </div>
    )
}

export default CardProfile