import { Divider } from 'antd'
import React, { useEffect, useState } from 'react'
import Cookies from 'js-cookie'
import EchangeWidget from '../../components/widgets/EchangeWidget'
import StatsWidget from '../../components/widgets/StatistiqueWidget'
import DocumentsWidget from '../../components/widgets/DocumentsWidget'
import TicketingWidget from '../../components/widgets/TicketingWidget'
import StyleService from '../../services/StyleService'
import WidgetService from '../../services/WidgetService'
import { hasActionByModule } from 'utilities/modules'

const currentUserId = localStorage.getItem('id')

const Home = () => {
    const [widgets, setWidgets] = useState({
        showEchange: 'true',
        showDocuments: 'true',
        showTickets: 'true',
        showStats: 'true'
    })

    document.title = 'Accueil'

    useEffect(() => {
        if (!window.location.hash) {
            StyleService.getStyleByIdUser(currentUserId).then((res) => {
                let firstDataElement = res.data[0]

                if (firstDataElement !== undefined) {
                    window.localStorage.setItem('primary_color', firstDataElement.primaryColor)

                    window.localStorage.setItem('secondary_color', firstDataElement.secondaryColor)

                    window.localStorage.setItem('logo', firstDataElement.logo)

                    window.location = window.location + '#loaded'

                    window.location.reload()
                }
            })
            WidgetService.getWidgetByIdUser(currentUserId).then((res) => {
                Cookies.set('idWidget', res.data.id)
                if (res.data.widgetList !== undefined && res.data)
                    res.data.widgetList.map((widget) => {
                        Cookies.set(widget.name, widget.active)
                        let active = widget.active
                        switch (widget.name) {
                            case 'showEchange':
                                setWidgets((prevState) => ({
                                    ...prevState,
                                    showEchange: active.toString()
                                }))
                                break
                            case 'showDocuments':
                                setWidgets((prevState) => ({
                                    ...prevState,
                                    showDocuments: active.toString()
                                }))
                                break
                            case 'showTickets':
                                setWidgets((prevState) => ({
                                    ...prevState,
                                    showTickets: active.toString()
                                }))
                                break
                            case 'showStats':
                                setWidgets((prevState) => ({
                                    ...prevState,
                                    showStats: active.toString()
                                }))
                                break
                            default:
                                break
                        }
                    })
            })
        }
    }, [])

    return (
        <>
            <br />

            {widgets.showEchange === 'true' && hasActionByModule('Recherche bordereaux', 'Accéder') && (
                <>
                    <EchangeWidget /> <Divider />
                </>
            )}
            {widgets.showStats === 'true' && hasActionByModule('Volumétrie', 'Accéder') && <StatsWidget />}
            <Divider type="vertical" />
            <div className="ant-row">
                <div className="ant-col ant-col-xs-24 ant-col-xl-11">
                    {widgets.showDocuments === 'true' && hasActionByModule('item', 'Accéder') && <DocumentsWidget />}
                </div>
                <Divider type="vertical" />
                <div className="ant-col ant-col-xs-24 ant-col-xl-12">
                    {widgets.showTickets === 'true' && hasActionByModule('Ticketing', 'Accéder') && <TicketingWidget />}
                </div>
            </div>
        </>
    )
}

export default Home
