import { Button, Card, Divider, Form, Input, message, Descriptions, Image } from 'antd'
import { FileTextOutlined, SwapOutlined, BarChartOutlined } from '@ant-design/icons'
import React, { useEffect, useState } from 'react'
import Cookies from 'js-cookie'
import { CardProfile } from 'components/Cards'
import WidgetService from '../../services/WidgetService'
import { analyze, validerPassword } from '../../utilities/validationPassword'
import http from 'utilities/http-common'
import { hasActionByModule } from 'utilities/modules'
import track, { useTracking } from 'react-tracking'

const currentUserId = localStorage.getItem('id')
const { Meta } = Card

const UserDetails = () => {
    const { trackEvent } = useTracking()
    const [form] = Form.useForm()
    const [backgroundColorNewPassword, setBackgroundColorNewPassword] = useState('')
    const [backgroundColorConfirmPassword, setBackgroundColorConfirmPassword] = useState('')
    const [changePassword, setChangePassword] = useState({ oldPassword: '', newPassword: '', confirmPassword: '' })
    const [widget, setWidget] = useState({
        id: Cookies.get('idWidget'),
        showTickets: Cookies.get('showTickets'),
        showStats: Cookies.get('showStats'),
        showDocuments: Cookies.get('showDocuments'),
        showEchange: Cookies.get('showEchange'),
        idUser: currentUserId
    })

    useEffect(() => {
        document.title = 'Profil'
    }, [])

    const handleClick = () => {
        WidgetService.updateWidget(widget)
    }

    const secondaryColor = localStorage.getItem('secondary_color')

    const handleChangePassword = async (values) => {
        if (values.newPassword === values.confirmPassword) {
            if (validerPassword(values.newPassword)) {
                message.loading('Changement du mot de passe', 1.5).then(
                    await http.patch('/api/v1/users/' + currentUserId + '/update-password', {
                        oldPassword: values.oldPassword,
                        newPassword: values.newPassword
                    }),
                    message.success('Votre mot de passe a été modifié avec succès.')
                )
            } else {
                message.error(
                    'Le mot de passe doit contenir au minimum 8 caractères,au moins une lettre minuscule et une lettre majuscule, un caractère spécial et un chiffre.'
                )
            }
        } else {
            message.error('le nouveaux mot de passe et confirm mot de passe ne sont pas identiques')
        }
    }

    const changeColorNewPassword = async (value) => {
        let color = analyze(value)
        setBackgroundColorNewPassword(color)
    }
    const changeColorConfirmPassword = async (value) => {
        let color = analyze(value)
        setBackgroundColorConfirmPassword(color)
    }

    return (
        <>
            <Form form={form} component={false}>
                <Card>
                    <h1 style={{ fontWeight: 700 }}>Mes informations</h1>
                    <br />
                    <CardProfile />
                </Card>
            </Form>
            <Divider />
            <Card>
                <div className="ant-row">
                    <div id="container" className="ant-col ant-col-xs-24 ant-col-xl-6">
                        <h1 style={{ fontWeight: 700 }}>Changer le mot de passe</h1>
                        <Form method="POST" id="createForm" onFinish={() => handleChangePassword(changePassword)} form={form}>
                            <Descriptions
                                style={{ marginTop: '1.5rem' }}
                                labelStyle={{ color: 'white', backgroundColor: secondaryColor ? secondaryColor : '#3CB371' }}
                                size="small"
                                bordered
                                column={1}
                            >
                                <Form.Item label="Ancien Mot de passe" name="oldPassword" required>
                                    <Input.Password
                                        id="old-password"
                                        style={{ width: '100%' }}
                                        placeholder="Votre ancien mot de passe"
                                        type="password"
                                        required
                                        bordered={false}
                                        onChange={(val) => setChangePassword((prevState) => ({ ...prevState, oldPassword: val.target.value }))}
                                    />
                                </Form.Item>
                                <Form.Item label="Nouveau Mot de passe" name="newPassword" required>
                                    <Input.Password
                                        id="new-password"
                                        style={{ width: '100%', backgroundColor: backgroundColorNewPassword }}
                                        placeholder="Votre nouveau mot de passe"
                                        type="password"
                                        required
                                        bordered={false}
                                        onChange={(val) => {
                                            setChangePassword((prevState) => ({ ...prevState, newPassword: val.target.value }))
                                            changeColorNewPassword(val)
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item label="Confirmer le nouveau mot de passe" name="confirmPassword" required>
                                    <Input
                                        id="confirm-new-password"
                                        style={{ width: '100%', backgroundColor: backgroundColorConfirmPassword }}
                                        placeholder="Confirmez votre mot de passe"
                                        type="password"
                                        required
                                        bordered={false}
                                        onChange={(val) => {
                                            setChangePassword((prevState) => ({ ...prevState, confirmPassword: val.target.value }))
                                            changeColorConfirmPassword(val)
                                        }}
                                    />
                                </Form.Item>
                            </Descriptions>

                            <Form.Item style={{ marginTop: '1rem' }}>
                                <Button
                                    id="btn-modif-password"
                                    style={{ backgroundColor: secondaryColor ? secondaryColor : '#3CB371', color: 'white', borderRadius: '10%' }}
                                    htmlType="submit"
                                    size="large"
                                >
                                    Modifier
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                    <div className="ant-col ant-col-xs-24 ant-col-xl-16" style={{ marginLeft: '1.5rem' }}>
                        <h1 style={{ fontWeight: 700 }}>Mes Widgets</h1>
                        <div>
                            <div className="ant-row">
                                {hasActionByModule('Recherche bordereaux', 'Accéder') && (
                                    <div className="ant-col ant-col-xs-24 ant-col-xl-6">
                                        <br />
                                        <Card
                                            style={{ width: 250 }}
                                            cover={<Image alt="example" src="../img/widgetEchange1.png" />}
                                            actions={[
                                                <Button
                                                    style={{ width: '100%' }}
                                                    type="text"
                                                    onClick={() => {
                                                        setWidget((prevState) => ({ ...prevState, showEchange: 'true' }))
                                                        Cookies.set('showEchange', true)
                                                        message.success('Le widget Echange a été ajouté')
                                                        trackEvent({
                                                            funComponent: 'Button-Ajouter-Widget-Echange',
                                                            event: 'Button-Ajouter-Widget-Echanges-clicked'
                                                        })
                                                    }}
                                                    onFinish={handleClick()}
                                                    disabled={Cookies.get('showEchange') === 'true'}
                                                >
                                                    Ajouter
                                                </Button>,
                                                <Button
                                                    style={{ width: '100%' }}
                                                    type="text"
                                                    onClick={() => {
                                                        setWidget((prevState) => ({ ...prevState, showEchange: 'false' }))
                                                        Cookies.remove('showEchange')
                                                        message.success('Le widget Echange a été retiré')
                                                        trackEvent({
                                                            funComponent: 'Button-Retirer-Widget-Echange',
                                                            event: 'Button-Retirer-Widget-Echanges-clicked'
                                                        })
                                                    }}
                                                    disabled={Cookies.get('showEchange') !== 'true'}
                                                >
                                                    Retirer
                                                </Button>
                                            ]}
                                        >
                                            <Meta
                                                avatar={<SwapOutlined />}
                                                title="Echanges"
                                                description="Afficher les derniers echanges bordereaux emis-reçus"
                                            />
                                        </Card>
                                    </div>
                                )}
                                {hasActionByModule('item', 'Accéder') && (
                                    <div className="ant-col ant-col-xs-24 ant-col-xl-6">
                                        <br />
                                        <Card
                                            style={{ width: 250 }}
                                            cover={<Image alt="example" src="../img/widgetDocuments.png" />}
                                            actions={[
                                                <Button
                                                    style={{ width: '100%' }}
                                                    type="text"
                                                    onClick={() => {
                                                        setWidget((prevState) => ({ ...prevState, showDocuments: 'true' }))
                                                        Cookies.set('showDocuments', true)
                                                        message.success('Le widget Document(s) a été ajouté')
                                                        trackEvent({
                                                            funComponent: 'Button-Ajouter-Widget-Document(s)',
                                                            event: 'Button-Ajouter-Widget-Document(s)-clicked'
                                                        })
                                                    }}
                                                    disabled={Cookies.get('showDocuments') === 'true'}
                                                >
                                                    Ajouter
                                                </Button>,
                                                <Button
                                                    style={{ width: '100%' }}
                                                    type="text"
                                                    onClick={() => {
                                                        setWidget((prevState) => ({ ...prevState, showDocuments: 'false' }))
                                                        Cookies.remove('showDocuments')
                                                        message.success('Le widget Document(s) a été retiré')
                                                        trackEvent({
                                                            funComponent: 'Button-Retirer-Widget-Document(s)',
                                                            event: 'Button-Retirer-Widget-Document(s)-clicked'
                                                        })
                                                    }}
                                                    disabled={Cookies.get('showDocuments') !== 'true'}
                                                >
                                                    Retirer
                                                </Button>
                                            ]}
                                        >
                                            <Meta
                                                avatar={<FileTextOutlined />}
                                                title="Document(s)"
                                                description="Afficher les derniers documents publiés"
                                            />
                                        </Card>
                                    </div>
                                )}
                                {hasActionByModule('Ticketing', 'Accéder') && (
                                    <div className="ant-col ant-col-xs-24 ant-col-xl-6">
                                        <br />
                                        <Card
                                            style={{ width: 250 }}
                                            cover={<Image alt="example" src="../img/widgetDocuments.png" />}
                                            actions={[
                                                <Button
                                                    style={{ width: '100%' }}
                                                    type="text"
                                                    onClick={() => {
                                                        setWidget((prevState) => ({ ...prevState, showTickets: 'true' }))
                                                        Cookies.set('showTickets', true)
                                                        message.success('Le widget Ticket(s) a été ajouté')
                                                        trackEvent({
                                                            funComponent: 'Button-Ajouter-Widget-Ticket(s)',
                                                            event: 'Button-Ajouter-Widget-Ticket(s)-clicked'
                                                        })
                                                    }}
                                                    disabled={Cookies.get('showTickets') === 'true'}
                                                >
                                                    Ajouter
                                                </Button>,
                                                <Button
                                                    style={{ width: '100%' }}
                                                    type="text"
                                                    onClick={() => {
                                                        setWidget((prevState) => ({ ...prevState, showTickets: 'false' }))
                                                        Cookies.remove('showTickets')
                                                        message.success('Le widget Ticket(s) a été retiré')
                                                        trackEvent({
                                                            funComponent: 'Button-Retirer-Widget-Ticket(s)',
                                                            event: 'Button-Retirer-Widget-Ticket(s)-clicked'
                                                        })
                                                    }}
                                                    disabled={Cookies.get('showTickets') !== 'true'}
                                                >
                                                    Retirer
                                                </Button>
                                            ]}
                                        >
                                            <Meta
                                                avatar={<FileTextOutlined />}
                                                title="Ticket(s)"
                                                description="Afficher les derniers tickets publiés"
                                            />
                                        </Card>
                                    </div>
                                )}
                                {hasActionByModule('Volumétrie', 'Accéder') && (
                                    <div className="ant-col ant-col-xs-24 ant-col-xl-6">
                                        <br />
                                        <Card
                                            style={{ width: 250 }}
                                            cover={<Image alt="example" src="../img/widgetStats.png" />}
                                            actions={[
                                                <Button
                                                    style={{ width: '100%' }}
                                                    type="text"
                                                    onClick={() => {
                                                        setWidget((prevState) => ({ ...prevState, showStats: 'true' }))
                                                        Cookies.set('showStats', true)
                                                        message.success('Le widget Statistiques a été ajouté')
                                                        trackEvent({
                                                            funComponent: 'Button-Ajouter-Widget-Statistiques',
                                                            event: 'Button-Ajouter-Widget-Statistiques-clicked'
                                                        })
                                                    }}
                                                    disabled={Cookies.get('showStats') === 'true'}
                                                >
                                                    Ajouter
                                                </Button>,
                                                <Button
                                                    style={{ width: '100%' }}
                                                    type="text"
                                                    onClick={() => {
                                                        setWidget((prevState) => ({ ...prevState, showStats: 'false' }))
                                                        Cookies.remove('showStats')
                                                        message.success('Le widget Statistiques a été retiré')
                                                        trackEvent({
                                                            funComponent: 'Button-Retirer-Widget-Statistiques',
                                                            event: 'Button-Retirer-Widget-Statistiques-clicked'
                                                        })
                                                    }}
                                                    disabled={Cookies.get('showStats') !== 'true'}
                                                >
                                                    Retirer
                                                </Button>
                                            ]}
                                        >
                                            <Meta
                                                avatar={<BarChartOutlined />}
                                                title="Statistiques"
                                                description="Afficher les stats du volume d'activités par semaine"
                                            />
                                        </Card>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Card>
        </>
    )
}

export default UserDetails
