import { addAlert } from 'store/actions'
import axios from 'axios'
import { getTimestamp } from './core'
import { showNotificationError } from 'utilities/antd-util'
import store from 'store'

const isHandlerEnabled = (config = {}) => {
    return config.hasOwnProperty('handlerEnabled') && !config.handlerEnabled ? false : true
}

const requestHandler = (request) => {
    const access_token = localStorage.getItem('access_token')
    if (isHandlerEnabled(request)) {
        if (access_token) {
            request.headers['Authorization'] = `Bearer ${access_token}`
        }
    }
    return request
}

const errorHandler = (error) => {
    if (isHandlerEnabled(error.config)) {
        let url = `${error.config.baseURL}/${error.config.url}`
        store.dispatch(
            addAlert({
                title: 'Erreur(s) survenue(s)',
                message: showNotificationError(error?.response?.data || {}),
                url,
                isApiCall: true,
                type: 'error',
                ts: getTimestamp()
            })
        )
    }
    return Promise.reject({ ...error })
}

const successHandler = (response) => {
    if (isHandlerEnabled(response.config)) {
    }
    return response
}

// Init Axios
export const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL
})

// Add interceptors
axiosInstance.interceptors.request.use((request) => requestHandler(request))

axiosInstance.interceptors.response.use(
    (response) => successHandler(response),
    (error) => errorHandler(error)
)
