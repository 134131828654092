import { createReducer } from '@reduxjs/toolkit'

const initialState = {
    loading: false,
    data: [],
    error: ''
}

export const auditLogsReducer = createReducer(initialState, {
    AUDIT_LOGS_REQUEST: (state) => {
        state.loading = true
    },
    AUDIT_LOGS_SUCCESS: (state, action) => {
        state.loading = false
        state.data = action.payload
    },
    AUDIT_LOGS_FAILURE: (state, action) => {
        state.loading = false
        state.data = []
        state.error = action.payload
    }
})
