import { CheckCircleTwoTone, SearchOutlined } from '@ant-design/icons'
import { Button, Table, message } from 'antd'
import React, { useState } from 'react'
import Cookies from 'js-cookie'
import '../../static/less/app.less'
import moment from 'moment'
import { getColumnSearchProps } from '../../utilities/table-utils'
import OrdersStepService from '../../services/OrdersStepService'
import track, { useTracking } from 'react-tracking'
const BordTable = (props) => {
    const { trackEvent } = useTracking()
    const data = props.data.map((data) => ({
        key: data.id,
        bordereau: data.bordereau,
        timestamp: moment(data.timestamp).utc().format('YYYY-MM-DD HH:mm'),
        emetteur: data.emetteur,
        receveur: data.receveur,
        ordersType: data.ordersType,
        nbPositions: data.numOrders,
        statut: <CheckCircleTwoTone twoToneColor="#52c41a" />
    }))

    const [searchText, setSearchText] = useState('')
    const [searchedColumn, setSearchedColumn] = useState('')

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
    }

    const handleReset = (clearFilters) => {
        clearFilters()
        setSearchText('')
    }

    return (
        <Table
            id="table-bordereau"
            columns={[
                {
                    id: 'id',
                    title: 'id',
                    dataIndex: 'key',
                    key: 'id',
                    hidden: true
                },
                {
                    id: 'bordereau',
                    title: 'Bordereau',
                    dataIndex: 'bordereau',
                    ...getColumnSearchProps('bordereau', searchText, searchedColumn, handleSearch, handleReset),
                    sorter: {
                        compare: (cell1Data, cell2Data) => cell1Data.bordereau - cell2Data.bordereau,
                        multiple: 1
                    }
                },
                {
                    id: 'date',
                    title: 'Date',
                    dataIndex: 'timestamp',
                    ...getColumnSearchProps('timestamp', searchText, searchedColumn, handleSearch, handleReset),
                    sorter: {
                        compare: (cell1Data, cell2Data) => {
                            const date1 = new Date(cell1Data.timestamp.replace(/-/g, '/'))
                            const date2 = new Date(cell2Data.timestamp.replace(/-/g, '/'))
                            return date1 - date2
                        },
                        multiple: 2
                    }
                },
                {
                    id: 'emetteur',
                    title: 'Emetteur',
                    dataIndex: 'emetteur',
                    ...getColumnSearchProps('emetteur', searchText, searchedColumn, handleSearch, handleReset),
                    sorter: {
                        compare: (cell1Data, cell2Data) => cell1Data.emetteur.localeCompare(cell2Data.emetteur),
                        multiple: 3
                    }
                },
                {
                    id: 'receveur',
                    title: 'Destinataire',
                    dataIndex: 'receveur',
                    ...getColumnSearchProps('receveur', searchText, searchedColumn, handleSearch, handleReset),
                    sorter: {
                        compare: (cell1Data, cell2Data) => cell1Data.receveur.localeCompare(cell2Data.receveur),
                        multiple: 4
                    }
                },
                {
                    id: 'ordersType',
                    title: 'Orders Type',
                    dataIndex: 'ordersType',
                    ...getColumnSearchProps('ordersType', searchText, searchedColumn, handleSearch, handleReset),
                    sorter: {
                        compare: (cell1Data, cell2Data) => cell1Data.ordersType.localeCompare(cell2Data.ordersType),
                        multiple: 5
                    }
                },
                {
                    id: 'nbPositions',
                    title: 'Nb Positions',
                    dataIndex: 'nbPositions',
                    ...getColumnSearchProps('nbPositions', searchText, searchedColumn, handleSearch, handleReset),
                    sorter: {
                        compare: (cell1Data, cell2Data) => cell1Data.nbPositions - cell2Data.nbPositions,
                        multiple: 6
                    }
                },
                {
                    id: 'statut',
                    title: 'Statut',
                    dataIndex: 'statut',
                    sorter: {
                        compare: (cell1Data, cell2Data) => (cell2Data.statut && !cell1Data.statut ? 1 : -1),
                        multiple: 7
                    }
                },
                {
                    id: 'detail',
                    title: 'Détail',
                    dataIndex: 'detail',
                    render: (text, record) => (
                        <div className="btn-wrap">
                            <Button
                                id="btn-detail"
                                onClick={async () => {
                                    OrdersStepService.getStepsByType(record.ordersType).then((res) => {
                                        const steps = res.data.map((data) => data.libelle)
                                        props.setSteps(steps)
                                    })
                                    message
                                        .loading('Chargement des details du bordereau ...')
                                        .then(props.getBordereauDetailsById(record.key, record.bordereau))

                                    trackEvent({
                                        funComponent: 'Button-Detail-Bordereau-' + record.key,
                                        event: 'Button-Detail-Clicked'
                                    })
                                }}
                            >
                                <a href="#details">
                                    <SearchOutlined />
                                </a>
                            </Button>
                        </div>
                    )
                }
            ].filter((item) => !item.hidden)}
            size="small"
            scroll={{ x: 120 }}
            dataSource={data}
            pagination={{ pageSize: Cookies.get('nbRow'), position: ['downRight'] }}
        />
    )
}

export default BordTable
