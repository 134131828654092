import { Card, Divider } from 'antd'
import React, { useState } from 'react'
import { TicketingForm } from 'components/Formulaires'
import { hasActionByModule } from 'utilities/modules'
import { TicketsTable } from 'components/Tables'
import TicketingService from '../../services/TicketingService'
import { message } from 'antd'
import { getPrioritieTag, getStatusTag } from '../../constants/enums/ticketStatusEnum'
import moment from 'moment'
import track, { useTracking } from 'react-tracking'

const Ticketing = () => {
    const { trackEvent } = useTracking()
    const [tickets, setTickets] = useState([])

    const getTickets = async () => {
        let numeros = []
        let data = {
            email: localStorage.getItem('email'),
            idEntite: Number(localStorage.getItem('entite')),
            idHierarchie: Number(localStorage.getItem('hierarchie')),
            idClient: Number(localStorage.getItem('client'))
        }
        setTickets([])
        TicketingService.getTicket(data)
            .then((response) => {
                if (response.data.length > 0) {
                    for (let ticket of response.data) {
                        const data = {}
                        TicketingService.getPriority(ticket.priority_id).then((responsePriority) => {
                            data.priority = getPrioritieTag(responsePriority.data.name)
                            TicketingService.getUser(ticket.customer_id).then((responseUser) => {
                                data.client = responseUser.data.firstname
                                TicketingService.getStatus(ticket.state_id).then((responseStatus) => {
                                    data.status = getStatusTag(responseStatus.data.name)
                                    data.number = ticket.number
                                    data.id = ticket.id
                                    data.title = ticket.title
                                    const DateCreationUtc = moment.utc(ticket.created_at?.toString()).toDate()
                                    data.created_at = moment(DateCreationUtc).local().format('YYYY-MM-DD HH:mm:ss')
                                    const DateModifUtc = moment.utc(ticket.updated_at?.toString()).toDate()
                                    data.updated_at = moment(DateModifUtc).local().format('YYYY-MM-DD HH:mm:ss')
                                    setTickets((prevState) => [...prevState, data])
                                })
                            })
                        })
                    }
                }
                trackEvent({
                    funComponent: 'List-Tickets',
                    event: 'List-Tickets-getData'
                })
            })
            .catch((err) => {
                trackEvent({
                    funComponent: 'List-Tickets',
                    event: 'List-Tickets-getData',
                    status: JSON.stringify(err.response?.data.status)
                })
                message.error("Impossible d'afficher les tickets")
            })
    }
    return (
        <>
            {hasActionByModule('Ticketing', 'Accéder') && (
                <>
                    {hasActionByModule('Ticketing', 'Créer') && (
                        <Card>
                            <h1 className="blod centre">Nouveau ticket</h1>
                            <TicketingForm getTickets={getTickets} setTickets={setTickets} />
                        </Card>
                    )}
                    <Divider />
                    {hasActionByModule('Ticketing', 'Lister') && (
                        <Card>
                            <h1 className="blod">List des tickets</h1>
                            <TicketsTable getTickets={getTickets} tickets={tickets} />
                        </Card>
                    )}
                </>
            )}
        </>
    )
}

export { Ticketing }
