import { Modal, Tabs, Descriptions,BackTop, Card } from 'antd'
import jsonata from 'jsonata'
import React, { useState, useEffect, Row, Col } from 'react'
const { TabPane } = Tabs

const secondaryColor = localStorage.getItem('secondary_color')

const RecepisseAdditionalInfo = ({ showModal, setShowModal, info }) => {
    const [ctas, setCtas] = useState([])
    const [dtms, setDtms] = useState([])
    const [nad, setNad] = useState({})
    const [sup, setSup] = useState({})
    const [tsr, setTsr] = useState({})

    String.prototype.splice = function(idx, rem, str) {
        return this.slice(0, idx) + str + this.slice(idx + Math.abs(rem));
    };

    const getCtaValues = (data) => {
        if (data === undefined || data.length === 0) {
            return []
        }
        if (data.cta === undefined || data.cta === null || data.cta.length === 0) {
            return []
        }

        const results = []

        const ctasData = JSON.parse(data.cta)
        const service = jsonata('$.CTA_C056.C056_3413')
        const name = jsonata('$.CTA_C056.C056_3412')
        const mobile_phone = jsonata("$.CTA_C076[C076_3155='AL'].C076_3148")
        const fix_phone = jsonata("$.CTA_C076[C076_3155='TE'].C076_3148")
        const email = jsonata("$.CTA_C076[C076_3155='EM'].C076_3148")
        const digicode = jsonata("$.CTA_C076[C076_3155='AE'].C076_3148")

        for (let ctaData of ctasData) {
            const result = {
                service: service.evaluate(ctaData),
                name: name.evaluate(ctaData),
                mobile_phone: mobile_phone.evaluate(ctaData),
                fix_phone: fix_phone.evaluate(ctaData),
                email: email.evaluate(ctaData),
                digicode: digicode.evaluate(ctaData)
            }

            results.push(result)
        }
        return results
    }

    const getDate = (date) => {
        if( date === undefined)
            return undefined
        date= date.splice(2, 0, "/");
        return date.splice(5, 0, "/");
    }
    const getDtmValues = (data) => {
        if (data === undefined || data.length === 0) {
            return []
        }
        if (data.dtm === undefined || data.dtm === null || data.dtm.length === 0) {
            return []
        }

        const dtmsData = JSON.parse(data.dtm)

        const results = []
        const dateRDVLivraison = jsonata('$[C507_2005 = "DAD"].C507_2380')
        const debutPeriodeLivraison = jsonata('$[C507_2005 = "DFD"].C507_2380')
        const finPeriodeLivraison = jsonata('$[C507_2005 = "DLD"].C507_2380')

        const dateRDVEnelevement = jsonata('$[C507_2005 = "EDD"].C507_2380')
        const debutPeriodeEnelevement = jsonata('$[C507_2005 = "EFD"].C507_2380')
        const finPeriodeEnelevement = jsonata('$[C507_2005 = "ELD"].C507_2380')

        for (let dtmData of dtmsData) {
            const result = {
                dateRDVLivraison: getDate(dateRDVLivraison.evaluate(dtmData)),
                debutPeriodeLivraison: getDate(debutPeriodeLivraison.evaluate(dtmData)),
                finPeriodeLivraison: getDate(finPeriodeLivraison.evaluate(dtmData)),
                dateRDVEnelevement: getDate(dateRDVEnelevement.evaluate(dtmData)),
                debutPeriodeEnelevement: getDate(debutPeriodeEnelevement.evaluate(dtmData)),
                finPeriodeEnelevement: getDate(finPeriodeEnelevement.evaluate(dtmData))
            }
            
            results.push(result)
        }
        return results
    }

    useEffect(() => {
        info = info[0]
        setCtas(getCtaValues(info))
        setDtms(getDtmValues(info))
    }, [info])

    
    return (
        <Modal centered visible={showModal} onCancel={() => setShowModal(false)} footer={[]} width={1200}   bodyStyle={{height: 550}}>
            <div>
                <h1>Informations additionnelles</h1>
                <Tabs type="card">
                    <TabPane tab="Contact (CTA)" key="1">
                        {ctas.map((cta, index) => (
                            <Card
                                title={`Contact ${index + 1}`}
                                headStyle={{ 'background-color': '#f0f2f5', 'text-align': 'center' }}
                                
                            >
                                <Descriptions
                                    labelStyle={{ color: 'white', backgroundColor: secondaryColor ? secondaryColor : '#3CB371' }}
                                    size="small"
                                    column={3}
                                    bordered
                                >
                                    <Descriptions.Item label="Service" contentStyle={{'width':250}}>{cta.service ? cta.service : '-'}</Descriptions.Item>
                                    <Descriptions.Item label="Nom" contentStyle={{'width':250}}>{cta.name ? cta.name : '-'}</Descriptions.Item>
                                    <Descriptions.Item label="Tel Mobile" contentStyle={{'width':250}}>{cta.mobile_phone ? cta.mobile_phone : '-'}</Descriptions.Item>
                                    <Descriptions.Item label="Tel Fixe" contentStyle={{'width':250}}>{cta.fix_phone ? cta.fix_phone : '-'}</Descriptions.Item>
                                    <Descriptions.Item label="Mail" contentStyle={{'width':250}}>{cta.email ? cta.email : '-'}</Descriptions.Item>
                                    <Descriptions.Item label="Digicode" contentStyle={{'width':250}}>{cta.digicode ? cta.digicode : '-'}</Descriptions.Item>
                                </Descriptions> 
                            </Card>
                        ))}
                    </TabPane>
                    <TabPane tab="Date (DTM)" key="2">
                        {dtms.map((dtm, index) => (
                            <Card
                                title={`Date ${index + 1}`}
                                style={{ marginTop: 1 }}
                                headStyle={{ 'background-color': '#f0f2f5', 'text-align': 'center' }}
                            >
                                <Card
                                    title={`Livraison`}
                                    headStyle={{ 'background-color': '#dee3e0', 'text-align': 'center' }}
                                    style={{ marginTop: 1 }}
                                >
                                    <Descriptions
                                        labelStyle={{ color: 'white', backgroundColor: secondaryColor ? secondaryColor : '#3CB371' }}
                                        size="small"
                                        column={3}
                                        bordered
                                    >
                                        <Descriptions.Item  label="Date de RDV" contentStyle={{'width':150}}>{dtm.dateRDVLivraison ? dtm.dateRDVLivraison : '-'}</Descriptions.Item>
                                        <Descriptions.Item  label="Début période de livraison" contentStyle={{'width':150}}>
                                            {dtm.debutPeriodeLivraison ? dtm.debutPeriodeLivraison : '-'}
                                        </Descriptions.Item>
                                        <Descriptions.Item  label="Fin de période de livraison" contentStyle={{'width':150}}>
                                            {dtm.finPeriodeLivraison ? dtm.finPeriodeLivraison : '-'}
                                        </Descriptions.Item>
                                    </Descriptions>
                                </Card>
                                <Card
                                    title={`Enlèvement`}
                                    headStyle={{ 'background-color': '#dee3e0', 'text-align': 'center' }}
                                    style={{ marginTop: 1 }}
                                >
                                    <Descriptions
                                        labelStyle={{ color: 'white', backgroundColor: secondaryColor ? secondaryColor : '#3CB371' }}
                                        size="small"
                                        column={3}
                                        bordered
                                    >
                                        <Descriptions.Item label="Date de RDV" contentStyle={{'width':150}} >
                                            {dtm.dateRDVEnelevement ? dtm.dateRDVEnelevement : '-     '}
                                        </Descriptions.Item>
                                        <Descriptions.Item   label="Début période de livraison" contentStyle={{'width':150}}>
                                            {dtm.debutPeriodeEnelevement ? dtm.debutPeriodeEnelevement : '-     '}
                                        </Descriptions.Item>
                                        <Descriptions.Item  label="Fin de période de livraison" contentStyle={{'width':150}}>
                                            {dtm.finPeriodeEnelevement ? dtm.finPeriodeEnelevement : '-     '}
                                        </Descriptions.Item>
                                    </Descriptions>
                                </Card>
                            </Card>
                        ))}
                    </TabPane>
                    <TabPane tab="Nom et adresse (NAD)" key="3">
                        <div 
                            style={{
                                    'font-size':22,
                                    'marginTop':155, 
                                    'marginLeft':450
                                    }}
                        >
                            <span 
                                className="nad" 
                                style={{
                                        'font-size':22
                                        }}
                            >
                                Aucune information trouvé 
                            </span>
                        </div>
                    </TabPane>
                </Tabs>
            </div>
        </Modal>
    )
}

export default RecepisseAdditionalInfo
