import { createReducer } from '@reduxjs/toolkit'

const initialState = {
    userInfo: {
        loading: false,
        data: {},
        error: ''
    }
}

export const metaReducer = createReducer(initialState, {
    FETCH_USER_INFO_REQUEST: (state) => {
        state.userInfo.loading = true
    },
    FETCH_USER_INFO_SUCCESS: (state, action) => {
        state.userInfo = {
            loading: false,
            data: action.payload,
            error: ''
        }
    },
    FETCH_USER_INFO_FAILURE: (state, action) => {
        state.userInfo = {
            loading: false,
            data: {},
            error: action.payload
        }
    }
})
