import { createReducer } from '@reduxjs/toolkit'

const initialState = {
    title: '',
    description: ''
}

export const siteInfoReducer = createReducer(initialState, {
    SET_SITE_INFO_TITLE: (state, action) => {
        state.title = action.payload
    },
    SET_SITE_INFO_DESCRIPTION: (state, action) => {
        state.description = action.payload
    }
})
