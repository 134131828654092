import { Layout } from 'antd'
import React from 'react'

const { Footer } = Layout

const Blank = ({ children }) => {
    return (
        <>
            {children}
            {/* style={{ textAlign: 'center', height: '50%', left: 0, bottom: 0, width: '100%', zIndex: 1 }} */}
            <Footer style={{ textAlign: 'center', left: 0, bottom: 0, width: '100%', zIndex: 1 }}>
                &copy; Augmenticom - Tous droits réservés {process.env.REACT_APP_COPYRIGHT_NOTICE}
            </Footer>
        </>
    )
}

export default Blank
