import { createReducer } from '@reduxjs/toolkit'

const initialState = {
    reports: {
        loading: false,
        data: [],
        error: ''
    },
    reportsWorkplaceTypes: {
        loading: false,
        data: [],
        error: ''
    }
}

export const reportsReducer = createReducer(initialState, {
    FETCH_REPORTS_REQUEST: (state) => {
        state.reports.loading = true
    },
    FETCH_REPORTS_SUCCESS: (state, action) => {
        state.reports = {
            loading: false,
            data: action.payload
        }
    },
    FETCH_REPORTS_FAILURE: (state, action) => {
        state.reports = {
            loading: false,
            data: {},
            error: action.payload
        }
    },
    FETCH_REPORTS_WORKPLACE_TYPES_REQUEST: (state) => {
        state.reportsWorkplaceTypes.loading = true
    },
    FETCH_REPORTS_WORKPLACE_TYPES_SUCCESS: (state, action) => {
        state.reportsWorkplaceTypes = {
            loading: false,
            data: action.payload
        }
    },
    FETCH_REPORTS_WORKPLACE_TYPES_FAILURE: (state, action) => {
        state.reportsWorkplaceTypes = {
            loading: false,
            data: {},
            error: action.payload
        }
    }
})
