import { Table } from 'antd'
import React, { useState } from 'react'
import jsonata from 'jsonata'
import '../../static/less/app.less'
import { getColumnSearchProps } from '../../utilities/table-utils'

const ReportTable = (props) => {
    const eventRecep = props.data.map((data) => {
        let content = JSON.parse(data.content)
        const timestamp = jsonata(JSON.parse(data.date_Content))?.evaluate(content)
        let expressionRemarque_content = null
        if (data.remarque_content !== null) expressionRemarque_content = jsonata(JSON.parse(data.remarque_content))

        let eventResult = {
            key: data.emmeteur + data.recepteur + timestamp,
            situation: data.code_Situation,
            justif: data.code_Justification,
            timestamp: timestamp,
            emett: data.emetteur,
            remarque: data.remarque_content === null ? '-' : expressionRemarque_content.evaluate(content)
        }
        return eventResult
    })

    const [searchText, setSearchText] = useState('')
    const [searchedColumn, setSearchedColumn] = useState('')

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
    }

    const handleReset = (clearFilters) => {
        clearFilters()
        setSearchText('')
    }

    return (
        <Table
            id="table-report"
            columns={[
                {
                    id: 'situation',
                    title: 'Situation',
                    dataIndex: 'situation',
                    ...getColumnSearchProps('situation', searchText, searchedColumn, handleSearch, handleReset),
                    sorter: {
                        compare: (cell1Data, cell2Data) => cell1Data.situation.localeCompare(cell2Data.situation),
                        multiple: 1
                    }
                },
                {
                    id: 'justification',
                    title: 'Justification',
                    dataIndex: 'justif',
                    ...getColumnSearchProps('justif', searchText, searchedColumn, handleSearch, handleReset),
                    sorter: {
                        compare: (cell1Data, cell2Data) => cell1Data.justif.localeCompare(cell2Data.justif),
                        multiple: 2
                    }
                },
                {
                    id: 'timestamp',
                    title: "Date et heure d'evt",
                    dataIndex: 'timestamp',
                    ...getColumnSearchProps('timestamp', searchText, searchedColumn, handleSearch, handleReset),
                    sorter: {
                        compare: (cell1Data, cell2Data) => cell1Data.timestamp - cell2Data.timestamp,
                        multiple: 3
                    }
                },
                {
                    id: 'emetteur',
                    title: 'siret emétteur',
                    dataIndex: 'emett',
                    ...getColumnSearchProps('emett', searchText, searchedColumn, handleSearch, handleReset),
                    sorter: {
                        compare: (cell1Data, cell2Data) => cell1Data.emett.localeCompare(cell2Data.emett),
                        multiple: 4
                    }
                },
                {
                    id: 'remarque',
                    title: 'Remarque(s)',
                    dataIndex: 'remarque',
                    ...getColumnSearchProps('remarque', searchText, searchedColumn, handleSearch, handleReset),
                    sorter: {
                        compare: (cell1Data, cell2Data) => cell1Data.remarque.localeCompare(cell2Data.remarque),
                        multiple: 5
                    }
                }
            ]}
            size="small"
            scroll={{ x: 120 }}
            dataSource={eventRecep}
        />
    )
}

export default ReportTable
