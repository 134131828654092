import { createReducer } from '@reduxjs/toolkit'

const initialState = {
    loading: true,
    valid: false,
    error: {}
}

export const authReducer = createReducer(initialState, {
    INTROSPECT_TOKEN_REQUEST: (state) => {
        state.loading = true
    },
    INTROSPECT_TOKEN_SUCCESS: (state, action) => {
        state.loading = false
        state.valid = action.payload
    },
    INTROSPECT_TOKEN_FAILURE: (state, action) => {
        state.loading = false
        state.error = action.payload
    }
})
