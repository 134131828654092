import { compose, applyMiddleware } from 'redux'
import rootReducer from './reducers'
import thunk from 'redux-thunk'
import logger from 'redux-logger'
import { configureStore } from '@reduxjs/toolkit'

const middlewares = [thunk]

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

if (process.env.NODE_ENV === 'development') {
    middlewares.push(logger)
}

const store = configureStore(
    {
        reducer: rootReducer
    },
    composeEnhancer(applyMiddleware(thunk))
)

export default store
