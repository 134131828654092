import http from 'utilities/http-common'

const getUserSubordinateEntities = (userId) => {
    return http.get('/api/v1/users/' + userId + '/entities')
}

const getHierarchies = (userId) => {
    return http.get('/api/v1/users/' + userId + '/hierarchies')
}

const getSubordinateUsers = (userId) => {
    return http.get('/api/v1/users/' + userId + '/subordinates')
}

const getSubordinateProfiles = (userId) => {
    return http.get('/api/v1/profiles/subordinate-profiles/' + userId)
}

const getHierarchieEntities = (userId, hierarchieId) => {
    return http.get('/api/v1/users/' + userId + '/hierarchies/' + hierarchieId + '/entities')
}

const getEntiteRoles = (entiteId) => {
    return http.get('/api/v1/hierarchies/' + entiteId + '/active-profiles')
}

const createUser = (userData) => {
    return http.post('/api/v1/users/register', userData)
}

const deleteUser = (userId) => {
    return http.delete('/api/v1/users/' + userId + '/delete')
}

const deleteRole = (roleId) => {
    return http.delete('/api/v1/profiles/' + roleId + '/delete')
}

export default {
    getUserSubordinateEntities,
    getHierarchies,
    getSubordinateUsers,
    getSubordinateProfiles,
    getHierarchieEntities,
    getEntiteRoles,
    createUser,
    deleteUser,
    deleteRole
}
