import { MoreOutlined } from '@ant-design/icons'
import { Card, Dropdown, Menu } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { ChartBar } from 'components/Charts/ChartType'
import statistiqueService from 'services/StatistiqueService'

const currentUserId = localStorage.getItem('id')
const period = 'week'

const StatistiqueWidget = () => {
    const [periodeData, setPeriodeData] = useState([])

    useEffect(() => {
        statistiqueService.getUserPeriodData(currentUserId, period).then((res) => {
            setPeriodeData(res.data)
        })
    }, [])

    return (
        <Card
            type="inner"
            title="Les statistiques de la semaine"
            extra={
                <Dropdown
                    overlay={
                        <Menu>
                            <Menu.Item key="all">
                                <Link to="/statistique">Voir les statistiques</Link>
                            </Menu.Item>
                        </Menu>
                    }
                    trigger={['click']}
                >
                    <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                        <MoreOutlined />
                    </a>
                </Dropdown>
            }
        >
            <div className="ant-row">
                <div className="ant-col ant-col-xs-24 ant-col-xl-24">
                    <ChartBar periodeData={periodeData} />
                </div>
            </div>
        </Card>
    )
}

export default StatistiqueWidget
