import { Button, PageHeader  } from 'antd';
import React, { useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf';
import { DownloadOutlined, CaretRightOutlined, CaretLeftOutlined } from '@ant-design/icons';
import guide from '../../static/guide/guide.pdf'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const Documentation = () => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const size = 'large';

    function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
    }
  
    return (    
                <div 
                    className="ant-row">
                    <div 
                        className="ant-col ant-col-xs-24 ant-col-xl-24">
                        <PageHeader
                            ghost={false}
                            onBack={() => window.history.back()}
                            title="Guide d'utilisation"
                            extra={[
                            <Button 
                                type="primary" 
                                icon={<DownloadOutlined />} 
                                size={size}  
                                href={guide}
                                download>
                                    <span 
                                        style={{'color':'white'}}>
                                        Télécharger 
                                    </span>
                            </Button>
                        ]}
                        >
                        <Document 
                            file={guide} 
                            onLoadSuccess={onDocumentLoadSuccess}>
                            <Page 
                                size="A4" 
                                width={1100} 
                                pageNumber={pageNumber} 
                            />
                        </Document>
                                <div style={{'margin-left': 550}}>
                                    <p>
                                    Page {pageNumber} of {numPages}
                                    </p>
                                    
                                    <Button shape="circle" icon={<CaretLeftOutlined />} size="large" 
                                            disabled={pageNumber == 1}
                                            onClick={() => setPageNumber(pageNumber - 1)
                                            }

                                                    />
                                    <Button shape="circle" icon={<CaretRightOutlined />} size="large" 
                                                    disabled={pageNumber >= numPages}
                                                    onClick={() => setPageNumber(pageNumber + 1)}
                                                    />
                                </div>
                                
                        </PageHeader>
                    </div>
                </div>
    )
}


export { Documentation }