import { ArrowRightOutlined, MoreOutlined, SearchOutlined } from '@ant-design/icons'
import { Button, Card, Col, Descriptions, Dropdown, Divider, List, Menu, Row, Table, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import EchangeService from 'services/EchangeService'
import track, { useTracking } from 'react-tracking'

const numFileRecords = 4
const numBordereauxRecords = 10

const EchangeWidget = () => {
    const { trackEvent } = useTracking()
    const [fileIn, setFileIn] = useState()
    const [fileOut, setFileOut] = useState()
    const [data, setData] = useState([])

    useEffect(() => {
        const currentUserId = localStorage.getItem('id')
        const latestEchangesRequest = {
            emis: true,
            recus: true,
            reference: null,
            startDate: null,
            endDate: null
        }
        EchangeService.getUserLatestBoredreau(currentUserId, latestEchangesRequest)
            .then((res) => {
                const researchedData = res.data.slice(0, numBordereauxRecords).map((data) => ({
                    key: data.id,
                    bordereau: data.bordereau,
                    timestamp: data.timestamp,
                    emetteur: data.emetteur,
                    receveur: data.receveur,
                    ordersType: data.ordersType
                }))
                setData(researchedData)
                trackEvent({
                    funComponent: 'List-Bordereaux',
                    event: 'List-Bordereaux-getData'
                })
            })
            .catch((err) => {
                trackEvent({
                    funComponent: 'List-Bordereaux',
                    event: 'List-Bordereaux-getData',
                    status: JSON.stringify(err.response?.data.status)
                })
            })
        EchangeService.getUserLatestInputFiles(currentUserId, numFileRecords)
            .then((res) => {
                setFileIn(res.data)
                trackEvent({
                    funComponent: 'List-InputFiles',
                    event: 'List-InputFiles-getData'
                })
            })
            .catch((err) => {
                trackEvent({
                    funComponent: 'List-InputFiles',
                    event: 'List-InputFiles-getData',
                    status: JSON.stringify(err.response?.data.status)
                })
            })
        EchangeService.getUserLatestOutputFiles(currentUserId, numFileRecords)
            .then((res) => {
                setFileOut(res.data)
                trackEvent({
                    funComponent: 'List-OutputFiles',
                    event: 'List-OutputFiles-getData'
                })
            })
            .catch((err) => {
                trackEvent({
                    funComponent: 'List-OutputFiles',
                    event: 'List-OutputFiles-getData',
                    status: JSON.stringify(err.response?.data.status)
                })
            })
    }, [])

    return (
        <>
            <div className="ant-row">
                <div className="ant-col ant-col-xs-24 ant-col-xl-6">
                    <Card type="inner" title="Derniers inter-changes reçus" style={{ height: '100%' }}>
                        <List
                            size="middle"
                            dataSource={fileIn}
                            renderItem={(item) => (
                                <Row gutter={16}>
                                    <Col span={4}>
                                        <ArrowRightOutlined style={{ fontSize: '30px', marginTop: '.8rem' }} />
                                    </Col>
                                    <Col span={18} style={{ marginBottom: '-.75rem' }}>
                                        <List.Item>
                                            <Descriptions column={1}>
                                                <Descriptions.Item label="Src">
                                                    <Tooltip placement="rightTop" title={item[1]}>
                                                        {item[1].slice(0, 19) + '...'}
                                                    </Tooltip>
                                                </Descriptions.Item>
                                                <Descriptions.Item label="File">
                                                    <Tooltip placement="rightTop" title={item[2]}>
                                                        {item[2].slice(0, 19) + '...'}
                                                    </Tooltip>
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Type">{item[3]}</Descriptions.Item>
                                            </Descriptions>
                                        </List.Item>
                                    </Col>
                                </Row>
                            )}
                        />
                    </Card>
                </div>
                <Divider type="vertical" />
                <div className="ant-col ant-col-xs-24 ant-col-xl-11">
                    <Card
                        style={{ height: '100%' }}
                        type="inner"
                        title="Emission-Reception"
                        extra={
                            <Dropdown
                                overlay={
                                    <Menu>
                                        <Menu.Item key="0">
                                            <Link to="/RechercheBord">View all</Link>
                                        </Menu.Item>
                                    </Menu>
                                }
                                trigger={['click']}
                            >
                                <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                                    <MoreOutlined />
                                </a>
                            </Dropdown>
                        }
                    >
                        <div>
                            <Table
                                id="table-emission-reception"
                                columns={[
                                    {
                                        id: 'id',
                                        title: 'id',
                                        dataIndex: 'key',
                                        hidden: true
                                    },
                                    {
                                        id: 'bordereau',
                                        title: 'Bordereau',
                                        dataIndex: 'bordereau'
                                    },

                                    {
                                        id: 'emetteur',
                                        title: 'Emetteur',
                                        dataIndex: 'emetteur'
                                    },
                                    {
                                        id: 'receveur',
                                        title: 'Destinataire',
                                        dataIndex: 'receveur'
                                    },
                                    {
                                        id: 'ordersType',
                                        title: "Type d'ordres",
                                        dataIndex: 'ordersType'
                                    },
                                    {
                                        id: 'detail',
                                        title: 'Détail',
                                        dataIndex: 'detail',

                                        render: (text, record, index) => (
                                            <div className="btn-wrap">
                                                <Button
                                                    id="btn-details-bordereau"
                                                    onClick={() => {
                                                        window.localStorage.setItem(
                                                            'bordereau',
                                                            JSON.stringify({
                                                                bordereau: record.bordereau,
                                                                bordereauId: record.key
                                                            })
                                                        )
                                                        window.location.replace('/RechercheBord')
                                                        trackEvent({
                                                            funComponent: 'Button-Details-Bordereau',
                                                            event: 'Button-Details-Bordereau-' + record.key + '-clicked'
                                                        })
                                                    }}
                                                >
                                                    <a href="#details">
                                                        <SearchOutlined />
                                                    </a>
                                                </Button>
                                            </div>
                                        )
                                    }
                                ].filter((item) => !item.hidden)}
                                size="small"
                                dataSource={data}
                                pagination={false}
                                scroll={{ x: 120 }}
                            />
                        </div>
                    </Card>
                </div>
                <Divider type="vertical" />
                <div className="ant-col ant-col-xs-24 ant-col-xl-6">
                    <Card type="inner" title="Derniers inter-changes émis" style={{ height: '100%' }}>
                        <List
                            size="middle"
                            dataSource={fileOut}
                            renderItem={(item) => (
                                <Row gutter={16}>
                                    <Col span={18} style={{ marginBottom: '-.75rem' }}>
                                        <List.Item>
                                            <Descriptions column={1}>
                                                <Descriptions.Item label="Dest">
                                                    <Tooltip placement="rightTop" title={item[1]}>
                                                        {item[1].slice(0, 19) + '...'}
                                                    </Tooltip>
                                                </Descriptions.Item>
                                                <Descriptions.Item label="File">
                                                    <Tooltip placement="bottom" title={item[2]}>
                                                        {item[2].slice(0, 19) + '...'}
                                                    </Tooltip>
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Type">{item[3]}</Descriptions.Item>
                                            </Descriptions>
                                        </List.Item>
                                    </Col>
                                    <Col span={1}>
                                        <ArrowRightOutlined style={{ fontSize: '30px', marginTop: '.8rem' }} />
                                    </Col>
                                </Row>
                            )}
                        />
                    </Card>
                </div>
            </div>
        </>
    )
}

export default EchangeWidget
