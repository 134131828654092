import axios from "axios";
import { API_URL } from 'utilities/constants'

const authHeader = () => {
  const accessToken = localStorage.getItem('token')

  if (accessToken) {
      return 'Bearer ' + accessToken;
  } else {
      return ;
  }
}

export default axios.create({
  baseURL: API_URL.API_URL,
  headers: {
    Authorization: authHeader(),
     "Content-type": "application/json"
  }
});