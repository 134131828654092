import React, { useState, useEffect } from 'react'
import 'antd/dist/antd.less'
import { TableSimple } from '.'
import { getColumnSearchProps } from '../../utilities/table-utils'
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import RetentionService from '../../services/RetentionService'
import { message, Button, Modal, Form, Input, DatePicker } from 'antd'
import moment from 'moment'
import { hasActionByModule } from 'utilities/modules'
import track, { useTracking } from 'react-tracking'

const formatDate = 'YYYY/MM/DD'

const RetentionTable = ({ isExist, retentions, getRetentions, intersectionRetentions }) => {
    const { trackEvent } = useTracking()
    const [visible, setVisible] = useState(false)
    const [visibleConfirmDeleteRetention, setVisibleConfirmDeleteRetention] = useState(false)
    const [updatedRetention, setUpdatedRetention] = useState({
        id: '',
        emetteur: '',
        destinataire: '',
        orderType: '',
        delai: '',
        dateFrom: null,
        dateTo: null
    })
    const [searchText, setSearchText] = useState('')
    const [searchedColumn, setSearchedColumn] = useState('')

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
    }

    const handleReset = (clearFilters) => {
        clearFilters()
        setSearchText('')
    }

    const configRetentionTable = {
        columns: [
            {
                id: 'emetteur',
                title: 'Emetteur',
                dataIndex: 'emetteur',
                ...getColumnSearchProps('emetteur', searchText, searchedColumn, handleSearch, handleReset),
                sorter: {
                    compare: (cell1Data, cell2Data) => cell1Data.emetteur.localeCompare(cell2Data.emetteur),
                    multiple: 1
                }
            },
            {
                id: 'destinataire',
                title: 'Destinataire',
                dataIndex: 'destinataire',
                ...getColumnSearchProps('destinataire', searchText, searchedColumn, handleSearch, handleReset),
                sorter: {
                    compare: (cell1Data, cell2Data) => cell1Data.destinataire.localeCompare(cell2Data.destinataire),
                    multiple: 2
                }
            },
            {
                id: 'orderType',
                title: 'Type orders',
                dataIndex: 'orderType',
                ...getColumnSearchProps('orderType', searchText, searchedColumn, handleSearch, handleReset),
                sorter: {
                    compare: (cell1Data, cell2Data) => cell1Data.orderType.localeCompare(cell2Data.orderType),
                    multiple: 3
                }
            },
            {
                id: 'delai',
                title: 'Delai',
                dataIndex: 'delai'
            },
            {
                id: 'dateFrom',
                title: 'Date de début',
                dataIndex: 'dateDebut',
                ...getColumnSearchProps('dateDebut', searchText, searchedColumn, handleSearch, handleReset),
                sorter: {
                    compare: (cell1Data, cell2Data) => {
                        const date1 = new Date(cell1Data.dateDebut.replace(/-/g, '/'))
                        const date2 = new Date(cell2Data.dateDebut.replace(/-/g, '/'))
                        return date1 - date2
                    },
                    multiple: 5
                }
            },
            {
                id: 'dateTo',
                title: 'Date de fin',
                dataIndex: 'dateFin',
                ...getColumnSearchProps('dateFin', searchText, searchedColumn, handleSearch, handleReset),
                sorter: {
                    compare: (cell1Data, cell2Data) => {
                        if (cell1Data.dateFin === null) {
                            return 1
                        }
                        if (cell2Data.dateFin === null) {
                            return -1
                        }
                        const date1 = new Date(cell1Data.dateFin.replace(/-/g, '/'))
                        const date2 = new Date(cell2Data.dateFin.replace(/-/g, '/'))
                        return date1 - date2
                    },
                    multiple: 6
                }
            },
            hasActionByModule('Retention', 'Editer') || hasActionByModule('Retention', 'Supprimer')
                ? {
                      id: 'action',
                      title: 'Action',
                      ellipsis: true,
                      dataIndex: '',
                      render: (text, record) => (
                          <div
                              className="btn-wrap"
                              style={{
                                  width: '90px'
                              }}
                          >
                              {
                                  hasActionByModule('Retention', 'Editer') && (
                                      <>
                                          <Button
                                              id={'edit-retention'}
                                              size="small"
                                              onClick={() => {
                                                  setVisible(true)
                                                  setUpdatedRetention((prevState) => ({
                                                      ...prevState,
                                                      id: record.id,
                                                      emetteur: record.emetteur,
                                                      destinataire: record.destinataire,
                                                      orderType: record.orderType,
                                                      delai: record.delai,
                                                      dateFrom: record.dateDebut,
                                                      dateTo: record.dateFin
                                                  }))
                                                  trackEvent({
                                                      funComponent: 'Button-Edit-Retention',

                                                      event: 'Button-Edit-Retention-' + record.id + '-clicked'
                                                  })
                                              }}
                                          >
                                              <EditOutlined />
                                          </Button>
                                      </>
                                  )
                              }
                              {
                                  hasActionByModule('Retention', 'Supprimer') && (
                                      <Button
                                          id={'delete-retention'}
                                          size="small"
                                          onClick={() => {
                                              setVisibleConfirmDeleteRetention(true)
                                              trackEvent({
                                                  funComponent: 'Button-Delete-Retention',

                                                  event: 'Button-Delete-Retention-' + record.id + '-clicked'
                                              })
                                          }}
                                      >
                                          <DeleteOutlined />
                                      </Button>
                                  )
                              }
                              {visibleConfirmDeleteRetention === true ? confirmDeleteRetention(record.id) : null}
                          </div>
                      ),
                      width: '14%'
                  }
                : {}
        ],
        info: retentions,
        scrollX: 800,
        pagination: {
            pageSize: 10,
            position: ['downRight']
        },
        size: 'small'
    }

    useEffect(() => {
        getRetentions()
    }, [])
    const deleteRetention = (idRetention) => {
        trackEvent({
            funComponent: 'Button-Confirm-Delete-Retention',

            event: 'Button-Confirm-Delete-Retention-clicked'
        })
        RetentionService.deleteRetention(idRetention)
            .then(() => {
                trackEvent({
                    funComponent: 'Delete-Retention',
                    event: 'Delete-Retention-sendData'
                })
                message.success('La rétention a été supprimé avec succès')
                getRetentions()
            })
            .catch((e) => {
                trackEvent({
                    funComponent: 'Delete-Retention',
                    event: 'Delete-Retention-sendData',
                    status: JSON.stringify(e.response?.data.status)
                })
                message.error(e + 'Une erreur est survenue lors de la suppression de la rétention.')
            })
        setVisibleConfirmDeleteRetention(false)
    }
    const cancelConfirmDeleteRetention = () => {
        setVisibleConfirmDeleteRetention(false)
    }
    const confirmDeleteRetention = (idRetention) => {
        return (
            <Modal
                visible={visibleConfirmDeleteRetention}
                title="Confirmer"
                onCancel={cancelConfirmDeleteRetention}
                footer={[
                    <Button id="confirm-annuler-Retention" key="back" onClick={cancelConfirmDeleteRetention}>
                        Annuler
                    </Button>,
                    <Button id="confirm-delete-Retention" key="submit" type="primary" onClick={() => deleteRetention(idRetention)}>
                        Confirmer
                    </Button>
                ]}
            >
                <p>Vous êtes sur de vouloir supprimer cette rétention?</p>
            </Modal>
        )
    }

    const handleCancel = () => {
        setUpdatedRetention({
            id: '',
            emetteur: '',
            destinataire: '',
            orderType: '',
            delai: '',
            dateFrom: null,
            dateTo: null
        })
        setVisible(false)
    }
    const cancelModifRetention = () => {
        handleCancel()
        trackEvent({
            funComponent: 'Button-Cancel-Edit-Retention',

            event: 'Button-Cancel-Edit-Retention-clicked'
        })
    }
    const modifRetention = () => {
        modifierRetention()
        trackEvent({
            funComponent: 'Button-Ok-Edit-Retention',

            event: 'Button-Ok-Edit-Retention-clicked'
        })
    }
    const afficherModal = () => {
        return (
            <Modal
                visible={visible}
                title={'Modification de la rétention'}
                onCancel={cancelModifRetention}
                footer={[
                    <Button id="cancel-Retention" key="back" onClick={cancelModifRetention}>
                        Cancel
                    </Button>,
                    <Button id="modifier-Retention" key="submit" type="primary" onClick={modifRetention}>
                        Modifier
                    </Button>
                ]}
            >
                <Form
                    labelCol={{
                        span: 8
                    }}
                    wrapperCol={{
                        span: 8
                    }}
                    layout="horizontal"
                    method="POST"
                >
                    <br />

                    <Form.Item label="Emetteur" name="emetteur" required>
                        <Input
                            id="emetteur"
                            style={{ width: '100%' }}
                            required
                            key={`emetteur:${updatedRetention.id || updatedRetention.id}`}
                            defaultValue={'' || updatedRetention.emetteur}
                            disabled
                        ></Input>
                    </Form.Item>
                    <Form.Item label="Destinataire" name="destinataire" required>
                        <Input
                            id="destinataire"
                            style={{ width: '100%' }}
                            required
                            key={`destinataire:${updatedRetention.destinataire || updatedRetention.destinataire}`}
                            defaultValue={'' || updatedRetention.destinataire}
                            disabled
                        ></Input>
                    </Form.Item>
                    <Form.Item label="Type Orders" name="orderType" required>
                        <Input
                            id="orderType"
                            style={{ width: '100%' }}
                            required
                            key={`orderType:${updatedRetention.orderType || updatedRetention.orderType}`}
                            defaultValue={'' || updatedRetention.orderType}
                            disabled
                        ></Input>
                    </Form.Item>
                    <Form.Item label="Delai" name="delai" required>
                        <Input
                            id="delai"
                            style={{ width: '100%' }}
                            required
                            key={`delai:${updatedRetention.delai || updatedRetention.delai}`}
                            defaultValue={'' || updatedRetention.delai}
                            disabled
                        ></Input>
                    </Form.Item>
                    <Form.Item
                        label="Date début"
                        name="Date début"
                        rules={[
                            {
                                required: true
                            }
                        ]}
                    >
                        <DatePicker
                            id="dateFrom"
                            disabledDate={(current) =>
                                (current && current < new Date(new Date().setDate(new Date().getDate() - 1))) ||
                                current > new Date(new Date().setDate(new Date().getDate() + 365)) ||
                                moment(updatedRetention.dateFrom?.toString().replaceAll('-', '/')) <= new Date()
                            }
                            defaultValue={moment(updatedRetention.dateFrom?.toString().replaceAll('-', '/'), formatDate)}
                            format={formatDate}
                            onChange={(val) => {
                                setUpdatedRetention((prevState) => ({
                                    ...prevState,
                                    dateFrom: val
                                }))
                                trackEvent({
                                    funComponent: 'Edit-Date-Debut-Retention',

                                    event: 'Edit-Date-Debut-Retention-' + new Date(val) + '-choosed'
                                })
                            }}
                            allowClear={false}
                        />
                    </Form.Item>
                    <Form.Item label="Date fin" name="Date fin">
                        <DatePicker
                            id="dateTo"
                            disabledDate={(current) =>
                                (current && current < new Date(new Date().setDate(new Date().getDate() - 1))) ||
                                current > new Date(new Date().setDate(new Date().getDate() + 365)) ||
                                current <=
                                    (updatedRetention.dateFrom ? moment(updatedRetention.dateFrom?.toString().replaceAll('-', '/'), formatDate) : '')
                            }
                            defaultValue={updatedRetention.dateTo ? moment(updatedRetention.dateTo?.toString().replaceAll('-', '/'), formatDate) : ''}
                            format={formatDate}
                            onChange={(val) => {
                                setUpdatedRetention((prevState) => ({
                                    ...prevState,
                                    dateTo: val
                                }))
                                trackEvent({
                                    funComponent: 'Edit-Date-Fin-Retention',

                                    event: 'Edit-Date-Fin-Retention-' + new Date(val) + '-choosed'
                                })
                            }}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
    const modifierRetention = async () => {
        message.destroy()
        if (updatedRetention.id !== '' && updatedRetention.dateFrom !== null) {
            let retentionsUpdate = retentions
            let retentionDeletedIndex = retentionsUpdate.findIndex((retention) => retention.id === updatedRetention.id)
            retentionsUpdate.splice(retentionDeletedIndex, 1)
            if (moment(updatedRetention.dateTo).format('YYYY-MM-DD') <= moment(updatedRetention.dateFrom).format('YYYY-MM-DD')) {
                message.error('Veuillez choisir au moins un jour ouvrable entre Date début et la date de fin')
                return
            }
            if (
                isExist(
                    updatedRetention.orderType,
                    updatedRetention.emetteur,
                    updatedRetention.destinataire,
                    updatedRetention.dateFrom,
                    updatedRetention.dateTo,
                    retentionsUpdate
                )
            ) {
                let messageAlert = 'Cette rétention interférant avec '
                messageAlert =
                    messageAlert + (intersectionRetentions.length === 1 ? 'une rétention :' : +intersectionRetentions.length + ' rétentions :')
                intersectionRetentions.filter(
                    (intersectionRetention, index) =>
                        (messageAlert =
                            messageAlert +
                            ' rétention (' +
                            (index + 1) +
                            ') => Date Début: ' +
                            intersectionRetention.dateDebut +
                            ' et Date Fin: ' +
                            intersectionRetention.dateFin)
                )
                message.error(messageAlert)
                setUpdatedRetention({
                    id: '',
                    emetteur: '',
                    destinataire: '',
                    orderType: '',
                    delai: '',
                    dateFrom: null,
                    dateTo: null
                })
                getRetentions()
                setVisible(false)
                return
            } else {
                RetentionService.modifierRetention(updatedRetention)
                    .then(() => {
                        message.success('La régle de rétention est modifié avec succès')
                        setUpdatedRetention({
                            id: '',
                            emetteur: '',
                            destinataire: '',
                            orderType: '',
                            delai: '',
                            dateFrom: null,
                            dateTo: null
                        })
                        getRetentions()
                        setVisible(false)
                        trackEvent({
                            funComponent: 'Update-Retention',
                            event: 'Update-Retention-sendData'
                        })
                    })
                    .catch((e) => {
                        trackEvent({
                            funComponent: 'Update-Retention',
                            event: 'Update-Retention-sendData',
                            status: JSON.stringify(e.response?.data.status)
                        })
                        message.error(e + "Une erreur est survenue lors de la modification d'une rétention.")
                    })
            }
        } else {
            message.error('vous pouvez pas modifier cette rétention, il faut remplir tous les champs')
        }
    }
    return (
        <div>
            <TableSimple data={configRetentionTable} />
            {visible === true ? afficherModal() : null}
        </div>
    )
}

export default RetentionTable
