import http from 'utilities/http-common'

const getUserLatestInputFiles = (userId, numRecords) => {
    return http.get('/api/v1/users/' + userId + '/interchange/in/' + numRecords)
}

const getUserLatestOutputFiles = (userId, numRecords) => {
    return http.get('/api/v1/users/' + userId + '/interchange/out/' + numRecords)
}

const getUserLatestBoredreau = (userId, latestEchangesRequest) => {
    return http.post('/api/v1/users/' + userId + '/research/bordereaux', latestEchangesRequest)
}

const getUserRecepisses = (userId, request) => {
    return http.post('/api/v1/users/' + userId + '/research/recepisse', request)
}

const getUserRecepisseDetails = (userId, recepisseId) => {
    return http.get('/api/v1/users/' + userId + '/research/recepisse/' + recepisseId + '/details')
}

const getReportSuiviData = (userId, recepisseId) => {
    return http.get('/api/v1/users/' + userId + '/research/recepisse/' + recepisseId + '/events/GTF010')
}
const getReportPointageData = (userId, recepisseId) => {
    return http.get('/api/v1/users/' + userId + '/research/recepisse/' + recepisseId + '/events/GTF020')
}

const getRecepisseByBordereauIdAndOrderType = (userId, bordereauId, orderType) => {
    return http.get('/api/v1/users/' + userId + '/research/bordereaux/' + bordereauId + '/recepice/' + orderType)
}

const getBordereauDetailsById = (userId, bordereauId) => {
    return http.get('/api/v1/users/' + userId + '/research/bordereaux/' + bordereauId + '/details')
}

const getRecepisseOrderAddons = (recepisse) => {
    return http.get('/api/v1/orders/' + recepisse + '/addons')
}

export default {
    getUserLatestInputFiles,
    getUserLatestOutputFiles,
    getUserLatestBoredreau,
    getUserRecepisses,
    getUserRecepisseDetails,
    getReportSuiviData,
    getReportPointageData,
    getRecepisseByBordereauIdAndOrderType,
    getBordereauDetailsById,
    getRecepisseOrderAddons
}
