import http from '../utilities/http-common'

const createTicket = (ticket) => {
    return http.post('/api/v1/ticketing/save', ticket)
}

const getTicket = (data) => {
    return http.post('/api/v1/ticketing/get', data)
}

const getUser = (idUser) => {
    return http.get('/api/v1/ticketing/getUser/' + idUser)
}

const getPriority = (idPrioritie) => {
    return http.get('/api/v1/ticketing/getPrioritie/' + idPrioritie)
}

const getStatus = (idStatus) => {
    return http.get('/api/v1/ticketing/getStatus/' + idStatus)
}

const getArticle = (idTicket) => {
    return http.get('/api/v1/ticketing/getArticle/' + idTicket)
}

const sendArticle = (article) => {
    return http.post('/api/v1/ticketing/article', article)
}

export default {
    createTicket,
    getTicket,
    getStatus,
    sendArticle,
    getArticle,
    getUser,
    getPriority
}
