import { ClearOutlined, SearchOutlined } from '@ant-design/icons'
import { Button, Card, DatePicker, Form, Row, Input, Select, message } from 'antd'
import React, { useState, useEffect } from 'react'
import { DEFAULT_FORM_LAYOUT } from 'utilities/ui'
import RetentionService from '../../services/RetentionService'
import OrdersStepService from '../../services/OrdersStepService'
import '../../static/less/app.less'
import track, { useTracking } from 'react-tracking'

const { RangePicker } = DatePicker
const { Option } = Select
const secondaryColor = localStorage.getItem('secondary_color')
const currentUserId = localStorage.getItem('id')
const MAX_EMISSOON_RECEPTION_DAYS = 15
const MAX_EMISSION_OR_RECEPTION_DAYS = 30

const EchangesSearchForm = (props) => {
    const { trackEvent } = useTracking()
    const [dates, setDates] = useState([])
    const [value, setValue] = useState()
    const [orientationData, setOrientationData] = useState()
    const [ordersTypes, setOrdersTypes] = useState([])
    const bordereauInfo = JSON.parse(localStorage.getItem('bordereau') ? localStorage.getItem('bordereau') : null)

    const handleSearch = async (values, fetchCallback) => {
        if (bordereauInfo === null) {
            let emis = false
            let recus = false
            let orientation = values.orientation === undefined ? 'em_recep' : values.orientation
            if (orientation === 'emission' || orientation === 'em_recep') emis = true
            if (orientation === 'reception' || orientation === 'em_recep') recus = true
            let requestBody = {
                emis: emis,
                recus: recus,
                reference: values.reference === undefined || values.reference === null || values.reference === '' ? null : values.reference,
                startDate: values.date === undefined || values.date === null ? null : values.date[0]._d,
                endDate: values.date === undefined || values.date === null ? null : values.date[1]._d,
                ordersType: values.type === undefined || values.type === 'Tous' ? null : values.type
            }
            message.loading('Chargement des données en cours ...', 0.5).then(() => {
                OrdersStepService.getStepsByType(values.type)
                    .then((res) => {
                        const steps = res.data.map((data) => data.libelle)
                        props.setSteps(steps)
                        trackEvent({
                            funComponent: 'List-Type',
                            event: 'List-Type-getData'
                        })
                    })
                    .catch((err) => {
                        trackEvent({
                            funComponent: 'List-Type',
                            event: 'List-Type-getData',
                            status: JSON.stringify(err.response?.data.status)
                        })
                        message.error('Une erreur est survenue lors du chargement des étapes.')
                    })
                fetchCallback(currentUserId, requestBody)
                    .then((res) => {
                        props.setSearchedData(res.data)
                        message.success('Chargement des données terminé')
                        trackEvent({
                            funComponent: 'List-Bordereaux',
                            event: 'List-Bordereaux-getData'
                        })
                    })
                    .catch((err) => {
                        trackEvent({
                            funComponent: 'List-Bordereaux',
                            event: 'List-Bordereaux-getData',
                            status: JSON.stringify(err.response?.data.status)
                        })
                        message.error('Une erreur est survenue lors du chargement des données.')
                    })
            })
        }
    }

    useEffect(() => {
        let value = {
            orientation: 'em_recep'
        }
        handleSearch(value, props.fetchCallback)
        RetentionService.getOrdersTypes().then((res) => {
            res.data[3] = { id: 4, libelle: 'Tous' }
            setOrdersTypes(res.data.reverse())
        })
    }, [])

    const onOpenChange = (open) => {
        if (open) {
            setDates([])
        }
    }

    const disabledDate = (current) => {
        if (!dates || dates.length === 0) {
            return false
        }
        if (orientationData !== 'em_recep') {
            const tooLate = dates[0] && current.diff(dates[0], 'days') > MAX_EMISSION_OR_RECEPTION_DAYS
            const tooEarly = dates[1] && dates[1].diff(current, 'days') > MAX_EMISSION_OR_RECEPTION_DAYS
            return tooEarly || tooLate
        } else {
            const tooLate = dates[0] && current.diff(dates[0], 'days') > MAX_EMISSOON_RECEPTION_DAYS
            const tooEarly = dates[1] && dates[1].diff(current, 'days') > MAX_EMISSOON_RECEPTION_DAYS
            return tooEarly || tooLate
        }
    }

    return (
        <Form
            {...DEFAULT_FORM_LAYOUT}
            id="searchForm"
            initialValues={{ orientation: 'em_recep', type: 'Tous' }}
            onFinish={(values) => handleSearch(values, props.fetchCallback)}
        >
            <Card>
                <Row className="ant-row" justify="center" align="top">
                    <div className="ant-col ant-col-xs-24 ant-col-xl-6">
                        <Form.Item style={{ marginLeft: '10%' }} label="Orientation" name="orientation">
                            <Select
                                id="orientation"
                                onChange={(val) => {
                                    setOrientationData(val)
                                    trackEvent({
                                        funComponent: 'Orientation',
                                        event: 'Orientation-' + val + '-Selected'
                                    })
                                }}
                            >
                                <Option id="em_recep" value="em_recep">
                                    Emission et Reception
                                </Option>
                                <Option id="emission" value="emission">
                                    Emission
                                </Option>
                                <Option id="reception" value="reception">
                                    Reception
                                </Option>
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="ant-col ant-col-xs-24 ant-col-xl-6">
                        <Form.Item style={{ marginLeft: '10%' }} label="Type" name="type">
                            <Select
                                id="type"
                                showSearch
                                onChange={(val) =>
                                    trackEvent({
                                        funComponent: 'Type',
                                        event: 'Type-' + val + '-Selected'
                                    })
                                }
                                filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                filterSort={(optionA, optionB) => optionA.label.toLowerCase().localeCompare(optionB.label.toLowerCase())}
                                options={ordersTypes.map((ordersType) => ({
                                    value: ordersType.libelle,
                                    label: ordersType.libelle
                                }))}
                            />
                        </Form.Item>
                    </div>
                    <div className="ant-col ant-col-xs-24 ant-col-xl-6">
                        <Form.Item style={{ marginLeft: '10%' }} label="Référence" name="reference">
                            <Input
                                id="reference"
                                onChange={(e) => {
                                    trackEvent({
                                        funComponent: 'Reference',
                                        event: 'Reference-' + e.target.value + '-puted'
                                    })
                                }}
                            />
                        </Form.Item>
                    </div>
                    <div className="ant-col ant-col-xs-24 ant-col-xl-6">
                        <Form.Item style={{ marginLeft: '10%' }} label="Date" name="date">
                            <RangePicker
                                id="rangePicker-date"
                                value={value}
                                disabledDate={disabledDate}
                                onCalendarChange={(val) => setDates(val)}
                                onChange={(val, dateString) => {
                                    setValue(val)
                                    trackEvent({
                                        funComponent: 'RangePicker',
                                        event: 'RangePicker-' + dateString + '-choosed'
                                    })
                                }}
                                onOpenChange={onOpenChange}
                            />
                        </Form.Item>
                    </div>
                </Row>
                <div className="ant-row" style={{ marginRight: '3rem', justifyContent: 'end', marginLeft: '3rem' }}>
                    <Button.Group>
                        <div className="ant-row">
                            <div className="ant-col">
                                <Button
                                    id="btn-rechercher"
                                    style={{ backgroundColor: secondaryColor ? secondaryColor : '#3CB371', color: 'white' }}
                                    htmlType="submit"
                                    shape="round"
                                    icon={<SearchOutlined />}
                                    onClick={() => {
                                        trackEvent({
                                            funComponent: 'Button-Recherche',
                                            event: 'Button-Recherche-Clicked'
                                        })
                                    }}
                                >
                                    Rechercher
                                </Button>
                            </div>
                            <div className="ant-col">
                                <Button
                                    id="btn-reinitialiser"
                                    style={{ backgroundColor: secondaryColor ? secondaryColor : '#3CB371', color: 'white' }}
                                    shape="round"
                                    icon={<ClearOutlined />}
                                    onClick={() => {
                                        document.getElementById('searchForm').reset()
                                        trackEvent({
                                            funComponent: 'Button-Reinitialiser',
                                            event: 'Button-Reinitialiser-Clicked'
                                        })
                                    }}
                                >
                                    Réinitialiser
                                </Button>
                            </div>
                        </div>
                    </Button.Group>
                </div>
            </Card>
        </Form>
    )
}

export default EchangesSearchForm
