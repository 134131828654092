import { CheckCircleTwoTone, SearchOutlined, ExclamationCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons'
import { Button, Table, message } from 'antd'
import React, { useState } from 'react'
import jsonata from 'jsonata'
import '../../static/less/app.less'
import EchangeService from 'services/EchangeService'
import { getColumnSearchProps } from '../../utilities/table-utils'
import track, { useTracking } from 'react-tracking'

const currentUserId = localStorage.getItem('id')

const RecepTable = (props) => {
    const { trackEvent } = useTracking()
    const returnIcons = (data) => {
        return data === 1 ? (
            <CheckCircleTwoTone twoToneColor="#52c41a" />
        ) : data === 2 ? (
            <ExclamationCircleTwoTone twoToneColor="#FFA500" />
        ) : data === 3 ? (
            <CloseCircleTwoTone twoToneColor="#FF0000" />
        ) : (
            '-'
        )
    }

    const [searchText, setSearchText] = useState('')
    const [searchedColumn, setSearchedColumn] = useState('')

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
    }

    const handleReset = (clearFilters) => {
        clearFilters()
        setSearchText('')
    }

    const step1Name = props.steps[0] ? props.steps[0] : 'step1'
    const step2Name = props.steps[1] ? props.steps[1] : 'step2'
    const step3Name = props.steps[2] ? props.steps[2] : 'step3'
    const step4Name = props.steps[3] ? props.steps[3] : 'step4'

    const dataRecep = props.data.map((data) => {
        let adresse_content = JSON.parse(data.adresse_Content)
        let poids_content = JSON.parse(data.weight_Content)
        let expressionVille = data.path_3164 !== null && data.path_3164 !== '' ? jsonata(JSON.parse(data.path_3164)) : ''
        let expressionPays = data.path_3207 !== null && data.path_3207 !== '' ? jsonata(JSON.parse(data.path_3207)) : ''
        let expressionCodePostal = data.path_3251 !== null && data.path_3251 !== '' ? jsonata(JSON.parse(data.path_3251)) : ''
        let expressionPoids = data.path_Weight !== null && data.path_Weight !== '' ? jsonata(JSON.parse(data.path_Weight)) : ''
        let expressionPoidsUnite = data.path_WeightUnit !== null && data.path_WeightUnit !== '' ? jsonata(JSON.parse(data.path_WeightUnit)) : ''
        let expression_Rsdest = props.isRecepisse
            ? data.path_Rsdest !== null && data.path_Rsdest !== ''
                ? jsonata(JSON.parse(data.path_Rsdest))
                : ''
            : null

        let result = {
            key: data.id,
            id: data.id,
            date: data.order_Date,
            recipice: data.num_Recep,
            recep: props.isRecepisse ? (expression_Rsdest !== '' ? expression_Rsdest.evaluate(adresse_content) : '') : null,
            ville: expressionVille !== '' ? expressionVille.evaluate(adresse_content) : '',
            pays: expressionPays !== '' ? expressionPays.evaluate(adresse_content) : '',
            cp: expressionCodePostal !== '' ? expressionCodePostal.evaluate(adresse_content) : '',
            poids: expressionPoids !== '' ? expressionPoids.evaluate(poids_content) : '',
            unite: expressionPoidsUnite !== '' ? expressionPoidsUnite.evaluate(poids_content) : '',
            um: data.nb_Um,
            step00: returnIcons(data.step00),
            dataStep00: data.step00,
            step01: returnIcons(data.step01),
            dataStep01: data.step01,
            step02: returnIcons(data.step02),
            dataStep02: data.step02,
            step03: returnIcons(data.step03),
            dataStep03: data.step03
        }

        return result
    })

    return (
        <Table
            columns={[
                {
                    id: 'id',
                    title: 'Id',
                    dataIndex: 'id',
                    hidden: true,
                    key: 'id',
                    ...getColumnSearchProps('id', searchText, searchedColumn, handleSearch, handleReset),
                    sorter: {
                        compare: (cell1Data, cell2Data) => cell1Data.id - cell2Data.id,
                        multiple: 8
                    }
                },
                {
                    id: 'recipice',
                    title: 'Récépissé',
                    dataIndex: 'recipice',
                    width: '12%',
                    ...getColumnSearchProps('recipice', searchText, searchedColumn, handleSearch, handleReset),
                    sorter: {
                        compare: (cell1Data, cell2Data) => cell1Data.recipice - cell2Data.recipice,
                        multiple: 1
                    }
                },
                {
                    id: 'date',
                    title: 'Date',
                    dataIndex: 'date',
                    ...getColumnSearchProps('date', searchText, searchedColumn, handleSearch, handleReset),
                    sorter: {
                        compare: (cell1Data, cell2Data) => {
                            const date1 = new Date(cell1Data.date.replace(/-/g, '/'))
                            const date2 = new Date(cell2Data.date.replace(/-/g, '/'))
                            return date1 - date2
                        },
                        multiple: 2
                    }
                },
                {
                    id: 'destinataire',
                    title: 'Destinataire',
                    dataIndex: 'recep',
                    hidden: !props.isRecepisse,
                    ...getColumnSearchProps('recep', searchText, searchedColumn, handleSearch, handleReset),
                    sorter: {
                        compare: (cell1Data, cell2Data) => cell1Data.recep.localeCompare(cell2Data.recep),
                        multiple: 2
                    }
                },
                {
                    id: 'ville',
                    title: 'Ville',
                    dataIndex: 'ville',
                    ...getColumnSearchProps('ville', searchText, searchedColumn, handleSearch, handleReset),
                    sorter: {
                        compare: (cell1Data, cell2Data) => cell1Data.ville.localeCompare(cell2Data.ville),
                        multiple: 3
                    }
                },
                {
                    id: 'cp',
                    title: 'CP',
                    dataIndex: 'cp',
                    ...getColumnSearchProps('cp', searchText, searchedColumn, handleSearch, handleReset),
                    sorter: {
                        compare: (cell1Data, cell2Data) => cell1Data.cp - cell2Data.cp,
                        multiple: 4
                    }
                },
                {
                    id: 'pays',
                    title: 'Pays',
                    dataIndex: 'pays',
                    ...getColumnSearchProps('pays', searchText, searchedColumn, handleSearch, handleReset),
                    sorter: {
                        compare: (cell1Data, cell2Data) => cell1Data.pays.localeCompare(cell2Data.pays),
                        multiple: 5
                    }
                },
                {
                    id: 'poids',
                    title: 'Poids',
                    dataIndex: 'poids',
                    ...getColumnSearchProps('poids', searchText, searchedColumn, handleSearch, handleReset),
                    sorter: {
                        compare: (cell1Data, cell2Data) => cell1Data.poids - cell2Data.poids,
                        multiple: 6
                    }
                },
                {
                    id: 'unite',
                    title: 'Unité',
                    dataIndex: 'unite',
                    ...getColumnSearchProps('poids', searchText, searchedColumn, handleSearch, handleReset),
                    sorter: {
                        compare: (cell1Data, cell2Data) => cell1Data.unite - cell2Data.unite,
                        multiple: 7
                    }
                },
                {
                    id: 'um',
                    title: 'Um',
                    dataIndex: 'um',
                    ...getColumnSearchProps('poids', searchText, searchedColumn, handleSearch, handleReset),
                    sorter: {
                        compare: (cell1Data, cell2Data) => cell1Data.um - cell2Data.um,
                        multiple: 8
                    }
                },
                {
                    id: 'step00',
                    title: step1Name,
                    dataIndex: 'step00',
                    sorter: {
                        compare: (cell1Data, cell2Data) => cell1Data.dataStep00 - cell2Data.dataStep00,
                        multiple: 9
                    }
                },
                {
                    id: 'step01',
                    title: step2Name,
                    dataIndex: 'step01',
                    sorter: {
                        compare: (cell1Data, cell2Data) => cell1Data.dataStep01 - cell2Data.dataStep01,
                        multiple: 10
                    }
                },
                {
                    id: 'step02',
                    title: step3Name,
                    dataIndex: 'step02',
                    sorter: {
                        compare: (cell1Data, cell2Data) => cell1Data.dataStep02 - cell2Data.dataStep02,
                        multiple: 11
                    }
                },
                {
                    id: 'step03',
                    title: step4Name,
                    dataIndex: 'step03',
                    sorter: {
                        compare: (cell1Data, cell2Data) => cell1Data.dataStep03 - cell2Data.dataStep03,
                        multiple: 12
                    }
                },
                {
                    id: 'detail',
                    title: 'Détail',
                    dataIndex: 'detail',
                    render: (text, record) => (
                        <div className="btn-wrap">
                            <Button
                                id="btn-detail"
                                onClick={async (e) => {
                                    trackEvent({
                                        funComponent: 'Button-detail-Recepisse-' + record.id,
                                        event: 'Button-detail-Recepisse-Clicked'
                                    })
                                    message.loading('Chargement des details du récépissé ...', 0.5)
                                    EchangeService.getUserRecepisseDetails(currentUserId, record.id)
                                        .then((res) => {
                                            let data = res.data[0]
                                            let adresse_Content = JSON.parse(data.adresse_Content)
                                            let weight_Content = JSON.parse(data.weight_Content)
                                            let expressionDest_Adr =
                                                data.dest_Adr !== null && data.dest_Adr !== '' ? jsonata(JSON.parse(data.dest_Adr)) : ''
                                            let expressionDest_Cp =
                                                data.dest_Cp !== null && data.dest_Cp !== '' ? jsonata(JSON.parse(data.dest_Cp)) : ''
                                            let expressionDest_Loc =
                                                data.dest_Loc !== null && data.dest_Loc !== '' ? jsonata(JSON.parse(data.dest_Loc)) : ''
                                            let expressionDest_Pays =
                                                data.dest_Pays !== null && data.dest_Pays !== '' ? jsonata(JSON.parse(data.dest_Pays)) : ''
                                            let expressionDest_Rs =
                                                data.dest_Rs !== null && data.dest_Rs !== '' ? jsonata(JSON.parse(data.dest_Rs)) : ''
                                            let expressionEmet_Adr =
                                                data.emet_Adr !== null && data.emet_Adr !== '' ? jsonata(JSON.parse(data.emet_Adr)) : ''
                                            let expressionEmet_Cp =
                                                data.emet_Cp !== null && data.emet_Cp !== '' ? jsonata(JSON.parse(data.emet_Cp)) : ''
                                            let expressionEmet_Loc =
                                                data.emet_Loc !== null && data.emet_Loc !== '' ? jsonata(JSON.parse(data.emet_Loc)) : ''
                                            let expressionEmet_Pays =
                                                data.emet_Pays !== null && data.emet_Pays !== '' ? jsonata(JSON.parse(data.emet_Pays)) : ''
                                            let expressionEmet_Rs =
                                                data.emet_Rs !== null && data.emet_Rs !== '' ? jsonata(JSON.parse(data.emet_Rs)) : ''
                                            let expressionPath_weight =
                                                data.path_Weight !== null && data.path_Weight !== '' ? jsonata(JSON.parse(data.path_Weight)) : ''
                                            let expressionPath_weightUnit =
                                                data.path_WeightUnit !== null && data.path_WeightUnit !== ''
                                                    ? jsonata(JSON.parse(data.path_WeightUnit))
                                                    : ''
                                            let recepDetailResult = {
                                                emetteur: expressionEmet_Rs !== '' ? expressionEmet_Rs.evaluate(adresse_Content) : '-',
                                                destinataire: expressionDest_Rs !== '' ? expressionDest_Rs.evaluate(adresse_Content) : '-',
                                                adresse_Emet: expressionEmet_Adr !== '' ? expressionEmet_Adr.evaluate(adresse_Content) : '-',
                                                adresse_Dest: expressionDest_Adr !== '' ? expressionDest_Adr.evaluate(adresse_Content) : '-',
                                                pays_Emet: expressionEmet_Pays !== '' ? expressionEmet_Pays.evaluate(adresse_Content) : '-',
                                                pays_Dest: expressionDest_Pays !== '' ? expressionDest_Pays.evaluate(adresse_Content) : '-',
                                                cp_Emet: expressionEmet_Cp !== '' ? expressionEmet_Cp.evaluate(adresse_Content) : '-',
                                                cp_Dest: expressionDest_Cp !== '' ? expressionDest_Cp.evaluate(adresse_Content) : '-',
                                                ville_Emet: expressionEmet_Loc !== '' ? expressionEmet_Loc.evaluate(adresse_Content) : '-',
                                                ville_Dest: expressionDest_Loc !== '' ? expressionDest_Loc.evaluate(adresse_Content) : '-',
                                                nb_Um: data.nb_Um,
                                                path_Weight: expressionPath_weight !== '' ? expressionPath_weight.evaluate(weight_Content) : '-',
                                                path_WeightUnit:
                                                    expressionPath_weightUnit !== '' ? expressionPath_weightUnit.evaluate(weight_Content) : '-',
                                                regime: data.regime
                                            }
                                            props.setDetail(recepDetailResult)
                                            props.setRecepisseId(record.recipice)
                                            trackEvent({
                                                funComponent: 'List-Recepisse',
                                                event: 'List-Recepisse-getData'
                                            })
                                            message.success('Chargement des données terminé', 1)
                                        })
                                        .catch((err) => {
                                            trackEvent({
                                                funComponent: 'List-Recepisse',
                                                event: 'List-Recepisse-getData',
                                                status: JSON.stringify(err.response?.data.status)
                                            })
                                            message.error('Une erreur est survenue lors du chargement des détails du récépissé')
                                        })

                                    EchangeService.getReportSuiviData(currentUserId, record.id)
                                        .then((res) => {
                                            props.setrecepisseEvent(res.data)
                                            props.setShowDetail(true)
                                            trackEvent({
                                                funComponent: 'List-Suivi-Data',
                                                event: 'List-Suivi-Data-getData'
                                            })
                                        })
                                        .catch((err) => {
                                            trackEvent({
                                                funComponent: 'List-Suivi-Data',
                                                event: 'List-Suivi-Data-getData',
                                                status: JSON.stringify(err.response?.data.status)
                                            })
                                            message.error('Une erreur est survenue lors du chargement des données de suivi')
                                        })
                                    EchangeService.getReportPointageData(currentUserId, record.id)
                                        .then((res) => {
                                            props.setReportPointage(res.data)
                                            trackEvent({
                                                funComponent: 'List-Pointage-Data',
                                                event: 'List-Pointage-Data-getData'
                                            })
                                        })
                                        .catch((err) => {
                                            trackEvent({
                                                funComponent: 'List-Pointage-Data',
                                                event: 'List-Pointage-Data-getData',
                                                status: JSON.stringify(err.response?.data.status)
                                            })
                                            message.error('Une erreur est survenue lors du chargement des données de pointage')
                                        })
                                    EchangeService.getRecepisseOrderAddons(record.id)
                                        .then((res) => {
                                            props.setDetailsAdditionalInfo(res.data)
                                            trackEvent({
                                                funComponent: 'List-Additionnel-Data',
                                                event: 'List-Additionnel-Data-getData'
                                            })
                                        })
                                        .catch((err) => {
                                            trackEvent({
                                                funComponent: 'List-Additionnel-Data',
                                                event: 'List-Additionnel-Data-getData',
                                                status: JSON.stringify(err.response?.data.status)
                                            })
                                            message.error('Une erreur est survenue lors du chargement des données additionnel')
                                        })
                                    if (!props.isRecepisse) {
                                        props.setShowPopup(true)
                                    }
                                }}
                            >
                                <a href="#details">
                                    <SearchOutlined />
                                </a>
                            </Button>
                        </div>
                    )
                }
            ].filter((item) => !item.hidden)}
            size="small"
            scroll={{ x: 120 }}
            dataSource={dataRecep}
            pagination={{
                current: props.currentPage,
                pageSize: props.numRows,
                position: ['downRight'],
                onChange: (page) => {
                    if (props.setCurrentPage) props.setCurrentPage(page)
                }
            }}
        />
    )
}

export default RecepTable
