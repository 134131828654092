import { getUserPermissions, handleCheckBoxChange, getModuleAction } from './RoleProvider'
import React, { useEffect, useState } from 'react'
import { Button, Input, Table, Select, Checkbox, message } from 'antd'
import RoleService from 'services/RoleService'
import { getModuleEnumByLabel, getModuleRow } from '../../../constants/enums/moduleEnum'
import track, { useTracking } from 'react-tracking'

const CreateRole = (props) => {
    const { trackEvent } = useTracking()
    const userSubordinateEntities = props.userSubordinateEntities
    const [roleTableData, setRoleTableData] = useState([])
    const creationRole = { libelle: '', idEntite: '', moduleAction: [] }
    const [roleLibelle, setRoleLibelle] = useState('')
    const [roleEntite, setRoleEntite] = useState()
    const currentUserId = localStorage.getItem('id')
    const secondaryColor = localStorage.getItem('secondary_color')
    const modules = JSON.parse(window.localStorage.getItem('modules'))
    const [column, setColumn] = useState([
        {
            title: '',
            ellipsis: true,
            key: 'idAction',
            dataIndex: 'libelleAction',
            width: 90,
            fixed: 'left',
            render(text, row) {
                return {
                    props: {
                        style: { background: '#fafafa' }
                    },
                    children: <div style={{ fontWeight: 500 }}>{text}</div>
                }
            }
        }
    ])

    useEffect(() => {
        getUserPermissions(currentUserId, setRoleTableData)
        makeColumns()
    }, [])
    const makeColumns = () => {
        if (modules !== undefined && modules !== null) {
            Object.keys(modules).forEach((module) => {
                const sousCol = []
                let actionsIds = []
                let sousModules = modules[module].sousModule
                if (modules[module].module !== undefined) {
                    let moduleName = modules[module].module.libelle
                    if (sousModules === null) {
                        let actions = modules[module].module.actions
                        if (actions !== null && actions.length > 0) {
                            actions.forEach((action) => {
                                actionsIds.push(action.id)
                            })
                        }
                        sousCol.push({
                            title: '',
                            ellipsis: true,
                            dataIndex: getModuleEnumByLabel(moduleName),
                            key: modules[module].module.id,
                            render: (text, row) => {
                                if (getModuleRow(row, getModuleEnumByLabel(moduleName))?.hasCurrentAction) {
                                    if (actionsIds.includes(row.idAction))
                                        return (
                                            <Checkbox
                                                id={modules[module].module.id + '-' + row.idAction}
                                                onChange={(e) => {
                                                    creationRole.moduleAction = handleCheckBoxChange(
                                                        e.target.checked,
                                                        modules[module].module.id,
                                                        row.idAction,
                                                        creationRole
                                                    )
                                                    trackEvent({
                                                        funComponent: 'Checkbox-Module-Action',
                                                        event:
                                                            'Checkbox--Module-' + modules[module].module.id + '-Action-' + row.idAction + '-checked'
                                                    })
                                                }}
                                            ></Checkbox>
                                        )
                                }
                            }
                        })
                    } else {
                        if (sousModules !== null && sousModules.length > 0) {
                            sousModules.forEach((sousModule) => {
                                let actions = sousModule.actions
                                if (actions !== null && actions.length > 0) {
                                    actions.forEach((action) => {
                                        actionsIds.push(action.id)
                                    })
                                }
                                sousCol.push({
                                    title: sousModule.libelle,
                                    ellipsis: true,
                                    dataIndex: getModuleEnumByLabel(sousModule.libelle),
                                    key: sousModule.id,
                                    render: (text, row) => {
                                        if (getModuleRow(row, getModuleEnumByLabel(sousModule.libelle))?.hasCurrentAction) {
                                            if (actionsIds.includes(row.idAction))
                                                return (
                                                    <Checkbox
                                                        id={sousModule.id + '-' + row.idAction}
                                                        onChange={(e) => {
                                                            creationRole.moduleAction = handleCheckBoxChange(
                                                                e.target.checked,
                                                                sousModule.id,
                                                                row.idAction,
                                                                creationRole
                                                            )
                                                            trackEvent({
                                                                funComponent: 'Checkbox-Module-Action',
                                                                event: 'Checkbox--Module-' + sousModule.id + '-Action-' + row.idAction + '-checked'
                                                            })
                                                        }}
                                                    ></Checkbox>
                                                )
                                        }
                                    }
                                })
                            })
                        }
                    }
                    column.push({
                        title: moduleName,
                        key: modules[module].module.id,
                        ellipsis: true,
                        children: sousCol
                    })
                }
            })
        }
    }

    const handleCreateRole = async (roleData) => {
        roleData.moduleAction = getModuleAction()
        if (roleData.libelle === '' || roleData.idEntite === '') {
            if (roleData.moduleAction.length === 0) {
                message.warning('Veuillez remplir les champs rôle et entité')
            } else {
                message.warning('Veuillez remplir à nouveau les champs rôle et entité')
            }
        } else {
            message.loading('La création du profil est en cours', 1.5)
            RoleService.createRole(roleData)
                .then(() => {
                    message.destroy()
                    message.success('Le rôle a été créé avec succès')
                    roleData.libelle = ''
                    roleData.idEntite = ''
                    roleData.moduleAction = []
                    trackEvent({
                        funComponent: 'Create-Profil',
                        event: 'Create-Profil-sendData'
                    })
                    window.location.reload()
                })
                .catch((err) => {
                    trackEvent({
                        funComponent: 'Create-Profil',
                        event: 'Create-Profil-sendData',
                        status: JSON.stringify(err.response?.data.status)
                    })
                    message.destroy()
                    message.error(err.response.data.message)
                })
        }
    }
    return (
        <div>
            <h1 style={{ fontWeight: 700 }}>Créer un rôle</h1>
            <br></br>
            <div className="ant-row">
                <div className="ant-col ant-col-xs-24 ant-col-xl-10" style={{ display: 'flex' }}>
                    <Button
                        style={{
                            backgroundColor: secondaryColor ? secondaryColor : '#3CB371',
                            color: 'white',
                            width: '70px'
                        }}
                        size="large"
                        disabled
                    >
                        Entité
                    </Button>
                    <Select
                        id="role-entity"
                        datasource={userSubordinateEntities}
                        bordered={false}
                        allowClear
                        placeholder="Sélectionner"
                        onChange={(val) => {
                            setRoleEntite(val)
                            trackEvent({
                                funComponent: 'Role-Entity',
                                event: 'Role-Entity-' + val + '-choosed'
                            })
                        }}
                        style={{ width: '80%', marginBottom: '-2px' }}
                    >
                        {userSubordinateEntities.map((niveau) => (
                            <Select.Option id={niveau.libelle} key={niveau.id} value={niveau.id}>
                                {niveau.libelle}
                            </Select.Option>
                        ))}
                    </Select>
                </div>
                <div className="ant-col ant-col-xs-24 ant-col-xl-10" style={{ display: 'flex' }}>
                    <Button
                        style={{
                            backgroundColor: secondaryColor ? secondaryColor : '#3CB371',
                            color: 'white',
                            width: '70px'
                        }}
                        size="large"
                        disabled
                    >
                        Rôle
                    </Button>
                    <Input
                        id="role-name"
                        bordered={false}
                        onChange={(val) => setRoleLibelle(val.target.value)}
                        placeholder="Nom du role à créer"
                        style={{ width: '80%', marginBottom: '30px' }}
                    />
                </div>

                <br />
                <br />
                <br />
                <div className="ant-col ant-col-xs-24 ant-col-xl-2">
                    <Button
                        id="btn-creer-role"
                        style={{
                            backgroundColor: secondaryColor ? secondaryColor : '#3CB371',
                            color: 'white'
                        }}
                        onClick={async () => {
                            creationRole.libelle = roleLibelle
                            creationRole.idEntite = roleEntite
                            await handleCreateRole(creationRole)
                            props.reloadRoleList()
                            trackEvent({
                                funComponent: 'Role-Name',
                                event: 'Role-Name-' + roleLibelle + '-puted'
                            })
                            trackEvent({
                                funComponent: 'Button-Cree-Role',
                                event: 'Button-Cree-Role-clicked'
                            })
                        }}
                        size="large"
                    >
                        Créer
                    </Button>
                </div>
            </div>
            <br />
            <Table id="table-role" columns={column} dataSource={roleTableData} pagination={false} bordered size="small" scroll={{ x: 120 }} />
        </div>
    )
}

export default CreateRole
