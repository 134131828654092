import { createReducer } from '@reduxjs/toolkit'

const initialState = {
    roles: {
        loading: false,
        data: [],
        error: null
    },
    users: {
        loading: false,
        data: [],
        error: ''
    },
    statuses: {
        loading: false,
        data: [],
        error: ''
    },
    userByUUID: {
        loading: false,
        data: {},
        error: ''
    },
    usersByRole: {
        loading: false,
        data: [],
        error: ''
    },
    reports: {
        loading: false,
        data: [],
        error: ''
    },
    collaborationTypes: {
        loading: false,
        data: [],
        error: ''
    }
}

export const userReducer = createReducer(initialState, {
    FETCH_USERS_REQUEST: (state) => {
        state.users.loading = true
    },
    FETCH_USERS_SUCCESS: (state, action) => {
        state.users = {
            loading: false,
            data: action.payload,
            error: ''
        }
    },
    FETCH_USERS_FAILURE: (state, action) => {
        state.users = {
            loading: false,
            data: [],
            error: action.payload
        }
    },
    FETCH_USER_BY_UUID_REQUEST: (state) => {
        state.userByUUID.loading = true
    },
    FETCH_USER_BY_UUID_SUCCESS: (state, action) => {
        state.userByUUID = {
            loading: false,
            data: action.payload,
            error: ''
        }
    },
    FETCH_USER_BY_UUID_FAILURE: (state, action) => {
        state.userByUUID = {
            loading: false,
            data: {},
            error: action.payload
        }
    },
    FETCH_USERS_BY_ROLE_REQUEST: (state) => {
        state.usersByRole.loading = true
    },
    FETCH_USERS_BY_ROLE_SUCCESS: (state, action) => {
        state.usersByRole = {
            loading: false,
            data: action.payload,
            error: ''
        }
    },
    FETCH_USERS_BY_ROLE_FAILURE: (state, action) => {
        state.usersByRole = {
            loading: false,
            data: {},
            error: action.payload
        }
    },
    FETCH_USER_REPORTS_REQUEST: (state) => {
        state.reports.loading = true
    },
    FETCH_USER_REPORTS_SUCCESS: (state, action) => {
        state.reports = {
            loading: false,
            data: action.payload,
            error: ''
        }
    },
    FETCH_USER_REPORTS_FAILURE: (state, action) => {
        state.reports = {
            loading: false,
            data: [],
            error: action.payload
        }
    },
    FETCH_COLLABORATION_TYPES_REQUEST: (state) => {
        state.collaborationTypes.loading = true
    },
    FETCH_COLLABORATION_TYPES_SUCCESS: (state, action) => {
        state.collaborationTypes = {
            loading: false,
            data: action.payload,
            error: ''
        }
    },
    FETCH_COLLABORATION_TYPES_FAILURE: (state, action) => {
        state.collaborationTypes = {
            loading: false,
            data: [],
            error: action.payload
        }
    },
    FETCH_USER_STATUSES_REQUEST: (state) => {
        state.statuses.loading = true
    },
    FETCH_USER_STATUSES_SUCCESS: (state, action) => {
        state.statuses = {
            loading: false,
            data: action.payload,
            error: ''
        }
    },
    FETCH_USER_STATUSES_FAILURE: (state, action) => {
        state.statuses = {
            loading: false,
            data: [],
            error: action.payload
        }
    },
    FETCH_ROLES_REQUEST: (state) => {
        state.roles.loading = true
    },
    FETCH_ROLES_SUCCESS: (state, action) => {
        state.roles = {
            loading: false,
            data: action.payload,
            error: ''
        }
    },
    FETCH_ROLES_FAILURE: (state, action) => {
        state.roles = {
            loading: false,
            data: [],
            error: action.payload
        }
    }
})
