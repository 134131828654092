import { Descriptions, Badge, Button,Col, Row  } from 'antd'
import { EyeOutlined } from '@ant-design/icons'
import React, { useState } from 'react'

import '../../static/less/app.less'
import RecepisseAdditionalInfo from 'components/Modals/RecepisseAdditionalInfo'

const secondaryColor = localStorage.getItem('secondary_color')

const RecepDetails = (props) => {
    const numAdditionnalInfo = props.detailsAdditionalInfo.length
    const text = numAdditionnalInfo > 0 ? 'Informations additionnelles trouvés' : 'Aucune information additionnelle'
    const color = '#22f59d' /* '#f5222d' */
    const status = 'processing' // processing corresponds to error

    const [showModal, setShowModal] = useState(false)

    return (
        <div id="Recepisse details">
            <Descriptions
                title={'Détail du récépissé Numero : ' + props.recepisseId}
                labelStyle={{ color: 'white', backgroundColor: secondaryColor ? secondaryColor : '#3CB371' }}
                size="large"
                extra={
                    numAdditionnalInfo > 0 ? (
                        <Button
                            id="btn-reinitialiser"
                            shape="round"
                            onClick={() => {
                                setShowModal(true)
                            }}
                        >
                            <Badge 
                                size="default" 
                                status={status} 
                                text={text} 
                                showZero={true} 
                                color={color} /> 
                        </Button>
                    ) : null
                }
                column={3}
                bordered
            >
                <Descriptions.Item 
                    label="Expéditeur">
                    {props.details.emetteur}
                </Descriptions.Item>
                <Descriptions.Item 
                    label="Destinataire">
                    {props.details.destinataire}
                </Descriptions.Item>
                <Descriptions.Item label="Nbre d'UM">{props.details.nb_Um}</Descriptions.Item>
                <Descriptions.Item label="Pays | CP | Ville">
                    {props.details.pays_Emet} | {props.details.cp_Emet} | {props.details.ville_Emet}
                </Descriptions.Item>
                <Descriptions.Item label="Pays | CP | Ville">
                    {props.details.pays_Dest} | {props.details.cp_Dest} | {props.details.ville_Dest}
                </Descriptions.Item>
                <Descriptions.Item label="Poids">{`${props.details.path_Weight} ${props.details.path_WeightUnit}`}</Descriptions.Item>
                <Descriptions.Item label="Adresse">{props.details.adresse_Emet}</Descriptions.Item>
                <Descriptions.Item label="Adresse">{props.details.adresse_Dest}</Descriptions.Item>
                <Descriptions.Item label="Régime">{props.details.regime !== null ? props.details.regime : '-'}</Descriptions.Item>
            </Descriptions>
            <RecepisseAdditionalInfo showModal={showModal} setShowModal={setShowModal} info={props.detailsAdditionalInfo} />
        </div>
    )
}

export default RecepDetails
