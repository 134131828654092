import UserService from 'services/UserService'
import RoleService from 'services/RoleService'

let moduleActionList = []
export const handleCheckBoxChange = (isChecked, moduleId, actionId, roleData) => {
    if (isChecked === true) {
        let result = { idModule: moduleId, idAction: actionId }
        roleData.moduleAction.push(result)
    } else {
        let filtredModuleAction = roleData.moduleAction.filter((i) => {
            if (i.idModule === moduleId && i.idAction === actionId) return false
            return true
        })
        roleData.moduleAction = filtredModuleAction
    }
    moduleActionList = roleData.moduleAction
    return roleData.moduleAction
}
export const getModuleAction = () => {
    return moduleActionList
}
export const getUserPermissions = (userId, setResultTableCallback) => {
    let userProfileId = -1
    return UserService.getUserInfo(userId).then((res) => {
        userProfileId = res.data.authorities[0].id
        RoleService.getUserPermissions(userProfileId).then((res) => {
            const result = res.data.map((action) => {
                const actionWithKey = action
                actionWithKey.key = action.idAction
                return actionWithKey
            })
            setResultTableCallback(result)
        })
    })
}