import http from "../utilities/http-common"

const getUserInfo = (idUser) => {
    return http.get('/api/v1/users/' + idUser)
}

const getUserModuleAction = (idUser) => {
  return http.get('/api/v1/users/' + idUser + '/data')
};

const reinitialiserPassword = (idUser) =>{
  return http.patch('/api/v1/users/' + idUser + '/reset-password')
} 
const modifierUser = (updatedUser) => {
    return http.put('/api/v1/users/update', updatedUser)
}

const logOutUser = () => {
    return http.post('/api/v1/logout')
}

export default {
    getUserInfo,
    getUserModuleAction,
    reinitialiserPassword,
    modifierUser,
    logOutUser
}
