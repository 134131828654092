export const DEFAULT_FORM_LAYOUT = {
    labelCol: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 },
    wrapperCol: { xs: 24, sm: 24, md: 12, lg: 16, xl: 16 },
    layout: 'horizontal',
    labelAlign: 'left',
    size: 'large',
    colon: false
}

export const DEFAULT_FORM_LAYOUT_NO_LABEL = {
    wrapperCol: { span: 14, offset: 4 },
    layout: 'horizontal'
}

export const DEFAULT_FORM_LAYOUT_NO_LABEL_FULL = {
    wrapperCol: { span: 24 },
    layout: 'horizontal'
}

export const DEFAULT_FORM_LAYOUT_SMALL_LABEL = {
    ...DEFAULT_FORM_LAYOUT,
    labelCol: { xs: 24, sm: 24, md: 24, lg: 8, xl: 8 },
    ...{ wrapperCol: { xs: 24, sm: 24, md: 24, lg: 16, xl: 16 } },
    size: 'small'
}
