import React from 'react'
import { Table } from 'antd'

const TableSimple = ({data}) => {
    if (data !== undefined) {
        return (
            <Table
                id="table"
                columns={data.columns}
                size={data.size}
                dataSource={data.info}
                scroll={{ y: data.scroll, x: data.scrollX }}
                pagination={data.pagination}
            />
        )
    }
  };
  
  export default TableSimple;