import React, { useState } from 'react'
import '../../static/less/app.less'
import { Form, Button, Input, message } from 'antd'
import TicketingService from '../../services/TicketingService'
import track, { useTracking } from 'react-tracking'

const { TextArea } = Input
const TicketingForm = ({ getTickets, setTickets }) => {
    const { trackEvent } = useTracking()
    const [ticket, setTicket] = useState({
        title: '',
        article: {
            subject: '',
            body: ''
        },
        email: localStorage.getItem('email'),
        idEntite: localStorage.getItem('entite'),
        idHierarchie: localStorage.getItem('hierarchie'),
        idClient: localStorage.getItem('client')
    })

    const createTicket = () => {
        TicketingService.createTicket(ticket)
            .then((response) => {
                message.success(response.data)
                setTickets([])
                getTickets()
                trackEvent({
                    funComponent: 'Create-Ticket',
                    event: 'Create-Ticket-sendData'
                })
            })
            .catch((err) => {
                trackEvent({
                    funComponent: 'Create-Ticket',
                    event: 'Create-Ticket-sendData',
                    status: JSON.stringify(err.response?.data.status)
                })
                message.error('Impossible de créer le ticket')
            })
    }

    return (
        <>
            <Form
                labelCol={{
                    span: 4
                }}
                wrapperCol={{
                    span: 14
                }}
            >
                <Form.Item label="Titre" name="title" required>
                    <Input
                        id="ticket-title"
                        allowClear
                        onChange={(val) => {
                            setTicket((prevState) => ({
                                ...prevState,
                                title: val.target.value
                            }))
                            trackEvent({
                                funComponent: 'Ticket-Title',

                                event: 'Ticket-Title-' + val.target.value + '-puted'
                            })
                        }}
                    />
                </Form.Item>
                <Form.Item label="Sujet" name="subject">
                    <Input
                        id="ticket-sujet"
                        allowClear
                        onChange={(val) => {
                            setTicket((prevState) => ({
                                ...prevState,
                                article: { ...prevState.article, subject: val.target.value }
                            }))
                            trackEvent({
                                funComponent: 'Ticket-Sujet',

                                event: 'Ticket-Sujet-' + val.target.value + '-puted'
                            })
                        }}
                    />
                </Form.Item>
                <Form.Item label="Body" name="body" required>
                    <TextArea
                        id="ticket-body"
                        showCount
                        maxLength={300}
                        onChange={(val) => {
                            setTicket((prevState) => ({
                                ...prevState,
                                article: { ...prevState.article, body: val.target.value }
                            }))
                        }}
                    />
                </Form.Item>

                <Form.Item label=" " colon={false}>
                    <Button
                        type="primary"
                        id="btn-creer-ticket"
                        htmlType="submit"
                        onClick={() => {
                            createTicket()
                            trackEvent({
                                funComponent: 'Ticket-Body',

                                event: 'Ticket-Body-' + ticket.article.body + '-puted'
                            })
                            trackEvent({
                                funComponent: 'Button-Cree-Ticket',

                                event: 'Button-Cree-Ticket-clicked'
                            })
                        }}
                    >
                        Créer
                    </Button>
                </Form.Item>
            </Form>
        </>
    )
}
export default TicketingForm
