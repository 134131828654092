import constants, { ACCEPTED_DATE_FORMAT } from './constants'

import moment from 'moment'

export const objectIsEmptyOrNull = (obj) => {
    if (obj) {
        return Object.keys(obj).length === 0 && obj.constructor === Object
    }
    return true
}

export const getObjectKeys = (obj) => {
    if (obj !== null) {
        return Object.keys(obj)
    }
}

export const removeKey = (obj, prop) => {
    let { [prop]: omit, ...res } = obj
    return res
}

export const camelToWordCase = (txt) => {
    const result = txt.replace(/([A-Z])/g, ' $1')
    return result.charAt(0).toUpperCase() + result.slice(1)
}

export const formatStringToDate = (str, format) => {
    return moment(str, format)
}

export const formatDateToString = (date) => {
    return formatStringToDate(date, 'YYYY-MM-DD[T]HH:mm:ss')
}
export const toHumanDate = (str) => {
    return formatStringToDate(str).format(constants.DEFAULT_DATE_FORMAT)
}

export const extractUniqueKeyValueList = (objList, key) => {
    const arr = []

    if (objList && objList.length) {
        for (var obj of objList) {
            if (!arr.includes(obj[key])) {
                arr.push(obj[key])
            }
        }
    }
    return arr
}

export const arrayGroupByKey = (objList, el) => {
    if (objList !== null) {
        return objList.reduce((acc, curr) => {
            if (!acc[curr[el]]) acc[curr[el]] = []
            acc[curr[el]].push(curr)
            return acc
        }, {})
    }
}

export const extractIdFromUrl = (url) => {
    const splitedUrl = url.split('/')
    return splitedUrl[splitedUrl.length - 1]
}

export const shownValueColumn = (value) => {
    if (value !== null && value !== undefined) {
        const isDate = moment(value.toString().substr(0, 19), ACCEPTED_DATE_FORMAT, true).isValid()
        if (isDate) {
            return formatStringToDate(value.toString(), 'YYYY-MM-DD[T]HH:mm:ss').format('DD/MM/YYYY')
        }
        return value.toString()
    }
    return value
}

export const getTimestamp = () => {
    return Math.round(new Date().getTime() / 1000)
}

export const chunk = (arr, size) => {
    return Array.from({ length: Math.ceil(arr.length / size) }, (v, i) => arr.slice(i * size, i * size + size))
}
