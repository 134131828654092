import { CheckCircleTwoTone } from '@ant-design/icons'
import { Card, Form } from 'antd'
import React, { useEffect, useState } from 'react'
import '../../static/less/app.less'
import Highcharts from 'highcharts'
import highchartsMore from 'highcharts/highcharts-more.js'
import solidGauge from 'highcharts/modules/solid-gauge.js'
import { ChartBar } from 'components/Charts/ChartType'
import StatistiqueService from '../../services/StatistiqueService'
import { ChartPie } from 'components/Charts/ChartType'
import { TableSimple } from 'components/Tables'
import { SelectList } from 'components/Selects'
import { SelectButtonPeriod } from 'components/Selects'
import track, { useTracking } from 'react-tracking'

highchartsMore(Highcharts)
solidGauge(Highcharts)

const Statistique = () => {
    const { trackEvent } = useTracking()
    const [niveaux, setNiveaux] = useState([])
    const [periodeData, setPeriodeData] = useState([])
    const [tauxRemonteInfosLiv, setTauxRemonteInfosLiv] = useState([])
    const [tauxRemonteInfosEnl, setTauxRemonteInfosEnl] = useState([])
    const [idNiveau, setIdNiveau] = useState()
    const currentUserId = localStorage.getItem('id')

    const getEntities = () => {
        StatistiqueService.getEntities(currentUserId)
            .then((res) => {
                setNiveaux(res.data)
                trackEvent({
                    funComponent: 'List-Entities',
                    event: 'List-Entities-getData'
                })
            })
            .catch((err) => {
                trackEvent({
                    funComponent: 'List-Entities',
                    event: 'List-Entities-getData',
                    status: JSON.stringify(err.response?.data.status)
                })
            })
    }

    useEffect(() => {
        getEntities()
        document.title = 'Statistiques'
    }, [])

    const calculEnvol = (total, periodePrecedente) => {
        if (periodePrecedente.total === null || periodePrecedente.total === 0) {
            return '-'
        } else {
            return (total / periodePrecedente.total - 1).toFixed(2)
        }
    }

    const dataPeriode = periodeData.map((data) => ({
        key: data.periode,
        periode: data.periode,
        enl_Emis: data.enl_Emis,
        enl_Recues: data.enl_Recues,
        exp_Emis: data.exp_Emis,
        exp_Recues: data.exp_Recues,
        liv_Emis: data.liv_Emis,
        liv_Recues: data.liv_Recues,
        total_Emis: data.total_Emis,
        total_Recues: data.total_Recues,
        total: data.total,
        envol: data.periode === '4' ? '-' : calculEnvol(data.total, periodeData[parseInt(data.periode) + 1]),
        statut: <CheckCircleTwoTone twoToneColor="#52c41a" />
    }))
    const tauxRemonteDataLiv = tauxRemonteInfosLiv.map((data) => ({
        key: data.orientation,
        const: data.orientation,
        nbre: data.nbre_Total,
        aar: data.pourc_Step00 === null ? '-' : data.pourc_Step00 * 100 + '%',
        mlv: data.pourc_Step01 === null ? '-' : data.pourc_Step01 * 100 + '%',
        rt: data.pourc_Step02 === null ? '-' : data.pourc_Step02 * 100 + '%',
        autre: data.pourc_Step03 === null ? '-' : data.pourc_Step03 * 100 + '%'
    }))
    const tauxRemonteDataEnl = tauxRemonteInfosEnl.map((data) => ({
        key: data.orientation,
        const: data.orientation,
        nbre: data.nbre_Total,
        pcd: data.pourc_Step00 === null ? '-' : data.pourc_Step00 * 100 + '%',
        mee: data.pourc_Step01 === null ? '-' : data.pourc_Step01 * 100 + '%',
        pce: data.pourc_Step02 === null ? '-' : data.pourc_Step02 * 100 + '%',
        fpa: data.pourc_Step03 === null ? '-' : data.pourc_Step03 * 100 + '%'
    }))

    const configChartLivEmis = {
        text: 'Liv Emis',
        names: ['AAR', 'MLV', 'RT', 'Autre'],
        taux: tauxRemonteInfosLiv,
        number: 0
    }

    const configChartLivRecues = {
        text: 'Liv Reçus',
        names: ['AAR', 'MLV', 'RT', 'Autre'],
        taux: tauxRemonteInfosLiv,
        number: 1
    }

    const configChartEnlEmis = {
        text: 'Enl Emis',
        names: ['PCD', 'MEE', 'PCE', 'FPA'],
        taux: tauxRemonteInfosEnl,
        number: 0
    }

    const configChartEnlRecues = {
        text: 'Enl Reçus',
        names: ['PCD', 'MEE', 'PCE', 'FPA'],
        taux: tauxRemonteInfosEnl,
        number: 1
    }

    const configTableVolumeActivite = {
        columns: [
            {
                title: 'Période',
                dataIndex: 'periode',
                width: '12%',
                render(text) {
                    return {
                        props: {
                            style: { background: '#fafafa' }
                        },
                        children: <div>{text}</div>
                    }
                }
            },
            {
                title: 'Enlévement',
                dataIndex: 'enlevement',
                children: [
                    {
                        title: 'Reçu',
                        dataIndex: 'enl_Recues'
                    },
                    {
                        title: 'Emis',
                        dataIndex: 'enl_Emis'
                    }
                ]
            },
            {
                title: 'Expédition',
                dataIndex: 'expedition',
                children: [
                    {
                        title: 'Reçu',
                        dataIndex: 'exp_Recues'
                    },
                    {
                        title: 'Emis',
                        dataIndex: 'exp_Emis'
                    }
                ]
            },
            {
                title: 'Livraison',
                dataIndex: 'livraison',
                children: [
                    {
                        title: 'Reçu',
                        dataIndex: 'liv_Recues'
                    },
                    {
                        title: 'Emis',
                        dataIndex: 'liv_Emis'
                    }
                ]
            },
            {
                title: 'TOTAL',
                dataIndex: 'total',
                children: [
                    {
                        title: 'Reçu',
                        dataIndex: 'total_Recues'
                    },
                    {
                        title: 'Emis',
                        dataIndex: 'total_Emis'
                    }
                ]
            },
            {
                title: 'Total',
                dataIndex: 'total'
            },
            {
                title: 'Envol.',
                dataIndex: 'envol'
            }
        ],
        info: dataPeriode,
        scroll: 240,
        pagination: false,
        size: 'small'
    }

    const configTableEnl = {
        columns: [
            {
                title: '',
                dataIndex: 'const',
                width: '12%',
                render(text) {
                    return {
                        props: {
                            style: { background: '#fafafa' }
                        },
                        children: <div style={{ textTransform: 'uppercase' }}> {text}</div>
                    }
                }
            },
            {
                title: 'Nbre',
                dataIndex: 'nbre'
            },
            {
                title: 'PCD',
                dataIndex: 'pcd'
            },
            {
                title: 'MEE',
                dataIndex: 'mee'
            },
            {
                title: 'PCE',
                dataIndex: 'pce'
            },
            {
                title: 'FPA',
                dataIndex: 'fpa'
            }
        ],
        info: tauxRemonteDataEnl,
        scroll: 180,
        pagination: false,
        size: 'small'
    }

    const configTableLiv = {
        columns: [
            {
                title: '',
                dataIndex: 'const',
                width: '12%',
                render(text, record) {
                    return {
                        props: {
                            style: { background: '#fafafa' }
                        },
                        children: <div style={{ textTransform: 'uppercase' }}> {text}</div>
                    }
                }
            },
            {
                title: 'Nbre',
                dataIndex: 'nbre'
            },
            {
                title: 'AAR',
                dataIndex: 'aar'
            },
            {
                title: 'MLV',
                dataIndex: 'mlv'
            },
            {
                title: 'RT',
                dataIndex: 'rt'
            },
            {
                title: 'Autre',
                dataIndex: 'autre'
            }
        ],
        info: tauxRemonteDataLiv,
        scroll: 180,
        pagination: false,
        size: 'small'
    }

    const configListSelectNiveau = {
        required: true,
        message: "Choisissez l'Entité",
        label: 'Entité',
        placeholder: 'Sélectionner',
        niveaux: niveaux
    }

    const configButtonSelectPeriode = {
        label: 'Periode',
        idNiveau: idNiveau
    }

    const changeIdNiveau = (val) => {
        setIdNiveau(val)
    }

    const changePeriodeData = (val) => {
        setPeriodeData(val)
    }

    const changeTauxRemonteInfosEnl = (val) => {
        setTauxRemonteInfosEnl(val)
    }

    const changeTauxRemonteInfosLiv = (val) => {
        setTauxRemonteInfosLiv(val)
    }

    return (
        <>
            <br />
            <Form>
                <Card>
                    <div className="ant-row">
                        <div className="ant-col ant-col-xs-10 ant-col-xl-6">
                            <SelectList returnedResult={changeIdNiveau} data={configListSelectNiveau} />
                        </div>
                        <div id="periodDetail" className="ant-col ant-col-xs-10 ant-col-xl-18">
                            <SelectButtonPeriod
                                data={configButtonSelectPeriode}
                                dataTauxRemonteInfosEnl={changeTauxRemonteInfosEnl}
                                dataTauxRemonteInfosLiv={changeTauxRemonteInfosLiv}
                                dataPeriodeData={changePeriodeData}
                            />
                        </div>
                    </div>
                </Card>
            </Form>
            <Card>
                <Card>
                    <div className="ant-row">
                        <div className="ant-col ant-col-xs-24 ant-col-xl-12">
                            <br />
                            <br />
                            <br />
                            <TableSimple data={configTableVolumeActivite} />
                        </div>

                        <div className="ant-col ant-col-xs-24 ant-col-xl-12">
                            <ChartBar periodeData={periodeData} />
                        </div>
                    </div>
                </Card>
                <br />
                <Card>
                    <div className="ant-row">
                        <div className="ant-col ant-col-xs-24 ant-col-xl-12">
                            <br />
                            <br />
                            <TableSimple data={configTableEnl} />
                        </div>

                        <div className="ant-col ant-col-xs-24 ant-col-xl-5">
                            <ChartPie data={configChartEnlEmis} />
                        </div>

                        <div className="ant-col ant-col-xs-24 ant-col-xl-5">
                            <ChartPie data={configChartEnlRecues} />
                        </div>
                    </div>
                </Card>
                <br />
                <Card>
                    <div className="ant-row">
                        <div className="ant-col ant-col-xs-24 ant-col-xl-12">
                            <br />
                            <br />
                            <TableSimple data={configTableLiv} />
                        </div>

                        <div className="ant-col ant-col-xs-24 ant-col-xl-5">
                            <ChartPie data={configChartLivEmis} />
                        </div>

                        <div className="ant-col ant-col-xs-24 ant-col-xl-5">
                            <ChartPie data={configChartLivRecues} />
                        </div>
                    </div>
                </Card>
            </Card>
        </>
    )
}

export { Statistique }
