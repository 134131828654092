import StackedBarChart from 'components/Charts/StackedBar'
import React from 'react'

const getChartData = (periodeData, dataType) => {
    let result = []

    periodeData.forEach((data) => {
        result.push(data[dataType])
    })

    return result
}

const configChartBar = (periodeData) => { 

    return {
        chart: {
            type: 'column'
        },

        title: {
            text: "Volume d'activité pour les 5 dernières périodes"
        },

        xAxis: {
            categories: ['0', '1', '2', '3', '4']
        },

        yAxis: {
            allowDecimals: false,
            min: 0,
            title: {
                text: "Nombres d'ordres"
            }
        },

        tooltip: {
            formatter: function () {
                return `<b> ${this.x} </b><br/> ${this.series.name} : ${this.y} <br/> Total : ${this.point.stackTotal}`
            }
        },

        plotOptions: {
            column: {
                stacking: 'normal'
            }
        },

        series: [
            {
                name: 'ENL Emis',
                data: getChartData(periodeData, 'enl_Emis'),
                stack: 'enl'
            },
            {
                name: 'ENL Reçus',
                data: getChartData(periodeData, 'enl_Recues'),
                stack: 'enl'
            },
            {
                name: 'EXP Emis',
                data: getChartData(periodeData, 'exp_Emis'),
                stack: 'exp'
            },
            {
                name: 'EXP Reçus',
                data: getChartData(periodeData, 'exp_Recues'),
                stack: 'exp'
            },
            {
                name: 'LIV Emis',
                data: getChartData(periodeData, 'liv_Emis'),
                stack: 'liv'
            },
            {
                name: 'LIV Reçus',
                data: getChartData(periodeData, 'liv_Recues'),
                stack: 'liv'
            }
        ]
    }};

const ChartBar = ({periodeData}) => {
    const configChartBarResult = configChartBar(periodeData);
    return (
        <>
        <br />
        <div>
            <StackedBarChart config={configChartBarResult} />
        </div>
        <br />
    </>
    );
  };
  
  export default ChartBar;