import { ADD_ALERT, EMPTY_ALERTS, REMOVE_ALERT_BY_URL } from '../actionTypes'

import { getTimestamp } from 'utilities/core'

const initialState = []

export default function (state = initialState, action) {
    switch (action.type) {
        case ADD_ALERT: {
            const { payload } = action
            return [...state, { ...payload, ts: getTimestamp() }]
        }
        case REMOVE_ALERT_BY_URL: {
            const { payload } = action
            return [...state.filter((a) => a.url !== payload)]
        }
        case EMPTY_ALERTS: {
            return []
        }
        default:
            return state
    }
}
