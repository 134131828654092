import http from "../utilities/http-common"

const getperiode = (niveau, periode) => {
    return http.get('/api/v1/stats/volume-activite/' + niveau + '/periode/' + periode)
}

const getUserPeriodData = (idUser, periode) => {
    return http.get('/api/v1/stats/volume-activite/user/' + idUser + '/periode/' + periode)
}

const getEntities = (currentUserId) => {
    return http.get('/api/v1/users/' + currentUserId + '/entities')
}

const getTauxRemonteInfosEnl = (idNiveau, value) => {
    return http.get('/api/v1/stats/taux-remontees-info/' + idNiveau + '/' + value + '/ENL')
}

const getTauxRemonteInfosLiv = (idNiveau, value) => {
    return http.get('/api/v1/stats/taux-remontees-info/' + idNiveau + '/' + value + '/LIV')
}

export default {
    getperiode,
    getUserPeriodData,
    getEntities,
    getTauxRemonteInfosEnl,
    getTauxRemonteInfosLiv
}