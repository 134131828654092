const strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})')
const mediumRegex = new RegExp('^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})')

export const analyze = (event) => {
    if (strongRegex.test(event.target.value)) {
        return '#0F9D58'
    } else if (mediumRegex.test(event.target.value)) {
        return '#F4B400'
    } else {
        return '#DB4437'
    }
}
export const validerPassword = (value) => {
    if (strongRegex.test(value)) {
        return true
    } else {
        return false
    }
}
