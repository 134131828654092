import React, { useEffect } from 'react'
import { Form, Select } from 'antd'
import track, { useTracking } from 'react-tracking'

const SelectList = ({ data, returnedResult }) => {
    const { trackEvent } = useTracking()
    useEffect(() => {
        if (data.niveaux[0] !== undefined) {
            returnedResult(data.niveaux[0].id)
        }
    }, [data.niveaux[0]])

    return (
        <Form.Item
            rules={[
                {
                    required: data.required,
                    message: data.message
                }
            ]}
            label={<label style={{ fontSize: '16px' }}>{data.label}</label>}
            name="niveau"
        >
            <Select
                key={`libelle:${data.niveaux[0]?.id || data.niveaux[0]?.id}`}
                defaultValue={'' || data.niveaux[0]?.libelle}
                style={{ width: '100%' }}
                placeholder={data.placeholder}
                onChange={(val) => {
                    returnedResult(val)
                    trackEvent({
                        funComponent: 'Select-Entity',

                        event: 'Select-Entity-Id-' + val + '-choosed'
                    })
                }}
            >
                {data.niveaux.map((entite) => (
                    <Select.Option key={entite.id} value={entite.id}>
                        {entite.libelle}
                    </Select.Option>
                ))}
            </Select>
        </Form.Item>
    )
}

export default SelectList
