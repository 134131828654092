import http from '../utilities/http-common'

const getWidgetByIdUser = (idUser) => {
    return http.get('/api/v1/widget/' + idUser)
}
const updateWidget = (widget) => {
    return http.post('/api/v1/widget/update', widget)
}

export default {
    getWidgetByIdUser,
    updateWidget
}
