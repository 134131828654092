import React from 'react'
import { InfoCircleOutlined } from '@ant-design/icons'
import { Table, Modal, Checkbox, message, Button } from 'antd'
import { getUserPermissions, handleCheckBoxChange, getModuleAction } from './RoleProvider'
import RoleService from 'services/RoleService'
import { getModuleEnumByLabel, getAllowedAuthorities, getRoleAuthorities } from '../../../constants/enums/moduleEnum'
import track, { useTracking } from 'react-tracking'

const ModifyRole = (profileId, targetRoleAuthorities, visibleUpdateRole, setVisibleUpdateRole, setModale, trackEvent) => {
    const creationRole = { libelle: '', idEntite: '', moduleAction: [] }
    const currentUserId = localStorage.getItem('id')
    let modules = JSON.parse(window.localStorage.getItem('modules'))
    const column = [
        {
            title: '',
            ellipsis: true,
            key: 'idAction',
            dataIndex: 'libelleAction',
            width: 90,
            fixed: 'left',
            render(text, row) {
                return {
                    props: {
                        style: { background: '#fafafa' }
                    },
                    children: <div style={{ fontWeight: 500 }}>{row.allowedAuthorities.libelleAction}</div>
                }
            }
        }
    ]

    const makeColumns = () => {
        if (modules !== undefined && modules !== null) {
            Object.keys(modules).forEach((module) => {
                const sousCol = []
                let sousModules = modules[module].sousModule
                if (modules[module].module !== undefined) {
                    let moduleName = modules[module].module.libelle
                    if (moduleName === 'Retention') {
                        sousCol.push({
                            title: '',
                            ellipsis: true,
                            dataIndex: getModuleEnumByLabel('Retention'),
                            key: modules[module].module.id,
                            render: (text, row) => {
                                if (getAllowedAuthorities(row, getModuleEnumByLabel('Retention'))?.hasCurrentAction) {
                                    return (
                                        <Checkbox
                                            id={'modif-'+modules[module].module.id + '-' + row.allowedAuthorities.idAction}
                                            defaultChecked={getRoleAuthorities(row, getModuleEnumByLabel('Retention'))?.hasCurrentAction}
                                            onChange={(e) => {
                                                creationRole.moduleAction = handleCheckBoxChange(
                                                    e.target.checked,
                                                    modules[module].module.id,
                                                    row.allowedAuthorities.idAction,
                                                    creationRole
                                                )
                                                trackEvent({
                                                    funComponent: 'Checkbox-Module-Action',
                                                    event:
                                                        'Checkbox--Module-' +
                                                        modules[module].module.id +
                                                        '-Action-' +
                                                        row.allowedAuthorities.idAction +
                                                        '-checked'
                                                })
                                            }}
                                        ></Checkbox>
                                    )
                                }
                            }
                        })
                    } else {
                        if (sousModules !== null && sousModules.length > 0) {
                            sousModules.forEach((sousModule) => {
                                sousCol.push({
                                    title: sousModule.libelle,
                                    ellipsis: true,
                                    dataIndex: getModuleEnumByLabel(sousModule.libelle),
                                    key: sousModule.id,
                                    render: (text, row) => {
                                        if (getAllowedAuthorities(row, getModuleEnumByLabel(sousModule.libelle))?.hasCurrentAction) {
                                            return (
                                                <Checkbox
                                                    id={'modif-'+sousModule.id + '-' + row.allowedAuthorities.idAction}
                                                    defaultChecked={
                                                        getRoleAuthorities(row, getModuleEnumByLabel(sousModule.libelle))?.hasCurrentAction
                                                    }
                                                    onChange={(e) => {
                                                        creationRole.moduleAction = handleCheckBoxChange(
                                                            e.target.checked,
                                                            sousModule.id,
                                                            row.allowedAuthorities.idAction,
                                                            creationRole
                                                        )
                                                        trackEvent({
                                                            funComponent: 'Checkbox-Module-Action',
                                                            event:
                                                                'Checkbox--Module-' +
                                                                sousModule.id +
                                                                '-Action-' +
                                                                row.allowedAuthorities.idAction +
                                                                '-checked'
                                                        })
                                                    }}
                                                ></Checkbox>
                                            )
                                        }
                                    }
                                })
                            })
                        }
                    }
                    column.push({
                        title: moduleName,
                        key: modules[module].module.id,
                        ellipsis: true,
                        children: sousCol
                    })
                }
            })
        }
        return column
    }

    const getModuleActionIdListByMapping = (userPermisssions) => {
        const moduleActionIdList = []
        for (let obj of userPermisssions) {
            for (let columnName in obj) {
                if (columnName !== 'libelleAction' && columnName !== 'idAction' && obj[columnName].hasCurrentAction === true) {
                    moduleActionIdList.push({ idModule: obj[columnName].idModule, idAction: obj.idAction })
                }
            }
        }
        creationRole.moduleAction = moduleActionIdList
    }

    const handleRoleModification = async (profileId, newProfile) => {
        newProfile.moduleAction = getModuleAction()
        RoleService.modifierRole(profileId, newProfile).then(() => {
            message.loading('Action in progress..', 1.5).then(() => {
                message.success('Le rôle a été modifié avec succès')
            })
        })
        setVisibleUpdateRole(false)
        setModale([])
    }

    const setCurrentUserAuthorities = (currentUserAuthorities) => {
        getModuleActionIdListByMapping(targetRoleAuthorities)
        let roleModifObj = []
        for (let i = 0; i < targetRoleAuthorities.length; i++) {
            roleModifObj.push({ roleAuthorities: targetRoleAuthorities[i], allowedAuthorities: currentUserAuthorities[i] })
        }
        setModale(
            <Modal
                width="1000"
                onCancel={cancelUpdateRole}
                visible={visibleUpdateRole}
                title="Modification du rôle :"
                icon={<InfoCircleOutlined />}
                footer={[
                    <Button key="back" id={'Button-annuler-updateRole'} onClick={cancelUpdateRole}>
                        Annuler
                    </Button>,
                    <Button id={'Button-modifier-updateRole'} onClick={() => handleRoleModification(profileId, creationRole)}>
                        Modifier
                    </Button>
                ]}
            >
                <div>
                    <br />
                    <Table columns={makeColumns()} dataSource={roleModifObj} pagination={false} bordered size="small" />
                </div>
            </Modal>
        )
    }

    const cancelUpdateRole = () => {
        creationRole.moduleAction = []
        setVisibleUpdateRole(false)
        setModale([])
    }

    getUserPermissions(currentUserId, setCurrentUserAuthorities)

}

export default ModifyRole
