import { Card, Tabs, Divider, Select, Col, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import Cookies from 'js-cookie'
import '../../static/less/app.less'
import EchangesSearchForm from '../../components/Echanges/EchangesSearchForm'
import RecepTable from '../../components/Echanges/RecepTable'
import ReportTable from '../../components/Echanges/ReportTable'
import RecepDetails from '../../components/Echanges/RecepDetails'
import EchangeService from 'services/EchangeService'
import { hasActionByModule } from 'utilities/modules'
import track, { useTracking } from 'react-tracking'

const { Option } = Select
const { TabPane } = Tabs

const RechercheRecep = () => {
    const { trackEvent } = useTracking()
    const [researchedData, setSearchedData] = useState([])
    const [showDetail, setShowDetail] = useState(false)
    const [recepisseId, setRecepisseId] = useState()
    const [recepisseEvent, setrecepisseEvent] = useState([])
    const [reportPointage, setReportPointage] = useState([])
    const [details, setDetail] = useState([])
    const [detailsAdditionalInfo, setDetailsAdditionalInfo] = useState([])
    const [steps, setSteps] = useState([])
    const nbRowPreference = Cookies.get('nbRow') !== undefined ? Cookies.get('nbRow') : 10
    const [nbRow, setNbRow] = useState(nbRowPreference)

    document.title = 'Recherche Récépissé'
    useEffect(() => {
        setShowDetail(false)
        setDetail([])
    }, [researchedData])

    return hasActionByModule('Recherche récépissés', 'Lister') ? (
        <>
            <EchangesSearchForm setSearchedData={setSearchedData} fetchCallback={EchangeService.getUserRecepisses} setSteps={setSteps} />
            <Divider />
            <Card>
                <Row gutter={{ xs: 1, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row">
                        <div>Nombre de lignes: </div>
                    </Col>
                    <Col className="gutter-row">
                        <Select
                            style={{ width: '60px' }}
                            defaultValue={nbRow}
                            onChange={(e) => {
                                Cookies.set('nbRow', e)
                                setNbRow(e)
                            }}
                        >
                            <Option value="5">5</Option>
                            <Option value="10">10</Option>
                            <Option value="25">25</Option>
                            <Option value="50">50</Option>
                        </Select>
                    </Col>
                </Row>
            </Card>
            <Card>
                <RecepTable
                    data={researchedData}
                    steps={steps}
                    setShowDetail={setShowDetail}
                    setRecepisseId={setRecepisseId}
                    setrecepisseEvent={setrecepisseEvent}
                    setReportPointage={setReportPointage}
                    setDetail={setDetail}
                    setDetailsAdditionalInfo={setDetailsAdditionalInfo}
                    isRecepisse={true}
                    numRows={nbRow}
                />
            </Card>
            <br />

            {showDetail ? (
                <Card>
                    <RecepDetails details={details} recepisseId={recepisseId} detailsAdditionalInfo={detailsAdditionalInfo} />
                    <br />
                    <br />
                    <Tabs
                        type="card"
                        onSelect={(key) => {
                            trackEvent({
                                funComponent: 'Tab',
                                event: 'Tab-' + key.key + '-clicked'
                            })
                        }}
                    >
                        <TabPane tab="Report(s) de suivi" key="Report(s) de suivi">
                            <ReportTable data={recepisseEvent} />
                        </TabPane>
                        <TabPane tab="Report(s) de pointage" key="Report(s) de pointage">
                            <ReportTable data={reportPointage} />
                        </TabPane>
                    </Tabs>
                </Card>
            ) : null}
            <div id="details"></div>
        </>
    ) : (
        "vous ne pouvez pas lister les ressources, veuillez contacter l'administrateur"
    )
}

export default RechercheRecep
