import React, { useState, useEffect } from 'react'
import 'antd/dist/antd.less'
import { TableSimple } from '.'
import { getColumnSearchProps } from '../../utilities/table-utils'
import { SearchOutlined } from '@ant-design/icons'
import TicketingService from '../../services/TicketingService'
import { Button } from 'antd'
import DetailTicket from '../Modals/DetailTicket'
import track, { useTracking } from 'react-tracking'

const TicketsTable = ({ getTickets, tickets }) => {
    const { trackEvent } = useTracking()
    const [searchText, setSearchText] = useState('')
    const [searchedColumn, setSearchedColumn] = useState('')
    const [visible, setVisible] = useState(false)
    const [article, setArticle] = useState({ idTicket: '', from: '', body: '' })
    const [rec, setRec] = useState([])
    const [articles, setArticles] = useState([])

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
    }

    const handleReset = (clearFilters) => {
        clearFilters()
        setSearchText('')
    }
    const configTicketsTable = {
        columns: [
            {
                id: 'number',
                title: 'Numero',
                dataIndex: 'number',
                ...getColumnSearchProps('number', searchText, searchedColumn, handleSearch, handleReset),
                sorter: {
                    compare: (cell1Data, cell2Data) => cell1Data.number - cell2Data.number,
                    multiple: 1
                }
            },
            {
                id: 'title',
                title: 'Titre',
                dataIndex: 'title',
                ...getColumnSearchProps('title', searchText, searchedColumn, handleSearch, handleReset),
                sorter: {
                    compare: (cell1Data, cell2Data) => cell1Data.title.localeCompare(cell2Data.title),
                    multiple: 2
                }
            },
            {
                id: 'client',
                title: 'Propriétaire',
                dataIndex: 'client',
                ...getColumnSearchProps('client', searchText, searchedColumn, handleSearch, handleReset),
                sorter: {
                    compare: (cell1Data, cell2Data) => cell1Data.client.localeCompare(cell2Data.client),
                    multiple: 2
                }
            },
            {
                id: 'status',
                title: 'Statut',
                dataIndex: 'status'
            },
            {
                id: 'priority',
                title: 'Priorité',
                dataIndex: 'priority'
            },
            {
                id: 'date-creation',
                title: 'Date de création',
                dataIndex: 'created_at',
                ...getColumnSearchProps('created_at', searchText, searchedColumn, handleSearch, handleReset),
                sorter: {
                    compare: (cell1Data, cell2Data) => {
                        const date1 = new Date(cell1Data.created_at.replace(/-/g, '/'))
                        const date2 = new Date(cell2Data.created_at.replace(/-/g, '/'))
                        return date1 - date2
                    },
                    multiple: 3
                }
            },
            {
                id: 'date-modification',
                title: 'Date de modification',
                dataIndex: 'updated_at',
                ...getColumnSearchProps('updated_at', searchText, searchedColumn, handleSearch, handleReset),
                sorter: {
                    compare: (cell1Data, cell2Data) => {
                        const date1 = new Date(cell1Data.updated_at.replace(/-/g, '/'))
                        const date2 = new Date(cell2Data.updated_at.replace(/-/g, '/'))
                        return date1 - date2
                    },
                    multiple: 5
                }
            },
            {
                id: 'detail',
                title: 'Détail',
                dataIndex: 'id',
                render: (text, record) => (
                    <div className="btn-wrap">
                        <Button
                            id="btn-detail"
                            onClick={async (e) => {
                                setRec(record)
                                setVisible(true)
                                trackEvent({
                                    funComponent: 'Button-detail',

                                    event: 'Button-detail-clicked'
                                })
                            }}
                        >
                            <a href="#details">
                                <SearchOutlined />
                            </a>
                        </Button>
                    </div>
                )
            }
        ],
        info: tickets,
        scroll: 390,
        scrollX: 800,
        pagination: true,
        size: 'small'
    }
    useEffect(() => {
        getTickets()
        TicketingService.getArticle(rec.id)
            .then((res) => {
                setArticles(res.data)
            })
            .catch((err) => {})
    }, [rec])
    return (
        <div>
            <TableSimple data={configTicketsTable} />
            {visible === true ? (
                <DetailTicket rec={rec} setVisible={setVisible} visible={visible} setArticle={setArticle} article={article} articles={articles} />
            ) : null}
        </div>
    )
}


export default TicketsTable
