import React, { useEffect } from 'react'
import { Radio, Form } from 'antd'
import StatistiqueService from '../../services/StatistiqueService'
import track, { useTracking } from 'react-tracking'

const SelectButtonPeriod = ({ data, dataTauxRemonteInfosLiv, dataTauxRemonteInfosEnl, dataPeriodeData }) => {
    const { trackEvent } = useTracking()
    const getPeriod = async (niveau, periode) => {
        StatistiqueService.getperiode(niveau, periode).then((res) => {
            dataPeriodeData(res.data)
        })
    }

    const getInfo = (periode) => {
        getPeriod(data.idNiveau, periode)
        StatistiqueService.getTauxRemonteInfosLiv(data.idNiveau, periode).then((res) => {
            dataTauxRemonteInfosLiv(res.data)
        })
        StatistiqueService.getTauxRemonteInfosEnl(data.idNiveau, periode).then((res) => {
            dataTauxRemonteInfosEnl(res.data)
        })
    }

    useEffect(() => {
        if (data.idNiveau !== undefined) {
            getInfo('week')
        }
    }, [data.idNiveau])

    if (data !== undefined) {
        return (
            <Form.Item label={<label style={{ fontSize: '16px' }}>{data.label}</label>} initialValue={'week'} name="periode">
                <Radio.Group
                    onChange={(val) => {
                        getInfo(val.target.value)
                        trackEvent({
                            funComponent: 'Select-Periode',

                            event: 'Select-Periode-' + val.target.value + '-choosed'
                        })
                    }}
                >
                    <Radio.Button value="week">Semaine</Radio.Button>
                    <Radio.Button value="month">Mois</Radio.Button>
                    <Radio.Button value="year">Année</Radio.Button>
                </Radio.Group>
            </Form.Item>
        )
    }
}

export default SelectButtonPeriod
